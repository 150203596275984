import React from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from 'prop-types';
import styles from './style.module.css';

import es from 'date-fns/locale/es';
import enUS from 'date-fns/locale/en-US';

registerLocale("es", es);
registerLocale('en-US', enUS);

const Calendar = ({ className = '', selected, header, highlighted = [], formatDate, language='en', loading = false, inline=true, ...rest }) => {
  const highlightedDates = !!highlighted
    ? highlighted.map((date) => new Date(date))
    : [];
  
    const localLanguage = language === 'en' ? 'en-US' : language;

  return (
    <div className={`${styles.container} `}>
      <DatePicker
        selected={selected}
        calendarClassName={ `${className} ${loading ? "loading" : ""} ${styles.customCalendar}`}
        utcOffset={-1}
        inline={inline}
        disabledKeyboardNavigation
        highlightDates={highlightedDates} 
        formatDate={formatDate}
        locale={localLanguage}
        {...rest}
      />
    </div>
  );
};

export default Calendar;

Calendar.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.any,
  header: PropTypes.any,
  highlighted: PropTypes.array,
  formatDate: PropTypes.string,
  locale: PropTypes.string,
  loading: PropTypes.bool,
  inline: PropTypes.bool,
  language: PropTypes.string,
  rest: PropTypes.any
};