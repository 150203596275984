import { useState, useEffect, useContext } from "react";
import { formatDate, substractYear, addYear } from "utils/date";
import {
  Container,
  Box,
  Navbar,
  Left,
  Right,
  Grid,
  GridItem,
  Arrow,
} from "../styles";
import { Layout } from "../containers/Layout";
import { I8nContext } from "libs/i18n";

export const Monthly = ({ onCancel, onOK, year }) => {
  const { translate } = useContext(I8nContext);

  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedYear, setSelectedYear] = useState(year);

  const currentYear = formatDate(new Date(), "YYYY");
  const currentMonth = formatDate(new Date(), "MMMM");
  const FIRST_MONTH = 3;
  const FIRST_YEAR = 2019;

  const months = [
    translate("Jan"),
    translate("Feb"),
    translate("Mar"),
    translate("Apr"),
    translate("May"),
    translate("Jun"),
    translate("Jul"),
    translate("Aug"),
    translate("Sep"),
    translate("Oct"),
    translate("Nov"),
    translate("Dec"),
  ];

  function activeRange(year) {
    const currentMonth = +formatDate(new Date(), "M");

    for (let i = 0; i < 12; i++) {
      const month = document.getElementsByName("months")[i].dataset["index"];
      const firstYearValidation = +year === FIRST_YEAR && month < FIRST_MONTH;
      const lastYearValidation =
        +year === +currentYear && +month > +currentMonth;

      if (!firstYearValidation && !lastYearValidation) {
        document.getElementsByName("months")[i].disabled = false;
      }
    }
  }

  useEffect(() => {
    clear();
    const year = formatDate(selectedYear, "YYYY");
    activeRange(+year);
  }, [selectedYear]);

  const date =
    selectedMonths.length === 0
      ? currentMonth
      : selectedMonths.map(
          (item) => `${months[item.month - 1]}-${item.year}  `
        );

  function clear() {
    for (let i = 0; i < document.getElementsByName("months").length; i++)
      if (document.getElementsByName("months")[i].type === "checkbox") {
        const element = document.getElementsByName("months")[i];
        element.checked = 0;
        element.disabled = true;
      }
  }

  const handleForward = (year) => {
    if (currentYear > formatDate(selectedYear, "YYYY")) {
      clear();
      setSelectedYear(addYear(year));
    }
  };
  const handleBackward = (year) => {
    if (FIRST_YEAR < +formatDate(selectedYear, "Y")) {
      clear();
      setSelectedYear(substractYear(year));
    }
  };

  const handleOkClick = () => {
    const result = {};
    for (const obj of selectedMonths) {
      if (result[obj.year]) {
        result[obj.year].push(obj.month);
      } else {
        result[obj.year] = [obj.month];
      }
    }

    onOK(result, date);
  };

  const handleClick = (month) => {
    const year = formatDate(selectedYear, "YYYY");
    const newSelected = [...selectedMonths];

    if (
      !newSelected.some((item) => item.month === month && item.year === year)
    ) {
      newSelected.push({ month, year });
      setSelectedMonths(newSelected);
    } else {
      let index = newSelected.findIndex((item) => item.month === month);
      newSelected.splice(index, 1);
      setSelectedMonths(newSelected);
    }
  };

  return (
    <Container>
      <Box>
        <Layout
          year={currentYear}
          date={date}
          onCancel={onCancel}
          onOK={handleOkClick}
        >
          <Navbar>
            <Arrow onClick={() => handleBackward(selectedYear)}>
              <Left
                className={
                  FIRST_YEAR === +formatDate(selectedYear, "Y") && "disabled"
                }
              />
            </Arrow>
            <p>{formatDate(selectedYear, "YYYY")}</p>
            <Arrow onClick={() => handleForward(selectedYear)}>
              <Right
                className={
                  currentYear === formatDate(selectedYear, "YYYY") && "disabled"
                }
              />
            </Arrow>
          </Navbar>

          <Grid>
            {!!months &&
              months.map((month, i) => (
                <GridItem>
                  <input
                    key={i-"month"}
                    type="checkbox"
                    disabled={false}
                    id={month}
                    data-index={i + 1}
                    name="months"
                    onClick={() => handleClick(i + 1)}
                  />
                  <label for={month}>{month}</label>
                </GridItem>
              ))}
          </Grid>
        </Layout>
      </Box>
    </Container>
  );
};
