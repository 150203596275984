import {Grid, Row} from '../styles';

const TableGrid = ({data}) => {
  const columns = !!data.title && data.title.length;
  const activations = !!data.title && data.activations;
  const withData = !!data.title && data.withData;
  const withStar = !!data.title && data.withStar;

  return (
    <Grid columns={columns}>

      { !!activations && activations.map((active, i) => <Row key={i}>{active}</Row>) }

      { !!withData && withData.map((wdata, i) => <Row key={i}>{wdata}</Row>) }

      { !!withStar && withStar.map((wstar, i) => <Row key={i}>{wstar}</Row>) }

    </Grid>
    );
};

export default TableGrid;
