import React, { useState, useEffect, useContext } from "react";
import { opencloseSelector, OpenCloseState } from '../../../libs/redux';
import { useSelector, shallowEqual } from 'react-redux';
import { isOpen, getSecondsToClose } from '../../../utils/date';
import { I8nContext } from "../../../libs/i18n";
import {Timer} from "../../views/sections/components/Header/styles";

const getCountdown = (count: number)=> {
  if (count <= 0) {
    return '00:00:00';
  } else {
    let hours : number|string = Math.floor(count / 3600);
    hours = hours < 10 ? '0' + hours.toString() : hours.toString()
    let minutes : number|string = Math.floor((count - (+hours * 3600)) / 60);
    minutes = minutes < 10 ? '0' + minutes.toString() : minutes.toString()
    let seconds : number|string = count - (+hours * 3600) - (+minutes * 60);
    seconds = seconds < 10 ? '0' + seconds.toString() : seconds.toString()
    const formatCountdown = `${hours}:${minutes}:${seconds}`
    return formatCountdown
  }              
}

const TimeCounter: React.FC<{className?: any}> = ({className}) => {

  const openCloseData : OpenCloseState = useSelector(opencloseSelector, shallowEqual);
  const {start, end} = openCloseData;
  const {translate } = useContext(I8nContext);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const counter = setTimeout(() => {
      if (count > 0) {
        setCount(count - 1);
      }
    }, 1000);
    return () => clearTimeout(counter);
  }, [count]);

  useEffect(() => {
    if ( !start || !end) return ;
    
    if (isOpen(start, end)) {
      const isNewDay = calcIsNewDay(start, end);
      setCount(getSecondsToClose(end, isNewDay));
    } else {
      setCount(-1);
    }
  } , [start, end]);

  const calcIsNewDay = (start : string, end : string) => {
    if(!start || !end) return false;
    
    const startNumber = +start.split(":")[0];
    const endNumber = +end.split(":")[0];

    return endNumber < startNumber;
  }

  return (
    <Timer className={className}>
      <p>{translate("TIME_REMAINING")}</p>
      <h1>{getCountdown(count)}</h1>
    </Timer>
  );

};

export default TimeCounter;