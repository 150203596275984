import OpencloseTypes from './types';
import { Reducer } from 'redux';
import { OpenCloseState, OpenCloseDispatchAction } from './state';

const initialState : OpenCloseState = {
    start: '',
    end: ''
}

const OpencloseReducer: Reducer<OpenCloseState, OpenCloseDispatchAction> = 
    (state = initialState, action) => {
        switch(action.type) {
            case OpencloseTypes.FETCH_START:
                return state
            case OpencloseTypes.FETCH_SUCCESS:
                return action.payload
            default:
                return state
        }
    }

export default OpencloseReducer;