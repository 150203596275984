import React, { useState } from 'react';
import styles from './dropdown.module.css';
import Select from 'react-select';
import { PRIMARY, INPUTS, WRONG, TEXT } from '@etiquette-ui/colors';
import './style.css';
import Icon from '@etiquette-ui/icons';
import PropTypes from 'prop-types';

export const Dropdown = ({
                             className,
                             containerProps = {},
                             leadingIconProps = {},
                             trailingIconProps = {},
                             selectProps = {},
                             labelProps = {},
                             errorProps = {},
                         }) => {
    const [open, setOpen] = useState(false);

    const { icon: leadingIcon, leadingIconStyles = {} } = leadingIconProps;
    const { icon: trailingIcon = 'chevron-down', trailingIconStyles = {} } = trailingIconProps;

    const { value, menuIsOpen=true, ...restSelectProps } = selectProps;

    const { label = '', ...restLabelProps } = labelProps;
    const { error, ...restErrorProps } = errorProps;

    const regular = {
        '--bg': INPUTS,
        '--wrong': WRONG,
        '--right': PRIMARY,
        '--text': TEXT,
    };

    return (
        <div style={regular} className={className}>
            <div className={`${styles.container} ${menuIsOpen && open ? styles.containerActive : ''}`} onClick={()=>setOpen(!open)} {...containerProps}>
                {leadingIcon && (
                    <div className={styles.iconContainer}>
                        <Icon
                            icon={leadingIcon}
                            styles={{
                                width: '1.75rem',
                                height: '1.75rem',
                                line: {
                                    fill: 'none',
                                    strokeLinecap: 'round',
                                    strokeLinejoin: 'round',
                                    strokeWidth: '1.5px',
                                    stroke: TEXT,
                                },
                                ...leadingIconStyles,
                            }}
                        />
                    </div>
                )}
                <div className={`${styles.inputContainer} ${!!label ? styles.inputWithLabel : ''}`}>
                    <Select
                        className={styles.input}
                        isSearchable={false}
                        defaultMenuIsOpen={false}
                        classNamePrefix={'selector'}
                        backspaceRemovesValue={false}
                        value={value}
                        menuIsOpen={menuIsOpen && open}
                        {...restSelectProps}
                    />
                    {!!label && (
                        <label className={styles.label} {...restLabelProps}>
                            {label}
                        </label>
                    )}
                    {!!error && (
                        <p className={styles.invalidAlert} {...restErrorProps}>
                            {error}
                        </p>
                    )}
                </div>
                <div className={styles.iconContainer}>
                    <Icon
                        icon={trailingIcon}
                        styles={{
                            width: '1.75rem',
                            height: '1.75rem',
                            line: {
                                fill: 'none',
                                strokeLinecap: 'round',
                                strokeLinejoin: 'round',
                                strokeWidth: '1.5px',
                                stroke: TEXT,
                            },
                            ...trailingIconStyles,
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

Dropdown.propTypes = {
    className: PropTypes.string,
    containerProps: PropTypes.object,
    leadingIconProps: PropTypes.object,
    trailingIconProps: PropTypes.object,
    selectProps: PropTypes.object,
    labelProps: PropTypes.object,
    errorProps: PropTypes.object,
};
