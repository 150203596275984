import React from "react";
import Header from "./components/Header";
import HostessPreview from "../sections/dashboards/hostess/preview";
import ServersPreview from "../sections/dashboards/servers/preview";
import BarPreview from "../sections/dashboards/bar/preview";
import FoodPreview from "../sections/dashboards/food/preview";
import OnnaPreview from "../sections/dashboards/onna/preview";
import StatisticsPreview from "../sections/dashboards/statistics/preview";
import ManagerPreview from "../sections/dashboards/managers/preview";
import MusicPreview from "../sections/dashboards/music/preview";
import CashiersPreview from "../sections/dashboards/cashiers/preview";
import Quince from "resources/assets/QUINCE.png";
import { Container, Logo, Version } from "./styles";

const HomePage = () => {
  return (
    <>
      <Header />
      <Container>
        <StatisticsPreview /> 
        <ManagerPreview /> 
        <ServersPreview /> 
        <HostessPreview /> 
        <FoodPreview /> 
        <OnnaPreview />
        <BarPreview /> 
        <CashiersPreview />
        <MusicPreview />
      </Container>
      <Logo src={`${process.env.PUBLIC_URL}/restaurant-logo.png`} alt="logo"/>
      <Version>V.3.0.1</Version>
    </>
  );
};

export default HomePage ;
