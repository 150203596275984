import WinlosesTypes from './types'
import { MusicWinlosesState } from './state';

export const fetchWinlosesStart = () => ({
    type: WinlosesTypes.FETCH_START
});

export const fetchWinlosesSuccess = (data: MusicWinlosesState) => ({
    type: WinlosesTypes.FETCH_SUCCESS,
    payload: data
});

export const fetchWinlosesFailure = () => ({
    type: WinlosesTypes.FETCH_FAILURE
});