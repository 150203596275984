import React, { useContext } from "react";
import { getImageURL, personFolder } from "../../../../../../resources/api";
import { MusicRank } from "../../../../../../libs/redux";
import styled from "styled-components";
import tw from "tailwind.macro";
import {getColor} from "resources/helpers/helper";
import { I8nContext } from "../../../../../../libs/i18n";
import {MainStructure, Container, WrapInfo, Line, WrapNum, WrapNumTagsMusic} from "../../../../../../resources/styles/components/cards";
import {getToken} from "../../../../../../utils/auth";
import AddCommentIcon from "../../../../../../resources/assets/add-comment-icon-blue.svg";

interface StyledProps {
  colors: {
    cardColor: string;
    overallColor: string;
  };
}

const CardStyle = styled.div`
  ${tw`flex w-full border-solid rounded-lg border-2 sm:border-8 lg:border-4`}
  border-color: ${(props: StyledProps) => props.colors.cardColor};
  justify-content: center;
  align-items: center;
  .overall {
    color: ${(props) => props.colors.overallColor};
  }
`;

const ManagerCard: React.FC<MusicRank> = ({
  overall,
  reason,
  operator,
  table,
  time,
  comments,
  onClick,
}) => {

  const id =operator.id
  const img = id?.toString() + '.png'
  
  const colors = {
    cardColor: getColor(overall),
    overallColor: getColor(overall),
  };
  const { translate } = useContext(I8nContext);
  const reasonValue = reason !== "" ? translate(reason)  : "-";
  const token = getToken()

  return (
    <MainStructure>
      {
          !!token && (
              <div className="relative">
                <div className="absolute top-0 right-0">
                  <div className="flex justify-center bg-white rounded-full w-8 h-8 z-0 cursor-pointer" onClick={onClick}>
                    <img src={AddCommentIcon} className="w-4 z-10"/>
                  </div>
                </div>
              </div>
          )
      }
      <CardStyle colors={colors}>
        <div className={"flex flex-col w-full"}>
          <div className={"flex"}>
            <Container width={'40%'}>
              <div className="flex justify-center">
                { id && <img src={getImageURL(personFolder, img)} alt="" /> }
              </div>
              <WrapInfo>
                <p>{operator.name}</p>
                <p className="bg_table"> {translate("TABLE")} {`${table}`} </p>
                <p className="font-sm">{time}</p>
              </WrapInfo>
            </Container>
            <WrapNum width={'20%'}>
              <Line />
              <p className="overall">{overall ? overall : "-"}</p>
              <Line />
            </WrapNum>
            <WrapNumTagsMusic width={'40%'}>
              <div className="comments wrapTag">
                <div className={"flex flex-col items-center sm:flex-row sm:justify-center"}>
                  <div className={"tagTitle pr-0 sm:pr-2"}>
                    <p className="overall"> {reasonValue} </p>
                  </div>
                </div>
              </div>
            </WrapNumTagsMusic>
          </div>
          {
              !!token && Array.isArray(comments) && comments.map(comment => (
                  <>
                    <div className="flex justify-center">
                      <hr className="w-full m-2 border-1" />
                    </div>

                    <span className="pl-4">Comment added by {`${comment.user.name} ${comment.user.last_name}`}</span>
                    <p className="pl-4">{comment.comment}</p>
                  </>
              ))
          }
        </div>
      </CardStyle>
    </MainStructure>
  );
};

export default ManagerCard;
               