
import {combineReducers} from 'redux';

// -------------------- REDUCERS -------------------- //
import WinlosesReducer from './states/winloses/reducer';
import RankReducer from './states/rank/reducer';

const musicReducer = combineReducers({
    winloses: WinlosesReducer,
    rank: RankReducer,
});


export default musicReducer;