
import TYPES from './types'
import { TicketsState } from './state';

export const fetchSummaryStart = () => ({
    type: TYPES.FETCH_START
});

export const fetchSummarySuccess = (data: TicketsState) => ({
    type: TYPES.FETCH_SUCCESS,
    payload: data
});
