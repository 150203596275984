import React from 'react';
import styled from 'styled-components';
import {Score} from './score.model';
// import '../../../resources/assets/styles/main.scss';
import {getColor} from "resources/helpers/helper";

interface StyledProps { 
    barColor : string
}
const ScoreStyled = styled.div`
    .score {
        font-family: Avenir;
    }
    .name {
        font-family: Avenir;
    }
    .bar{
        background-color: ${(props: StyledProps) => props.barColor};
    }
`

const ScoreComponent : React.FC<Score>= (props) => {

    const todayScore: string|number = props.today ? Number(props.today).toFixed(1) : '-';
    const weekScore:  string|number = props.week ? Number(props.week).toFixed(1) : props.week;

    return (
        <ScoreStyled barColor = {getColor(+todayScore)}>      
                <div className="flex flex-col items-center p-1 w-20 sm:w-40 lg:w-28">
                    <img src={props.imgURL} className="w-1/2 avatar_card-preview" alt=""/>
                    <p className="name uppercase text-center font-bold tracking-wider text-xs sm sm:text-xl lg:text-sm">{props.name}</p>
                    <p className="score font-bold text-xs tracking-wider font-bold sm:text-2xl lg:text-base">
                        {`${todayScore} / (${weekScore})`}
                    </p>
                    <div className="bar h-1 w-3/4 rounded-lg bg-red-500 sm:h-2" />
                </div>     
        </ScoreStyled>
    );
}

export default ScoreComponent;