import React, { useContext } from "react";
import Box from "app/components/box";
import { getColor, getColorPercent, percentageValue, getColorNegative } from "resources/helpers/helper";
import {
  MobileContainer,
  MobileContent,
} from "app/components/box/mobile";
import { I8nContext } from "../../../../../libs/i18n";
import { colors } from 'resources/Theme';
interface RatingProps {
  total: number;
  imagesURLS: { [key: string]: string };
  chefmet: any;
  wins: string;
  loses: string;
}

const OnnaMobile: React.FC<RatingProps> = ({
  total,
  chefmet,
  wins,
  loses
}) => {
  const { translate } = useContext(I8nContext);
  const totalValue = total ? total.toFixed(1).replace(/\.0+$/, "") : "-";
  const winsToNumber = parseInt(wins);
  const winsLosesToNumber = parseInt(wins)+parseInt(loses);
  const percentage = percentageValue(winsToNumber, winsLosesToNumber);
  return (
    <MobileContainer>
      <Box background={colors.white} radius='8px'>
        <MobileContent color={getColor(total)}>
          <h1>{totalValue}</h1>
          <p> {translate("AVERAGE")} </p>
        </MobileContent>
      </Box>

        <Box background={colors.white} radius='8px'>
          <MobileContent color={getColorNegative(chefmet.count)}>
            <h1> {chefmet.count} / {chefmet.total} </h1>
            <p color={getColorPercent(chefmet.count)}>
              {translate("Met Chef")}
            </p>
          </MobileContent>
        </Box>

        <Box background={colors.white} radius='8px'>
        <MobileContent color={getColorPercent(percentage)}>
          <h1>{percentage}%</h1>
          <p color={getColorPercent(percentage)}>
            {translate("WINS")}
          </p>
        </MobileContent>
        </Box>
    </MobileContainer>  
  );
};

export default OnnaMobile;
