import WinlosesTypes from './types'
import { OnnaWinlosesState } from './state';

export const fetchWinlosesStart = () => ({
    type: WinlosesTypes.FETCH_START
});

export const fetchWinlosesSuccess = (data: OnnaWinlosesState) => ({
    type: WinlosesTypes.FETCH_SUCCESS,
    payload: data
});

export const fetchWinlosesFailure = () => ({
    type: WinlosesTypes.FETCH_FAILURE
});