import styled from "styled-components";
import { device, colors } from 'resources/Theme';

export const Container = styled.div`
  width: 95vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;
  column-gap: 10px;

  & .box:nth-child(n) {
    border: 2px solid ${colors.white};
    color: ${colors.white};
    border-radius: 20px;
    background: linear-gradient(
      0deg,
      rgba(3, 98, 151, 5) 0%,
      rgba(1, 39, 57, 1) 45%
    );
  }

  @media ${device.ipad} { 
    grid-template-columns: repeat(1, 1fr);
  }
  @media ${device.tablet} {     
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10px;
    column-gap: 10px;
    justify-content: center;
  }
  @media ${device.mini} {
    row-gap: 5px;
    column-gap: 3px;
  }
`;

export const Logo = styled.img`
  margin: 25px auto;
  max-width: 170px;
  width: 170px;
`;

export const Version = styled.div`
  width: 97%;  
  text-align: right;
  font-size: 11px;
  color: ${colors.white};
`;

