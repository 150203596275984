import React, { useContext } from "react";
import DasboardHeader from "../../../../providers/header";
import {
  barWinlosesSelector,
  barRankSelector,
} from "../../../../../libs/redux";
import CardComponent from "../../../../containers/info-card/card.component";
import withSelector from "../../../../containers/info-card/card.provider";
import {TableStructure,TableContainer, CardTitle, TableContent} from "../../components/layout";
import { I8nContext } from "../../../../../libs/i18n";

const BarDashboard: React.FC = () => {
  const { translate } = useContext(I8nContext);
  return (
    <TableStructure>
      <DasboardHeader title={translate("BAR/DRINKS")} selector={barWinlosesSelector} />
      <TableContainer>
        <div>
          <CardTitle>
            <p style={{width: "32%"}}> {translate("WINS")} </p>
            <p style={{width: "34%"}}>{translate("DETAILS")}</p>
            <p style={{width: "34%"}}> {translate("LOSES")}</p>
          </CardTitle>
          <TableContent> {withSelector(CardComponent)(barRankSelector)} </TableContent>
        </div> 
      </TableContainer>
    </TableStructure>
  );
};

export default BarDashboard;
