import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useSelector, shallowEqual } from 'react-redux';
import { ticketsSelector } from '../../../../../../libs/redux';
import { FlexContainer, TitleContainer } from './common';

interface GridProps {
  gridTemplate?: string,
  border?: string,
}

const HeaderContainer = styled.div<GridProps>`
    display: grid;
    grid-template-columns: ${({gridTemplate})=>(gridTemplate)};
    padding: 0;
    align-items: center;
    text-align: center;
    padding: 5px 0;
    border: 2px solid ${({border})=>( border || "transparent")};
    border-radius: 0px 0px 6px 6px;
`;
const HeaderTitle = styled.p`
    font-weight: 400;
    font-size: 16px;
    @media (max-width: 890px) {
      font-size: 12px;
    }
    @media (max-width: 640px) {
      font-size: 11px;
      margin: 0 2px;
      width : min-content;
    }
`;

interface HeaderProps {
  leading?: number | undefined,
  highlight?: number | undefined,
  cell?: number | undefined,
  title?: string,
  border?: string,
  columns?: string
}

const TableHeader: React.FC<HeaderProps> = ({leading, highlight, cell, title, border, columns}) => {

  const summary : any = useSelector(ticketsSelector, shallowEqual);
  const {shifts} = summary;

  return useMemo(()=> {

    let gridTemplate =  `${leading}fr ${highlight}fr `
    
    if(Array.isArray(shifts)){
      shifts.forEach(()=>{
        gridTemplate = `${gridTemplate} ${cell}fr `
      }); 
    }

    return (
      <div style={{display: 'flex', width:'100%'}}>
      <TitleContainer display={!title ? 'none' : "flex" }><HeaderTitle>{title}</HeaderTitle></TitleContainer>
      <HeaderContainer style={{width:'100%'}} gridTemplate={columns} border={border}>
        {
          Array.isArray(shifts) && shifts.map((shift: string, i)=>(<FlexContainer key={i}><HeaderTitle>{shift.toUpperCase()}</HeaderTitle></FlexContainer>))
        }
      </HeaderContainer>
      </div>
    );
  },[summary])
};

export default TableHeader;