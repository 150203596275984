import React, { useMemo, Fragment, useContext } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { ticketsSelector } from "../../../../../../libs/redux";
import { FlexContainer,Total, LeftTitle, GridContainer, CellContainer, CellsContainer, CellsGrid, CellValue } from "./common";
import { colors} from 'resources/Theme';
import { I8nContext } from "../../../../../../libs/i18n";

interface HeaderProps {
  leading?: number | undefined;
  highlight: number;
  cell: number;
}

const TableGrid: React.FC<HeaderProps> = ({ leading, highlight, cell }) => {
  const summary: any = useSelector(ticketsSelector, shallowEqual);
  const { data, shifts } = summary;
  const { translate , selectedLang } = useContext(I8nContext);

  const keys2 = [
    [translate("TICKETS"), translate("TICKETS")],
    [translate("ACTIVATIONS"),translate("ACTIVATIONSABBR") ],
    [translate("COMPLETED"), translate("COMPLETEDABBR")],
    [translate("REJECTED"), translate("REJECTEDABBR")],
  ];
  const keys = ["tickets", "activations", "completed", "rejected"];

  const findData = (data: any[], targetShift: string) => {
    return data.find(({ shift }) => shift === targetShift);
  };

  return useMemo(() => {
    let mainGridTemplate = `${leading}fr `;
    let gridTemplate = `${highlight}fr `;

    if (Array.isArray(data)) {
      mainGridTemplate = `${leading}fr ${data.length * cell + highlight}fr`;
      data.forEach(() => {
        gridTemplate = `${gridTemplate} ${cell}fr`;
      });
    }

    return (
      <GridContainer gridTemplate={mainGridTemplate}>
        {Array.isArray(data) &&
          keys.map((key, i) => {
            const orderedData = shifts.map((shift: string) =>
              findData(data, shift)
            );
            const total = orderedData
              .map((item: any) => item[key])
              .reduce((acc: number, curr: number) => acc + curr);
            return (
              <Fragment key={i}>
                <FlexContainer>
                  {<LeftTitle className="hidden lg:flex md:flex">{keys2[i][0].toUpperCase()}</LeftTitle>}
                  {<LeftTitle className="lg:hidden md:hidden">{keys2[i][1].toUpperCase()}</LeftTitle>}
                </FlexContainer>

                  <CellsContainer>
                    <CellsGrid gridTemplate={gridTemplate}>
                        <Total>
                          <CellValue>{total}</CellValue>
                        </Total>
                      
                      {orderedData.map((value: any, i: number) => (
                        <CellContainer key={i} backgroundColor={colors.secundary}>
                          <CellValue>{value[key]}</CellValue>
                        </CellContainer>
                      ))}
                    </CellsGrid>
                  </CellsContainer>
              </Fragment>
            );
          })}
      </GridContainer>
    );
  }, [summary , selectedLang]);
};

export default TableGrid;
