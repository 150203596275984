
import RankTypes from './types'
import { FoodRankState } from './state';

export const fetchRankStart = () => ({
    type: RankTypes.FETCH_START
});

export const fetchRankSuccess = (data: FoodRankState) => ({
    type: RankTypes.FETCH_SUCCESS,
    payload: data
});
