import styled from "styled-components";
import { device, colors} from 'resources/Theme';
import { ReactComponent as Es } from "./img/es.svg";
import { ReactComponent as En } from "./img/en.svg";

const Container = styled.div`
  color: ${colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  width: 4rem;
  margin-left: 7px;
  @media ${device.mobile} {
    width: 2rem;
    margin-left: 5px;
  }
`;

const Down = styled.div`
  margin: 0 auto;
  width: 0.8em;
  height: 0.6em;
  background-color: ${colors.white};
  clip-path: polygon(0 30%, 50% 100%, 100% 30%, 100% 0, 50% 72%, 0 0);
  @media ${device.mini} { 
    width: 0.4em;
    height: 0.3em;
  }
`;
const Up = styled(Down)`
  transform: rotate(180deg);
`;

const Content = styled.div`
  display: flex;
  svg {
    @media (max-width: 812px) {
      font-size: 0.8rem;
    }
  }
`;
const ContentItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  width: 9rem;
  * {
    margin-left: 3px;
  }
`;
const ContentLarge = styled.div`
  display: inline-block;
  width: 65%;
  max-width: 30px;
`;
const List = styled.ul`
  background-color: ${colors.grayblue};
  padding-left: 0;
  color: ${colors.white};
  position: absolute;
  width: 90px;
  z-index: 30;
  @media ${device.mobile}{
    width: 75px;
  }
  @media ${device.mobileS} {
    width: 65px;
  }
`;
const ItemList = styled.li`
  list-style-type: none;
  background-color: ${colors.grayblue};
  text-align: left;
  width: 40px;
  padding: 3px;
`;
const LangSpan = styled.span`
  color: ${colors.white};
  margin: 3px;
  font-weight: bold;
`;
const Esp = styled(Es)`
  width: 2rem;

  @media ${device.laptop} { 
    width: 1.5rem;
  }
  @media ${device.tablet} { 
    width: 1.2rem;
  }
  @media ${device.mobile} {  
    width: 1rem;
  }
`;
const Eng = styled(En)`
  width: 2rem;

  @media ${device.laptop} { 
    width: 1.5rem;
  }
  @media ${device.tablet} { 
    width: 1.2rem;
  }
  @media ${device.mobile} {  
    width: 1rem;
  }
`;
const Text = styled.p`
  font-size: 1rem;
  padding-right: 6px;
  @media ${device.laptop} { 
    font-size: 1.2rem;
  }
  @media ${device.tablet} { 
    font-size: 1rem;
  }
  @media ${device.mini} { 
    font-size: 12px;
  }
`;

export {
    Container,
    Up,
    Down,
    Content,
    ContentItem,
    ContentLarge,
    List,
    ItemList,
    LangSpan,
    Esp,
    Eng,
    Text
};