import { useContext } from 'react';
import Fetcher from './fetcher';
import Parser from './parser';
import { I8nContext } from 'libs/i18n';


const useMonthly = () => {

    const { translate } = useContext(I8nContext);

    const getMonthly = async (data) => {
        const rawData = await Fetcher(data);
        return Parser(rawData, translate);
    }

    return getMonthly;
}

export default useMonthly;