import ResumeTypes from './types'
import { ServersResumeState } from './state';

export const fetchResumeStart = () => ({
    type: ResumeTypes.FETCH_START
});

export const fetchResumeSuccess = (data: ServersResumeState) => ({
    type: ResumeTypes.FETCH_SUCCESS,
    payload: data
});

export const fetchResumeFailure = () => ({
    type: ResumeTypes.FETCH_FAILURE
});