import styled from "styled-components";
import { device, colors } from 'resources/Theme';

export const MobileContainer = styled.div`
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    column-gap: 4px;
    row-gap: 4px;
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(25px, 1fr) );
    margin-bottom: 15px;

    @media ${device.mini} {
        margin-bottom: 5px;
    }
`;
export const MobileContent = styled.div`
    width: 100%;
    text-align: center;
    font-weight: 600;
    color: ${({ color }) => (color ? color : colors.white)};
    padding: 10px 0;
    @media ${device.mobileS} {
        padding: 5px 0;
    }

    span{
        font-size: 15px;
        @media ${device.mini} {
            font-size: 8px;
        }
    }

    h1{
        font-size: 20px;
        @media ${device.mini} {
            font-size: 10px;
        }
    }

    p{
        text-transform: uppercase;
        letter-spacing: 0.05em;
        font-size: 11px;
        line-height: 0.8rem;

        @media ${device.mobile} {
          font-size: min(10px, calc(11px - 0.3vw));
          padding: 0 2px;
        }
        @media ${device.mobileL} {
            font-size: calc(11px - 1vw);
        }
        @media ${device.mobileM} {
            font-size: 8px;
        }
        @media ${device.mini} {
            font-size: 5px;
            line-height: 0.3rem;
        }
    }
`;