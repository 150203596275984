import { useState, useEffect} from "react";
import moment from 'moment';
import {
  Container,
  Box,
  Navbar,
  Left,
  Right,
  Grid,
  GridItem,
  Arrow
} from "../styles";
import {Layout} from '../containers/Layout';

export const Yearly = ({onCancel, onOK, daySelected}) => {
    const currentYear = moment(new Date).format('Y');
    const [selectedYear, setSelectedYear] = useState([]);
  
    const thisYear = +moment(daySelected).format('Y');
    const firstYear = 2019;
    const [years, setYears] = useState([]);
    
    
    const fillArray = () => {
      const filler = [];

      for (let i=firstYear ; i <= thisYear  ; i++){
        filler.push(i);
      }
      setYears(filler);
    }


    useEffect(() => {
      fillArray();    
    }, []);
  
  
    const handleClick = (year) => {
      const newSelected = [...selectedYear];
  
      if(!newSelected.some((item) => (item === year))){
        newSelected.push(year);
        setSelectedYear(newSelected);
      } else{
        let index = newSelected.findIndex((item) => (item === year));
        newSelected.splice(index, 1);
        setSelectedYear(newSelected);
      }
    }
  
    const date = selectedYear.length === 0 ? `${years[0]} - ${thisYear}` : selectedYear.join("-");
  
    return (
      <Container>
        <Box>
          <Layout 
            year={currentYear}
            date={date}
            onCancel={onCancel}
            onOK={() => (onOK(selectedYear, date ))}
            >
            <Navbar>
              <Arrow>
                <Left className='disabled' />
              </Arrow>
              <p>{`${years[0]} - ${thisYear}`}</p>
              <Arrow>
                <Right className={"disabled"}/>
              </Arrow>
            </Navbar>
  
            <Grid>
            {!!years && years.map((year, i) =>      
              <GridItem>
                <input 
                key={i}
                type='checkbox'
                id={year}
                name='years' 
                onClick={() => (handleClick(year))}
                />
                <label for={year}>  
                  {year} 
                </label>
              </GridItem>
            )}
            </Grid>
            </Layout> 
        </Box>
      </Container>
    );
  };
  