import { useContext } from "react";
import TableHeader from "./components/TableHeader";
import TableGrid from "./components/TableGrid";
import LateralTitle from "./components/LateralTitle";
import { I8nContext } from "libs/i18n";
import { Header, TableContainer, Container } from "./styles";

export const Table = ({data, title}) => {
const { translate } = useContext(I8nContext);
const lateralInfo = data && Object.keys(data);
const sideTitles = lateralInfo.splice(0, 1);
return (
    <Container>
        <LateralTitle data={lateralInfo}/>
        <TableContainer>
            <Header> {translate(title)} </Header>
            <TableHeader data={!!data && data.title}/>
            <TableGrid data={!!data && data}/>
        </TableContainer>
    </Container>
  );
};