import { takeLatest, call, all, put } from 'redux-saga/effects';
import Types from './types';
import { globalDateChange, globalDateDidChange } from './actions';
import { globalDateDispatchAction } from './state';

function* fetchDateAsync (action : globalDateDispatchAction) {
    yield put(globalDateChange(action.payload))
    yield put(globalDateDidChange())
}

function* dateChange() {
    yield takeLatest(Types.DATE_CHANGE, fetchDateAsync);
}

export default function* sagas() {
    yield all([
        call(dateChange),
    ]);
}