import React, { useState, useContext } from "react";
import { I8nContext } from "../../../libs/i18n";
import {
  Container,
  Up,
  Down,
  Content,
  ContentItem,
  ContentLarge,
  List,
  ItemList,
  LangSpan,
  Esp,
  Eng,
  Text
} from "./styles";

const Lang = () => {
  const langs = [
    [<Esp key='es1' />, <Text key='es2'> ES </Text>],
    [<Eng key='en1' />, <Text key='en2'> EN </Text>],
  ];

  const [visible, setVisible] = useState(false);
  const { selectedLang, setSelectedLang } = useContext(I8nContext);

  const toggle = () => setVisible(!visible);

  return (
    <React.Fragment>
      <Container onClick={toggle}>
        <ContentItem>{langs[selectedLang]}</ContentItem>
        <Content>{visible ? <Up /> : <Down />} </Content>
      </Container>

      {visible && (
        <List onClick={toggle}>
          {langs.map((lang, index) => (
            <ItemList key={index} onClick={() => setSelectedLang(index)}>
              <Container>
                <ContentLarge>{lang[0]}</ContentLarge>
                <Content>
                  <LangSpan>{lang[1]}</LangSpan>
                </Content>
              </Container>
            </ItemList>
          ))}
        </List>
      )}
    </React.Fragment>
  );
};

export default Lang;
