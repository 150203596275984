import React, { useEffect, useState } from 'react';
import Header from './Header.jsx';
import Row, { Sortable } from './Row.jsx';
import { useTableContext } from './providers/TableProvider.jsx';
import { arrayMoveImmutable } from 'array-move';
import { SortableContainer } from 'react-sortable-hoc';
import { useFilters } from './providers/Filters.jsx';
import styles from './style.module.css';

const TableContainer = ({children, className, ...rest}) => <div className={`${styles.tableContainer} ${className ?? ''}`} {...rest}>{children}</div>

const SortableWrapper = SortableContainer(({ children, ...rest }) => {
  return <TableContainer {...rest}>{children}</TableContainer>;
});

const PAGE_SIZE = 20;
const LAST_ELEMENT_CLASS = 'dynamic-table-last-element-rendered';

const Dynamic = ({ items, className, primaryKey = 'id', onSortEnd }) => {
  const [processed, setProcessedItems] = useState(items);
  const { dragable, globalState } = useTableContext();
  const { filters, applyAllFilters } = useFilters();  
  const [itemCount, setItemCount] = useState(PAGE_SIZE);

  useEffect(() => {
    setProcessedItems(applyAllFilters(items));
  }, [items]);

  useEffect(() => {
    setProcessedItems(applyAllFilters(items));
  }, [filters]);

  const commitMove = (oldIndex, newIndex) => () => {
    setProcessedItems(arrayMoveImmutable(processed, oldIndex, newIndex));
  };

  const handleSort = async ({ oldIndex, newIndex }) => {
    onSortEnd({ processed, oldIndex, newIndex, globalState, commitMove: commitMove(oldIndex, newIndex) });
  };

  const onScroll = () => {
    const elements = document.getElementsByClassName(LAST_ELEMENT_CLASS);
    if (!elements || !elements[0]) return;

    if (elements[0].getBoundingClientRect().top < window.innerHeight) {
      setItemCount(itemCount + PAGE_SIZE);
    }
  };

  return (
    <div className={`${styles.container} ${className ?? ''}`}>
      <Header />
            {dragable ? (
                <SortableWrapper className="body" onSortEnd={handleSort} useDragHandle={true} onScroll={onScroll}>
                    {processed.slice(0, itemCount).map((item, i) => (
                        <Sortable key={item[primaryKey]} index={i} count={i} item={item} className={i===itemCount-1 ? LAST_ELEMENT_CLASS : undefined}/>
                    ))}
                </SortableWrapper>
            ) : (
                <TableContainer className="body" onScroll={onScroll}>
                    {processed?.slice(0, itemCount).map((item, i) => (
                        <Row key={item[primaryKey]} count={i} item={item} className={i===itemCount-1 ? LAST_ELEMENT_CLASS : undefined}/>
                    ))}
                </TableContainer>
            )}
    </div>
  );
};

export default Dynamic;
export {Dynamic};