import React, { useContext } from "react";
import Box from "app/components/box";
import { getColor, getColorPercent, getColorNegative } from "resources/helpers/helper";
import {
  MobileContainer,
  MobileContent,
} from "app/components/box/mobile";
import { I8nContext } from "../../../../../libs/i18n";
import { colors } from 'resources/Theme';
import styled from 'styled-components';

interface RatingProps {
  total: number;
  perfect: {
    count : number,
    total : number
  };
  touch : {
    count : number,
    total : number
  };
}

const ManagerMobile: React.FC<RatingProps> = ({
  total,
  perfect,
  touch,
}) => {
  const { translate } = useContext(I8nContext);

  const perfectionDontMatch =  Number ((perfect.total-perfect.count) !== 0);
  const perfectionIsZeros = perfect.total === 0 && perfect.count == 0 ;
  const perfectionColor = perfectionIsZeros ? 'black' : getColorNegative(perfectionDontMatch);
  const perfectionSecondColor = !perfectionDontMatch && !perfectionIsZeros ?  colors.good : 'black';

  const touchDontMatch = Number((touch.count - touch.total) !== 0);
  const touchIsZeros = touch.count === 0 && touch.total == 0;
  const touchColor = touchIsZeros  ? 'black' : getColorNegative(touchDontMatch) ;
  const touchSecondColor = !touchDontMatch && !touchIsZeros ? colors.good : 'black';

  return (
    <MobileContainer>
      <Box background={colors.white} radius='8px'>
        <MobileContent color={getColor(total)}>
          <h1>{total ? total.toFixed(1).replace(/\.0+$/, "") : "-"}</h1>
          <p> {translate("AVERAGE")} </p>
        </MobileContent>
      </Box>

        <Box background={colors.white} radius='8px'>
          <MobileContent color={perfect.total === 0 ? "#000" : colors.good}>
            <h1>
              <NumberHolder color={perfectionColor}>{perfect.count}</NumberHolder>
              <NumberHolder color={perfectionSecondColor} >/{perfect.total}</NumberHolder>
            </h1>
            <p style={{color : perfectionSecondColor}}>
              {translate("PERFECTION")}
            </p>
          </MobileContent>
        </Box>

        <Box background={colors.white} radius='8px'>
        <MobileContent color={perfect.total === 0 ? "#000" : colors.good }>
          <h1>
            <NumberHolder color={touchColor}>{touch.count}</NumberHolder>
            <NumberHolder color={touchSecondColor}>/{touch.total}</NumberHolder>
          </h1>
          <p style={{color : touchSecondColor}}>
            {translate("TOUCH_TABLE_CAPS")}
          </p>
        </MobileContent>
        </Box>
    </MobileContainer>
  );
};

const NumberHolder = styled.span`
  && { font-size: 20px;}
  color : ${props => props.color}
`;

export default ManagerMobile;
