import {combineReducers} from 'redux';

import TicketsReducer from './states/tickets/reducer';


const statisticsReducer = combineReducers({
    tickets: TicketsReducer,
});


export default statisticsReducer;