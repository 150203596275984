
import {combineReducers} from 'redux';

import ResumeReducer from './states/resume/reducer';

const musicReducer = combineReducers({
    resume: ResumeReducer,
});


export default musicReducer;