
interface CardData {
    rating: string,
    name: string
}
export interface CardInfo { 
    id: number,
    wins: CardData[]
    loses: CardData[],
    avg: number|null,
    details: {
        table: number,
        tableCardinal: string,
        time: string,
        name: string,
        service: string,
        chefmet : boolean
    },
    comments: any
}

export const goodServices: string[] = [
    'Fast Flowing',
    'Ok',
    'Perfectly',
    'Adequate'
];

export const badServices: string[] = [
    'Always Delayed',
    'Slow'
];