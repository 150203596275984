import React, { useContext } from "react";
import Box from "app/components/box";
import { getColor , getColorPercent, percentageValue} from "resources/helpers/helper";
import { I8nContext } from "../../../../libs/i18n";
import {
  MobileContainer,
  MobileContent,
} from "app/components/box/mobile";
import { colors } from 'resources/Theme';


interface RatingProps {
  total: number;
  avg : number;
  wins: string;
  loses: string;
}
const MobileView: React.FC<RatingProps> = ({ total, wins, loses }) => {
  const { translate } = useContext(I8nContext);
  const winsToNumber = parseInt(wins);
  const winsLosesToNumber = parseInt(wins)+parseInt(loses);
  const percentage = percentageValue(winsToNumber, winsLosesToNumber);
  const totalValue = total ? total.toFixed(1).replace(/\.0+$/, "") : "-";
  return (
  <MobileContainer>
    <Box background={colors.white} radius='8px'>
      <MobileContent color={getColor(total)}>
      <h1>{totalValue}</h1>
      <p> {translate("AVERAGE")} </p>
      </MobileContent>
    </Box>

    <Box background={colors.white} radius='8px'>
      <MobileContent color={getColorPercent(percentage)}>
      <h1>{percentage}%</h1>
      <p> {translate("WINS")} </p>
      </MobileContent>
    </Box>
  </MobileContainer>
  );
};

export default MobileView;
