import React, { useMemo } from 'react';
import ScoreComponent from './score.component';
import { getImageURL, personFolder, getRandom } from '../../../resources/api';
import {fadeIn} from 'react-animations';
import styled, { keyframes, Keyframes } from 'styled-components';
import tw from 'tailwind.macro';
  
const fadeinAnimation = keyframes`${fadeIn}`;
interface StyledProps {
  animation : Keyframes
}
const Animation = styled.div`
    margin-right: 5px;
    ${tw`flex lg:flex-col`}
    animation: 1s ${(props : StyledProps) => props.animation};
`;

interface Score{
  id: number,
  person: {
    name: string,
    img: string
  },
  week: number,
  today: number
}

interface ScoreProps {
  items: Score[]
}
const ScoreContainer : React.FC<ScoreProps> = ({items}) => {

    return useMemo(()=> {
      const itemsSorted : Score[]|boolean = items? items.sort((a, b) => b.week - a.week).sort((a,b) => Number(b.id) - Number(a.id)) : false;

      return(
        <div className="flex lg:flex-col  overflow-x-scroll lg:overflow-y-scroll xl:overflow-y-scroll lg:overflow-x-hidden lg:overflow-x-hidden">
            <Animation key={getRandom()} animation = {fadeinAnimation}>
              {
                Array.isArray(itemsSorted) && itemsSorted.map((item:Score) => <ScoreComponent key={item.id} 
                      {...{...item, 
                        name: item.person.name, 
                        imgURL: getImageURL(personFolder, item.person.img)
                      } } />)
              }
            </Animation>
        </div>
      );
    } ,[JSON.stringify(items)]);
};

export default ScoreContainer;