import { useContext, useState } from "react";
import styled from "styled-components";
import { I8nContext } from "libs/i18n";
import {Daily} from './Daily';
import { device, colors } from 'resources/Theme';
import { useDispatch, useSelector, shallowEqual} from 'react-redux'
import { ConfigDispatcher } from 'libs/redux';
import configSelector from 'libs/redux/config/selector';
import Img from '../calendar.svg';
import moment from 'moment';

const DateContainer = styled.div`
  display: flex;
  align-items:center;
  justify-content: center;
  color: #fff;
  font-family: Avenir;
  padding: 0 10px;
  cursor: pointer;
  margin-bottom: 10px;

  &: hover{
    border: 2px solid ${colors.gold};
    border-radius: 10px;
    cursor: ponter;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  @media ${device.mini} { 
    font-size: 12px;
  }
`;

const Image = styled.img`
  && {
    position: relative;
    margin: 0 auto;
    padding-right: 8px;
  }
`;
export const DateDisplay = () => {
  const { translateDate } = useContext(I8nContext);
  
  const [calendar, setCalendar] = useState(false);
  const showCalendar = () => setCalendar(true);
  const onCancel = () => setCalendar(false);


  const dispatcher = useDispatch();
  const configDispatcher = new ConfigDispatcher(dispatcher);

  const datePicker = useSelector(configSelector, shallowEqual); 
  const globalDateState = datePicker.date === '' ? new Date() : datePicker.date;

  const [daySelected, setDaySelected] = useState( globalDateState );
  const [date, setDate] = useState(moment(globalDateState).format('ddd, MMM D, YYYY'));

  const handleDateChange = (date: any, day:any) => {
    configDispatcher.dateChangeApp(date.format('YYYY-MM-DD'));
    setCalendar(false);
    setDate(day);
  };
 
  return (
    <DateContainer>
        <Container onClick={showCalendar}> 
          <Image src={Img} /> 
          {translateDate(date)}
        </Container>

          {calendar && 
            <Daily 
              daySelected={daySelected} 
              setDaySelected={setDaySelected} 
              onCancel={onCancel}
              onOK={handleDateChange} 
            />
          }
    </DateContainer>
  );
};