import React, { useContext } from "react";
import { getImageURL, personFolder } from "../../../../../../../resources/api";
import { ManagersRank } from "../../../../../../../libs/redux";
import styled from "styled-components";
import tw from "tailwind.macro";
import { colorTagGroup } from "../../../../../../../resources/styles/components/colorTagGroup";
import NoSVG from "resources/assets/close.svg";
import YesSVG from "resources/assets/check.svg";
import {getColor, getColorBorder, getColorPercentPerfect, getPersonColor, getValueForBorder} from "resources/helpers/helper";
import { I8nContext } from "../../../../../../../libs/i18n";
import {Container, WrapInfo, Line, WrapNum, WrapNumTag} from "../../../../../../../resources/styles/components/cards";
import theme from "resources/assets/theme";
import AddCommentIcon from "resources/assets/add-comment-icon-blue.svg"
import {getToken} from "../../../../../../../utils/auth";

interface StyledProps {
  colors: {
    cardColor: string;
    overallColor: string;
    colorPerfect: string;
    tagsColor: string;
  };
}
const MainStructure = styled.div`
    ${tw` max-h-screen w-full scrolling-touch text-white border-solid border-2 sm:border-8 lg:border-4`}
    border-color: ${(props: StyledProps) => props.colors.cardColor};
    margin-top: 10px;
    background: #001B35;
    border-radius: 10px;

    .green {
        color: ${theme.green};
    }
    .red {
        color: ${theme.red};
    }
    .orange {
        color: ${theme.orange};
    }
`;
const CardStyle = styled.div`
  ${tw`flex w-full rounded-lg`}
  
  justify-content: center;
  align-items: center;
  .overall {
    color: ${(props: StyledProps) => props.colors.overallColor};
  }
  .tags {
    color: ${(props) => props.colors.tagsColor};
  }
  .perfect {
    color: ${(props) => props.colors.colorPerfect};
    font-size : 0.8rem;
    @media (max-width: 640px){
      padding: 0;
      font-size : 0.5rem;
    }
  }
`;
const Duty = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    color: #000;
    border-radius: 10px;
    margin: 10px;
    margin-top: 0px;
    padding-top: 0;
    padding-bottom: 0;
`;

const Row = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;

    h5 {
      margin: 0 15px;
      color: #888;
      font-size: 17px;
      @media (max-width: 640px){
        font-size: 10px;
      }
    }

    p{
      margin: 0 5px;
      color: #888;
      font-size: 14px;

      span{
        color: #000;
      }

      @media (max-width: 640px){
        font-size: 6px;
      }
    }
`;

const ManagerCard: React.FC<ManagersRank> = ({
  person,
  overall,
  tags,
  tagslist,
  perfect,
  time,
  table,
  avg,
  managerName,
  operatorName,
    comments,
    onClick
}) => {

  const colors = {
    cardColor: getColorBorder(getValueForBorder(avg, tags)),
    overallColor: getColor(overall),
    colorPerfect: getColorPercentPerfect(perfect),
    tagsColor: getColor(tags),
  };
  const { translate } = useContext(I8nContext);
  const token = getToken()

  return (
    <MainStructure colors={colors}>
      {
        !!token && (
              <div className="relative">
                <div className="absolute top-0 right-0">
                  <div className="flex justify-center bg-white rounded-full w-8 h-8 z-0 cursor-pointer" onClick={onClick}>
                    <img src={AddCommentIcon} className="w-4 z-10"/>
                  </div>
                </div>
              </div>
          )
      }
      <CardStyle colors={colors}>
        <Container width={'30%'}>
          <div className="flex justify-center">
            <img src={getImageURL(personFolder, person.img)} alt="" />
          </div>
          <WrapInfo>
            <p style={{color : getPersonColor(person.id)}}>{person.name}</p>
            <p className="bg_table"> {translate("TABLE")} {`${table}`} </p>
            <p className="font-sm">{time}</p>
          </WrapInfo>
        </Container>
        <WrapNum width={'17%'}>
          <Line /> 
          <p className="overall">{overall ? overall : "-"}</p>
          <Line />
        </WrapNum> 
        <WrapNum width={'17%'}>
          <Line  width={'0%'}/>
          <p className="perfect" >{perfect ? translate(perfect) : "-"} </p>
          <Line />
        </WrapNum> 
        <WrapNumTag width={'40%'}>
          <p className="tags number"> {tags ? tags.toFixed(1).replace(/\.0+$/, "") : "-"}</p>
          <div className="comments wrapTag">
            {tagslist.map((item: any, index: number) => (
              <div className={"flex flex-col items-center sm:flex-row sm:justify-center"} key={index}>
                <div className={"Chef"}>
                    {item[0] !== "Met Chef"  && translate(item[0])}
                  </div>
                  <div className={`tagValue  ${colorTagGroup[item[1]]}`} title={item[1]} >
                    {item[0] !== "Met Chef"  && translate(item[1]) }
                  </div>
              </div>
            ))}
          </div>
          </WrapNumTag>    
        </CardStyle>
        <Duty>
          <Row> 
            <h5>On Duty: </h5>
            <p>GM: <span>{managerName}</span></p> 
            <p>Asistant Manager: <span>{operatorName}</span></p>
          </Row>

         <WrapNumTag width='30%'>
          <div className="comments wrapTag">
            {tagslist.map((item: any, index: number) => (
              item[1] !== '' && (
              <div className={"flex"} key={index}>
                <div className={`tagValue  ${colorTagGroup[item[1]]}`} >
                  {item[0] === "Met Chef" && (
                    <img src={item[1] === "No" ? NoSVG : YesSVG} alt=""></img> )}
                </div>
                <div className={"tagTitle pr-0 sm:pr-2"}>
                  {item[0] === "Met Chef" && <p>{translate("Met Chef")}</p> }
                </div>
              </div>)
            ))}
          </div>
          </WrapNumTag> 
        </Duty>
      {
          !!token && Array.isArray(comments) && comments.map(comment => (
              <>
                <div className="flex justify-center">
                  <hr className="w-full m-2 border-1" />
                </div>

                <span className="pl-4">Comment added by {`${comment.user.name} ${comment.user.last_name}`}</span>
                <p className="pl-4">{comment.comment}</p>
              </>
          ))
      }
      </MainStructure>
  );
};

export default ManagerCard;
               