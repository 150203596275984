import styled from "styled-components";
import { device, colors} from 'resources/Theme';

export const MainContainer = styled.div`
    max-height: 100%;
    width: 100%;
    color: ${colors.white};
`;

export const Container = styled.div`
    width: min(1500px, 95%);
    margin: 60px 16% 0 auto;

    @media ${device.desktop} {
        margin: 60px 12% 0 auto;
    }

    @media ${device.tablet} {
        margin: 0 auto 0;
    }
`;

export const CalendarContainer = styled.div`
    width: auto;
    margin: 60px auto 25px 15%;
    
    @media ${device.ipad} {
        margin: 60px auto 25px 21%;
    }

    @media ${device.mobileM} {
        margin: 30px auto 25px 21%;
    }
    @media ${device.mobileS} {
        margin: 30px auto 25px;
    }
`;

export const Content = styled.div`
    width: auto;
    margin: 0px auto 0px 15%;
    border-radius: 6px 6px 0px 0px;
    background: ${colors.primary};

    @media ${device.custom} {
        width: 84%;
    }
    @media ${device.mobile} {
        width: 81%;
        margin: 0px auto 0px 17%;
    }
    @media ${device.mobileL} {
        width: 78%;
        margin: 0px auto 0px 20%;
    }
    @media ${device.mobileM} {
        width: 77%;
        margin: 0px auto 0px 21%;
    }
    @media ${device.mobileS} {
        width: 71%;
        margin: 0px auto 0px 27%;
    }
`;