interface GroupColor {
    [key: string]:string,
}

export const colorTagGroup: GroupColor = {
    Perfectly: 'green',
    Perfect: 'green',
    Comfortable: 'green',
    'Fast & Flowing': 'green',
    'Fast' : 'green',
    'Moved fast' : 'green',
    'Always Smiling' : 'green',
    'Very Helpful' : 'green',
    'Too Many' : 'red',
    'Too many' : 'red',
    'Incorrect' :'red',
    Friendly: 'green',
    Smiling: 'green',
    'Super Friendly': 'green',
    'Very Friendly': 'green',
    'Very professional': 'green',
    'Not professional': 'red',
    'No Help Needed': 'orange',
    'Smiling enough': 'orange',
    'Semi Friendly': 'orange',
    'Ok speed': 'orange',
    'Knows menu Ok': 'orange',
    'Snail pace' : 'red',
    'Frowning' : 'red',
    Ok: 'orange',
    Helpful: 'green',
    'Not Helpful' : 'red',
    'Not helpful when needed' : 'red',
    "Un Friendly" : 'red',
    "Unfriendly" : 'red',
    Great: 'green',
    Adequate: 'orange',
    'No opinion': '#ffff',
    'I booked online': '#ffff',
    'No Opinion': '#ffff',
    'No problem': 'orange',
    Slow: 'red',
    'Not Smiling': 'red',
    'Not good': 'red',
    'Not helpful': 'red',
    'Poor': 'red',
    'Always Delayed': 'red',
    'Food Delivery Perfect': 'green',
    'Food Temperature Perfect': 'green',

    '1 min': 'green',
    '5 min': 'green',
    '10 min': 'green',
    '30 min': 'red',
    '20 min': 'red',
    '45 min': 'red',
    '60 min': 'red',
    '+60 min': 'red',
    '0-5 min': 'green',
    '5-10 min': 'green',
    '10-20 min': 'red',
    '20-30 min': 'red',
    '30-45 min': 'red',
    '45-60 min': 'red',
    'unknown': '#ffff',
    '?': '#ffff',
    '-': '#ffff',
}