import React, {useContext, useEffect, useState} from 'react';
import BaseDialog from './BaseDialog';
import {API_URL, getImageURL, personFolder} from "../../../resources/api";
import {getToken} from "../../../utils/auth";
import Card from "../../etiquette/Card";
import styled from "styled-components";
import tw from "tailwind.macro";
import BackIcon from "resources/assets/back-icon.svg"
import {I8nContext} from "../../../libs/i18n";

const SurveyDetails = ({surveyId, onClose, back}) => {
    const [surveyDetails, setSurveyDetails] = useState(null)
    const { translate } = useContext(I8nContext);

    useEffect(() => {
        (async function(){
            try {
                const response = await fetch(`${API_URL}dashboard/common/ranks/${surveyId}`,
                    {
                        method: 'GET',
                        headers: {
                            'Authorization': getToken()
                        }
                    });
                if (response.status === 401) {
                    alert(response.statusText)
                    return
                }

                setSurveyDetails(await response.json());
            } catch(e) {
                alert(translate("server error"))
            }
        })()
    },[])

    return (
        <BaseDialog
            open={true}
            isResponsive={true}
            showCloseButton={true}
            onClose={onClose}
        >
            <div className={"flex flex-col gap-2 overflow-scroll scrollbar-hide max-h-75 py-2"}>
                <div className={"flex w-full justify-center items-center"}>
                    <span className={"font-bold"}>{translate("Survey Rating")}</span>
                </div>
                <div className={"flex"} onClick={back}>
                    <img alt={""} src={BackIcon} />
                    <span className={"font-bold text-sm text-blue-500 cursor-pointer"}>{translate("View Survey Details")}</span>
                </div>
            {
                !!surveyDetails && (
                    <>
                        {
                            !!surveyDetails.managers && <PersonDetails {...surveyDetails.managers} title={translate("General Manager")}/>
                        }
                        {
                            !!surveyDetails.servers && <PersonDetails {...surveyDetails.servers} title={translate("Server")}/>
                        }
                        {
                            !!surveyDetails.hostess && <PersonDetails {...surveyDetails.hostess} title={translate("Host")}/>
                        }
                        {
                            !!surveyDetails.food && <ItemDetails {...surveyDetails.food} title={translate("Kitchen/Food")}/>
                        }
                        {
                            !!surveyDetails.bar && <ItemDetails {...surveyDetails.bar} title={translate("Bar/Drinks")}/>
                        }
                    </>
                )
            }
            </div>
        </BaseDialog>
    );
};

const TagItem = styled.div`
    ${tw`flex w-full`}  
    @media (max-width: 640px){
        font-size: 10px;
    }
    .loses-list {
        ${tw`flex items-center break-words`}  
        background-color: #c30e2e;
        color: white;
        ${(props) => props.noBorder ? "" : "border-radius: 8px;" }
      }
      .wins-list {
        ${tw`flex items-center break-words`}  
        background-color: #077e15;
        color: white;
        ${(props) => props.noBorder ? "": "border-radius: 8px;"}
      }
`;

const ItemRating = styled.p`
    ${tw`text-xs font-bold`}  
`;
const ItemName = styled.p`
    ${tw`text-xs font-medium`}  
`;

const PersonDetails = ({title, person, avg, overall, tags, tagslist}) => {
    const tagsClassified = Array.isArray(tagslist) ? {
        wins: tagslist.filter(tag => tag[2] >= 4),
        loses: tagslist.filter(tag => tag[2] > 0 && tag[2] < 4)
    } : {wins: [], loses: []}
    const { translate } = useContext(I8nContext);

    return (
        <Card shadow={"light"}>
            <span className={"font-bold text-sm"}>{title}</span>
            <div className={"flex flex-col py-2 w-full items-center"}>
                <div className={"flex items-start gap-2 w-full"}>
                    <img className="w-12" alt={""} src={getImageURL(personFolder, person.img)}/>
                    <span className={"text-xs font-bold"}>{person.name}</span>
                </div>
                <div className={"flex w-full my-2"}>
                    {
                        !!overall && <div className={"flex items-center justify-center w-28"}>
                            <TagItem>
                                <div className={`flex w-full ${overall >= 4.5 ? "wins-list" : "loses-list"} mx-1 px-2 gap-1`}>
                                    <ItemName>{translate("Overall")}</ItemName>
                                    <ItemRating>{overall.toFixed(1)}</ItemRating>
                                </div>
                            </TagItem>
                        </div>
                    }
                    {
                        !!tags && <div className={"flex items-center justify-center w-28"}>
                            <TagItem>
                                <div className={`flex w-full ${tags >= 4.5 ? "wins-list" : "loses-list"} mx-1 px-2 gap-1`}>
                                    <ItemName>{translate("Via Tags")}</ItemName>
                                    <ItemRating>{tags.toFixed(1)}</ItemRating>
                                </div>
                            </TagItem>
                        </div>
                    }
                    {
                        !!avg && <div className={"flex items-center justify-center w-28"}>
                            <TagItem>
                                <div className={`flex w-full ${avg >= 4.5 ? "wins-list" : "loses-list"} mx-1 px-2 gap-1`}>
                                    <ItemName>{translate("Average")}</ItemName>
                                    <ItemRating>{avg.toFixed(1)}</ItemRating>
                                </div>
                            </TagItem>
                        </div>
                    }
                </div>
            </div>
            {
                (!!tagsClassified.loses.length ||
                !!tagsClassified.wins.length) &&
                <span className={"font-bold text-xs"}>{translate("Tags received")}</span>
            }
            <div className={"flex w-full pb-1"}>
                <div className={"flex flex-col w-1/2"}>
                    {
                        !!tagsClassified.loses.length && (
                            <div>
                                {
                                    tagsClassified.loses.map(tag => (
                                        <div className={"flex items-center gap-1 text-xs"}>
                                            <div style={{
                                                backgroundColor: "#c30e2e",
                                                width: "0.5rem",
                                                height: "0.5rem",
                                                borderRadius: "50%"
                                            }}></div>
                                            <span style={{fontWeight: "bold", color: "#077e15"}}>{tag[2]}</span>
                                            <span>{translate(tag[0])}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }
                </div>
                <div className={"flex flex-col w-1/2"} >
                    {
                        !!tagsClassified.wins.length && (
                            <div >
                                {
                                    tagsClassified.wins.map(tag => (
                                        <div className={"flex items-center gap-1 text-xs"}>
                                            <div style={{
                                                backgroundColor: "#077e15",
                                                width: "0.5rem",
                                                height: "0.5rem",
                                                borderRadius: "50%"
                                            }}></div>
                                            <span style={{fontWeight: "bold", color: "#077e15"}}>{tag[2]}</span>
                                            <span>{translate(tag[0])}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        </Card>
    )
}

const ItemDetails = ({title, avg, wins, loses}) => {
    const { translate } = useContext(I8nContext);

    return (
        <Card shadow={"light"}>
            <span className={"font-bold text-sm"}>{title}</span>
            <div className={"flex py-2 w-full items-center"}>
                <div className={"flex items-center gap-2 w-1/3"}>
                    <span className={"text-xs font-bold"}>Items</span>
                    <span className={"text-xs font-bold"}>{wins.length + loses.length}</span>
                </div>
                <div className={"flex items-center justify-center w-28"}>
                    <TagItem>
                        <div className={`flex w-full ${avg >= 4.5 ? "wins-list" : "loses-list"} mx-1 px-2 gap-1`}>
                            <ItemName>{translate("Average")}</ItemName>
                            <ItemRating>{avg}</ItemRating>
                        </div>
                    </TagItem>
                </div>
            </div>
            <div className={"flex"}>
                {
                    !!loses.length && (
                        <div className={"flex flex-col w-1/2"}>
                            {
                                loses.map(item => (
                                    <TagItem noBorder={true}>
                                        <div className={`flex w-full loses-list mr-1 px-2 gap-1`}>
                                            <ItemRating>{item.rating}</ItemRating>
                                            <ItemName>{item.name}</ItemName>
                                        </div>
                                    </TagItem>
                                ))
                            }
                        </div>
                    )
                }
                {
                    !!wins.length && (
                        <div className={"flex flex-col w-1/2 gap-1"}>
                            {
                                wins.map(item => (
                                    <TagItem noBorder={true}>
                                        <div className={`flex w-full wins-list ml-1 px-2 gap-1`}>
                                            <ItemRating>{item.rating}</ItemRating>
                                            <ItemName>{item.name}</ItemName>
                                        </div>
                                    </TagItem>
                                ))
                            }
                        </div>
                    )
                }
            </div>
        </Card>
    )
}

export default SurveyDetails;

