import React, { useContext } from "react";
import DasboardHeader from "../../../../providers/header";
import {
  foodWinlosesSelector,
  foodRankSelector,
} from "../../../../../libs/redux";
import CardComponent from "../../../../containers/info-card/card.component";
import withSelector from "../../../../containers/info-card/card.provider";
import { TableStructure, TableContainer, CardTitle, TableContent } from "../../components/layout";
import { I8nContext } from "../../../../../libs/i18n";

const FoodDashboard: React.FC = () => {

  const { translate } = useContext(I8nContext);

  return (
    <TableStructure>
      <DasboardHeader title={translate("KITCHEN/FOOD")} selector={foodWinlosesSelector} />
      <TableContainer>
        <div>
          <CardTitle>
            <p style={{width: "32%"}}> {translate("WINS")} </p>
            <p style={{width: "34%"}}>{translate("DETAILS")}</p>
            <p style={{width: "34%"}}> {translate("LOSES")}</p>
          </CardTitle>
          <TableContent> {withSelector(CardComponent)(foodRankSelector)} </TableContent>
        </div>
      </TableContainer>
    </TableStructure>
  );
};

export default FoodDashboard;
