import { createSelector } from 'reselect';
import GlobalState from '../../../root-state';

const selectSummary = (state : GlobalState) => state.statistics.tickets;




export const ticketsSelector = createSelector(
    [selectSummary],
    data => data
);

