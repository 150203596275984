import React, { useContext } from "react";
import ListCard from "./components/hostess-card/hostess-list.container";
import Score from "../../../../containers/score/score.provider";
import DasboardHeader from "../../../../providers/header";
import { hostessWinlosesSelector, hostessResumeSelector } from "../../../../../libs/redux";
import HostessCard from "./components/hostess-card/hostess-card.component";
import { I8nContext } from "../../../../../libs/i18n";
import { MainStructure, MainContainer, CardTitle, CardContent, SideBar, SideHeader, SideContent } from "../../components/layout";

const HostessDashboard: React.FC = () => {

  const { translate } = useContext(I8nContext);

  return (
    <MainStructure>
      <DasboardHeader title={translate("HOSTS")} selector={hostessWinlosesSelector} />
      <MainContainer>
        <div>
          <CardTitle className="pr-3 sm:pr-0">
            <p style={{width: "25%"}}>{translate("HOSTESS")}</p>
            <p style={{width: "10%"}}>{translate("OVERALL")}</p>
            <p style={{width: "41%"}}>{translate("TAGS")}</p>
            <p style={{width: "14%"}}>{translate("LOBBY")}</p>
            <p style={{width: "10%"}}>{translate("DRINK")}</p>
          </CardTitle>
          <CardContent> <ListCard Component={HostessCard} /> </CardContent>
        </div>
        <SideBar>
          <SideHeader> {translate("SIDE_BAR")} </ SideHeader>
          <SideContent> <Score selector={hostessResumeSelector} /> </SideContent>
        </SideBar>
      </MainContainer>
    </MainStructure>
  );
};

export default HostessDashboard;
