import styled from 'styled-components';
import { device, colors } from 'resources/Theme';

const Container = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, min(200px, 40%));
    grid-template-rows: auto;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    @media ${device.laptop} { 
        column-gap: 20px;
    }
    @media ${device.mobile} { 
        grid-template-columns: repeat(3, 32%);
        column-gap: 10px;
    }
    @media ${device.mobileL} { 
        column-gap: 5px;
    }

    &.preview{
        @media ${device.tablet} {
            grid-template-columns: repeat(2, 50%);
            column-gap: 5px;
        }
        @media ${device.mini} {
            column-gap: 3px;
            row-gap: 4px;
        }
    }
`;
const WinLose = styled.div`
    text-align: center;

    &.regular{
        h1{
            font-size: 16px; 
            @media ${device.laptop} { 
                margin-top: -50px;
                font-size: 40px; 
                margin-bottom: 15px;
            }
            @media ${device.mobile} { 
                font-size: calc(40px - 1vw);
            }
            @media ${device.mobileL} { 
                margin-top: -30%;
                font-size: calc(40px - 2vw);
            }  
            @media ${device.mobileM} {
                margin-top: -35%;
                font-size: calc(40px - 4vw);
            }  
            @media ${device.mobileS} { 
                font-size: calc(40px - 6vw);
            }
            @media ${device.mobileXS} { 
                font-size: calc(40px - 8vw);
            }
        }
    
        p{
            padding-left: 10px;
            font-size: 16px;
            @media ${device.laptop} { 
                font-size: 25px;
            }
            @media ${device.mobile} { 
                font-size: calc(25px - 0.5vw);
            }
            @media ${device.mobileL} { 
                font-size: calc(25px - 1.8vw);
            }
            @media ${device.mobileM} { 
                font-size: calc(25px - 2.1vw);
            }
            @media ${device.mobileS} { 
                font-size: calc(25px - 3vw);
            }
            @media ${device.mobileXS} { 
                font-size: calc(25px - 4vw);
            }
        }
    }

    &.preview{
        h1{
            font-size: 16px; 
            @media ${device.mini} {
                font-size: 12px;
            }
        }
        p{
            padding-left: 10px;
            font-size: 16px;
            @media ${device.mini} {
                font-size: 10px;
            }
        }
    }
`;
const Average = styled.div`
    width: 100%;
    height: 67%;

    &.regular{
        @media ${device.laptop} { 
            margin-top: -12px;
            height: 61%;
        }
        @media ${device.mobileL} { 
            margin-top: -6px;
        }
        @media ${device.mobileM} { 
            margin-top: -14px;
        }
        @media ${device.mobileS} { 
            margin-top: -10px;
            height: 61%;
        }
    }

    &.preview{
        @media ${device.tablet} { 
            display: none;
        }
    }

`;
const Content = styled.div`
    text-align: center;
    color: ${({ color }) => (color ? color : colors.black )}; 
    font-weight: 600;
    line-height: 2.1rem;

    h1 {
        font-size: 2.5rem;
        @media ${device.mobile} {
            font-size: calc(2rem - 0.3vw);
            line-height: 2rem;
        }
        @media ${device.mobileL} {
            font-size: calc(2rem - 0.5vw);
        }
        @media ${device.mobileL} {
            font-size: calc(2rem - 1.3vw);
        }
        @media ${device.mobileS} {
            font-size: calc(2rem - 2.5vw);
            line-height: 1.5rem;
        }
        @media ${device.mobileXS} {
            font-size: calc(2rem - 3.5vw);
        }
    }
    p {
        text-transform: uppercase;
        letter-spacing: 0.025em;
        line-height: 1.3rem;

        @media ${device.mobile} {
            font-size: calc(1rem - 0.3vw);
            line-height: 0.8rem;
        }
        @media ${device.mobileL} {
            font-size: calc(1rem - 0.56vw);
        }
        @media ${device.mobileM} {
            font-size: calc(1rem - 1vw);
        }
        @media ${device.mobileS} {
            font-size: calc(1rem - 2.5vw);
        }
        @media ${device.mobileXS} {
            font-size: calc(1rem - 3.2vw);
        }
    }

`;
const ScoreContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    @media ${device.mini} {
        width: 50px;
        height: 24px;
    }
`;

export {
    Container, 
    WinLose, 
    Average, 
    Content, 
    ScoreContainer,
};