import WinlosesTypes from './types';
import { ServersWinlosesState, ServersWinlosesDispatchAction } from './state';
import { Reducer } from 'redux';

const initialState: ServersWinlosesState = {
    wins: '',
    loses: '',
    avg: 0,
    touch : 0,
    perfect : 0,
    chefmet : 0
}

export const WinlosesReducer : Reducer<ServersWinlosesState, ServersWinlosesDispatchAction>= 
    (state = initialState, action) => {
        switch(action.type) {
            case WinlosesTypes.FETCH_START:
                return state
            case WinlosesTypes.FETCH_SUCCESS:
                return action.payload
            default:
                return state
        }
    }

export default WinlosesReducer;