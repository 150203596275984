import React, { useContext } from "react";
import Box from "../box";
import Score from "./score/score.component";
import { getBackgroundColor, getColorAlt } from "../../../resources/helpers/helper";
import {Container,WinLose, ScoreContainer, Average, Content} from "./styles";
import { I8nContext } from "../../../libs/i18n";
interface RatingProps {
  total: number;
  imagesURLS: { [key: string]: string };
  preview?: boolean;
}
const Rating: React.FC<RatingProps> = ({ imagesURLS, total, preview }) => {
  const { translate } = useContext(I8nContext);
  return (
    <Container className={preview ? 'preview' : ''}>
      <WinLose className={preview ? 'preview' : 'regular'}>
        <h1>{translate("WINSSHORT")}</h1>
        <ScoreContainer>
          <Score  url1={imagesURLS.winsUrl1} url2={imagesURLS.winsUrl2} url3={imagesURLS.winsUrl3}/>
        </ScoreContainer>
        <p> {translate("STARS")} </p>
      </WinLose>

      <Average className={preview ? 'preview' : 'regular'}>
        <Box background={getBackgroundColor(total)} id="rating-box">
          <Content color={getColorAlt(total)}>
            <h1> {total ? total.toFixed(1).replace(/\.0+$/, "") : "-"} </h1>
            <p> {translate("TODAY")} </p>
          </Content>
        </Box>
      </Average>

      <WinLose className={preview ? 'preview' : 'regular'}>
        <h1>{translate("LOSESSHORT")}</h1>
        <ScoreContainer>
          <Score url1={imagesURLS.losesUrl1} url2={imagesURLS.losesUrl2} url3={imagesURLS.losesUrl3}/>
        </ScoreContainer>
        <p> {translate("STARS2")} </p>
      </WinLose>
    </Container>
  );
};

export default Rating;
