import React, { useContext } from "react";
import styled from "styled-components";
import theme from "../../../resources/assets/theme";
import tw from "tailwind.macro";
import { CardInfo, goodServices, badServices } from "./card.model";
import NoSVG from "../../../resources/assets/close.svg";
import YesSVG from "../../../resources/assets/check.svg";
import {
  TableWrap,
  TableColumn,
  TableCenter,
  TableColumnItem,
  ItemRating,
  ItemName,
  TableSpan,
  TableService,
  TableMetChef,
} from "../../../resources/styles/components/tables";
import { I8nContext } from "../../../libs/i18n";
import { TableCardinal, Service } from "../../../libs/i18n/switches";
import {API_URL} from "../../../resources/api";
import {getToken} from "../../../utils/auth";
import ALERT_TYPES from "../../etiquette/types";
import SurveyDetails from "../../components/dialogs/SuveyDetails";
import DetailsTicketsDialog from "../../components/dialogs/TicketDetails";
import {useAlert} from "../../etiquette/AlertProvider";
import AddCommentIcon from "../../../resources/assets/add-comment-icon-blue.svg";
interface withTargetColor {
  bgColor: string;
  textColor: string;
}
const CardStyled = styled.div`
  ${tw`flex w-full text-black  py-1`}
  font-family: Avenir;
  .score-red {
    color: ${theme.strongRed};
  }
  .score-green {
    color: ${theme.strongGreen};
  }
  .target {
    background-color: ${(props: withTargetColor) => props.bgColor};
    color: ${(props: withTargetColor) => props.textColor};
  }
`;

const Card: React.FC<CardInfo> = ({id, wins, loses, details, comments }) => {
  const getTargetColor: (
    service: string
  ) => { bgColor: string; textColor: string } = (service) => {
    if (goodServices.find((e) => e === service))
      return { bgColor: theme.serviceGood, textColor: "white" };
    else if (badServices.find((e) => e === service))
      return { bgColor: theme.serviceBad, textColor: "white" };
    else return { bgColor: theme.gray, textColor: "black" };
  };
  const color = getTargetColor(details.service);
  const { translate } = useContext(I8nContext);
  const dialogAlert = useAlert()
  const token = getToken()

  const fetchTicketDetails = async(surveyId: number) => {
    const response = await fetch(`${API_URL}dashboard/tickets/${surveyId}`, {
      method: "GET",
      headers: {
        "Authorization": getToken()
      }
    })
    if (response.status !== 200) {
      throw new Error('error en el servidor')
    }
    return await response.json()
  }

  const openSurveyDetails = (surveyId: any, back: any) => {
    dialogAlert({
      type: ALERT_TYPES.CUSTOM,
      config: {
        body: SurveyDetails,
        props: {
          surveyId,
          back
        }
      }
    })
  }

  const openDetails = (ticketData: any) => {
    const back = openDetails.bind(null, ticketData)
    dialogAlert({
      type: ALERT_TYPES.CUSTOM,
      config: {
        body: DetailsTicketsDialog,
        props: {
          ticketData,
          openSurveyDetails,
          back
        }
      }
    })
  }

  const showDialog = async() => {
    try {
      const ticketData = await fetchTicketDetails(id)
      openDetails(ticketData)
    } catch(e) {
      alert(e);
    }
  }


  return (
    <CardStyled bgColor={color.bgColor} textColor={color.textColor}>
      <TableWrap>
        <div className={"flex flex-col w-full"}>
          <div className={"flex"}>
            <TableColumn>
              <TableColumnItem>
                {wins.length > 0 &&
                  wins.map((item, index: number) => (
                    <div key={index} className="p-1 wins-list">
                      <ItemRating className="score-green">{item.rating}</ItemRating>
                      <ItemName className="name">{item.name}</ItemName>
                    </div>
                  ))}
              </TableColumnItem>
            </TableColumn>

            <TableCenter>
              <TableSpan>
                <p>
                  {translate("TABLE")} - {details.table}
                </p>
                <TableCardinal tableCardinal={details.tableCardinal} />
              </TableSpan>
              <p>{details.time}</p>
              <ItemName>{details.name}</ItemName>
              <TableService className="target">
                <Service service={details.service} />
              </TableService>
              <TableMetChef>
                <h1>{details.chefmet}</h1>
                {details.chefmet !== null && (
                  <>
                    <img src={details.chefmet ? YesSVG : NoSVG} />
                    <p>{translate("Met Chef")}</p>
                  </>
                )}
              </TableMetChef>
            </TableCenter>
            <TableColumn>
              <TableColumnItem>
                {loses.length > 0 &&
                  loses.map((item, index: number) => (
                    <div key={index} className="p-1 loses-list">
                      <ItemRating className="score-red">{item.rating}</ItemRating>
                      <ItemName>{item.name}</ItemName>
                    </div>
                  ))}
              </TableColumnItem>
            </TableColumn>
            {
                !!token && (
                    <div className="relative">
                      <div className="absolute top-0 right-0">
                        {/*@ts-ignore*/}
                        <div className="flex justify-center bg-white rounded-full w-8 h-8 z-0 cursor-pointer" onClick={showDialog}>
                          <img src={AddCommentIcon} className="w-4 z-10"/>
                        </div>
                      </div>
                    </div>
                )
            }
          </div>
          {
              !!token && Array.isArray(comments) && comments.map(comment => (
                  <>
                    <div className="flex justify-center">
                      <hr className="w-full m-2 border-1" />
                    </div>

                    <span className="pl-4">Comment added by {`${comment.user.name} ${comment.user.last_name}`}</span>
                    <p className="pl-4">{comment.comment}</p>
                  </>
              ))
          }
        </div>
      </TableWrap>
    </CardStyled>
  );
};

export default Card;
