import ResumeTypes from './types';
import { ManagersResumeState, ManagersResumeDispatchAction } from './state';
import { Reducer } from 'redux';

export const resumeReducer : Reducer<[ManagersResumeState]|null, ManagersResumeDispatchAction> = 
    ( state = null, action) => {
        switch(action.type) {
            case ResumeTypes.FETCH_START:
                return state
            case ResumeTypes.FETCH_SUCCESS:
                return action.payload
            default: 
                return state
        }
    }

export default resumeReducer;