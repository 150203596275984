import styled from 'styled-components';
import { device, colors } from 'resources/Theme';

const DropdownInput = styled.div`
font-size: 12px;
cursor: pointer;
width: 250px; 

&::before,
&::after {
  box-sizing: border-box;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;

  background-color: transparent;
  border: none;
  padding-left: 3px;
  padding-right: 14px;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  outline: none;
  overflow-x: hidden;
  

  &::-ms-expand {
    display: none;
  }
}

.select_container {
  display: grid;
  grid-template-areas: "select";
  align-items: center;

  width: auto;

  border: 1px solid #fff;
  border-radius: 10px;
  padding: 0px 0.8em;
  font-size: 14px;
  cursor: pointer;
  line-height: 3;
  color: ${colors.black};
  background: ${colors.clear};

  &::after {
    content: "";
    width: 0.8em;
    height: 0.5em;
    background-color: ${colors.black};
    clip-path: polygon(0 30%, 50% 99%, 100% 30%, 100% 0, 50% 72%, 0 0);
    justify-self: end;
    margin-right: 8px;
  }

  &--disabled {
    cursor: not-allowed;
    background-color: #eee;
    background-image: linear-gradient(to top, #ddd, #eee 33%);
  }
}

select,
.select_container:after {
  grid-area: select;
}
`;

const DateSelected = styled.div`
border-bottom: 2px solid white;
min-width: 247px;
width: fit-content;

margin-left: 20px;


@media ${device.mobile} {
  margin-top: 20px;
  margin-left: 0;
  width: 247px; 
  text-align: center;
}

@media ${device.mobileXS} {
  min-width: 200px; 
}

h1{
  font-size: 25px;
  text-transform: capitalize;
  @media ${device.tablet} {
    font-size: 20px;
  }
}
`;

const Container = styled.div`
display: flex;
align-items: flex-end;

@media ${device.tablet} {
  flex-wrap: wrap;
  justify-content: center;
}
`;

export {
    DropdownInput,
    DateSelected,
    Container
};