import {put} from 'redux-saga/effects';
import {
    fetchWinlosesSuccess,
    fetchWinlosesStart,
    fetchWinlosesFailure,
} from './actions';

import {winLosesURL} from '../../api';

export function* fetchWinlosesAsync (date) {
    
    try {
        yield put(fetchWinlosesStart())
        const response = yield fetch(`${winLosesURL}/${date}`, {
            method: "GET"
        })
        const json = yield response.json()
        yield put(fetchWinlosesSuccess(json))
    } catch (error) {
        console.log(error.message)
        yield put(fetchWinlosesFailure())
    }
}
