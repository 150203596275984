import styled from 'styled-components';
import { colors} from 'resources/Theme';

interface FlexProps {
  readonly justify?: string | undefined;
  display?: string;
};

export const FlexContainer = styled.div<FlexProps>`
    display: ${({display})=> (display || 'flex')};
    justify-content: ${({justify})=> justify ? justify : 'center'};
    align-items: center;
`;

export const TitleContainer = styled.div<FlexProps>`
    display: ${({display})=> (display || 'flex')};
    justify-content: ${({justify})=> justify ? justify : 'center'};
    align-items: center;
    width: 15%;
    @media (max-width: 660px) {
      width: 10%;
    }
    @media (max-width: 470px) {
      width: 8%;
    }
    @media (max-width: 300px) {
      width: 5%;
    }
`;

export const LeftTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  margin-left: auto;
  margin-right: 20px;
  text-align: end;
  @media (max-width: 890px) {
    font-size: 12px;
  }
  @media (max-width: 300px) {
    margin-right: 3px;
  }
`;

export const GridContainer = styled.div<{ gridTemplate: string}>`
  display: grid;
  grid-template-columns: ${({ gridTemplate }) => gridTemplate};
  @media (max-width: 890px) {
    padding: 0 10px;
  }
`;

export const CellContainer = styled.div<{ backgroundColor?: string }>`
  display: flex;
  height : auto;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : colors.gray};
  border-bottom: dashed 1px #707070;
  border-right: dashed 1px #707070;
  height : auto;
  min-height : 50px;
  align-items: center;
  justify-content: center;
`;
export const Total = styled.div`
  display: flex;
  height : auto;
  background-color: ${ colors.gray};
  border-bottom: dashed 1px #707070;
  border-right: dashed 1px #707070;
  height : auto;
  min-height : 50px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`;
export const CellsContainer = styled.div`
  @media (max-width: 640px) {
    overflow-x: auto;
    white-space: nowrap;
  }
`;

export const CellsGrid = styled.div<{ gridTemplate: string}>`
  display: grid;
  grid-template-columns: ${({ gridTemplate }) => gridTemplate};
  overflow: hidden;
  width: 100%;
`;

export const CellValue = styled.p<{ textAlign?: string }>`
  color: ${({ color }) => (color ? color : "white")};
  font-weight: 400;
  font-size: 28px;
  text-align: ${({ textAlign } ) =>
    textAlign ? textAlign : "center"};
  @media (max-width: 640px) {
    font-size: 18px;
  }
`;