import {put} from 'redux-saga/effects';

import {
    fetchResumeSuccess,
    fetchResumeStart,
    fetchResumeFailure,
} from './actions';

import { resumeURL } from '../../api';

export function* fetchResumeAsync (date) {
    try {
        yield put(fetchResumeStart())
        const response = yield fetch(`${resumeURL}/${date}`, {
            method: "GET"
        })
        const json = yield response.json()
        yield put(fetchResumeSuccess(json))
    } catch (error) {
        console.log(error.message)
        yield put(fetchResumeFailure())
    }
}
