import React from 'react';
import {
  DropdownInput,
    DateSelected,
    Container
} from "./styles";

export const Dropdown = ({options =[], initialValue, onChange, date=''}) =>(
  <Container>
    <DropdownInput>
      <div className="select_container">
        <select id="standard-select" onChange={onChange} required>
          {options.map((item)=>{
            if(item.id === initialValue) {
              return(
                <option value={item.id} key={item.id} selected>
                  {item.label}
                </option>
              )
  
            } else {
              return(
                <option value={item.id} key={item.id}>
                  {item.label}
                </option>
              )
            }
            
          })}
        </select>
      </div>
    </DropdownInput>
    <DateSelected>
          <h1>{date}</h1>
    </DateSelected>
    </Container>
  );