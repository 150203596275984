import React, { useContext } from "react";
import { getImageURL, personFolder } from "../../../../../../../resources/api";
import theme from "../../../../../../../resources/assets/theme";
import { HostessRank } from "../../../../../../../libs/redux";
import styled from "styled-components";
import tw from "tailwind.macro";
// import "../../../../../../resources/assets/styles/main.scss";
import { colorTagGroup } from "../../../../../../../resources/styles/components/colorTagGroup";
import {getColor, getColorBorder, getValueForBorder} from "resources/helpers/helper";
import { I8nContext } from "../../../../../../../libs/i18n";
import {MainStructure, ContainerPreview, WrapInfoPreview, Line, WrapNumPreview, WrapNumTagPreview, WrapTimePreview} from "../../../../../../../resources/styles/components/cards";

interface StyledProps {
  colors: {
    cardColor: string;
    overallColor: string;
    drinkColor: string;
    tagColor : string;
  };
}
const CardStyle = styled.div`
  ${tw`flex w-full border-solid rounded border-2 sm:border-4 lg:border-4`}
  border-color: ${(props: StyledProps) => props.colors.cardColor};
  justify-content: center;
  align-items: center;
  .overall {
    color: ${(props) => props.colors.overallColor};
  }
  .tags {
    color: ${(props) => props.colors.tagColor};
  }
  .drink {
    color: ${(props) => props.colors.drinkColor};
    @media (max-width: 640px){
      padding: 0;
    }
  }
  p{
    margin: 0 3px;
  }
`;

const HostessCard: React.FC<HostessRank> = ({
  person,
  overall,
  tags,
  tagslist,
  lobby,
  drink,
  time,
  table,
  avg,
}) => {
   const colors = {
    cardColor: getColorBorder(getValueForBorder(avg, tags)),
    overallColor: getColor(overall),
    drinkColor: drink === "No" ? theme.red : theme.green,
    tagColor : getColor(tags)
  };
  const { translate } = useContext(I8nContext);

  return (
    <MainStructure>
      <CardStyle colors={colors}>
        <ContainerPreview width={'25%'}>
          <img src={getImageURL(personFolder, person.img)} alt="" />
          <WrapInfoPreview>
            <p>{person.name}</p>
            <p className="font-sm">{time}</p>
            <p className="bg_table"> {translate("TABLE")} {`${table}`} </p>
          </WrapInfoPreview>
        </ContainerPreview>
        <WrapNumPreview width={'10%'}>
          <Line /> 
          <p className="overall">{overall ? overall : "-"}</p>
          <Line />
        </WrapNumPreview> 
        <WrapNumTagPreview width={'35%'}>
          <Line width={'0'}/>
          <div className="flex items-center w-11/12 py-2">
            <p className="tags number w-2/12 text-center"> {tags ? tags.toFixed(1).replace(/\.0+$/, "") : "-"}</p>
            <div className="w-10/12">
            {tagslist.map((tag, index) => (
                <div className={"flex justify-center lg:flex-col lg:items-center xl:flex-row"} key={index}>
                  <div className={"tag0  pr-2 lg:pr-0 xl:pr-2"}> {translate(tag[0])} </div>
                  <div className={`tag1 ${colorTagGroup[tag[1]]}`}>{translate(tag[1])}</div>
                </div>
              ))}
            </div>
          </div>
          <Line />
        </WrapNumTagPreview>  
        <WrapTimePreview width={'15%'}>
            <p className={`lobby text-center ${colorTagGroup[lobby]}`} > {lobby ==='unknown' ? '?' : lobby} </p>
        </WrapTimePreview>
        <WrapNumPreview width={'15%'}>
          <Line />
            <p className="drink">{drink}</p>  
          <Line width={'0'}/>        
        </WrapNumPreview> 
        </CardStyle>
    </MainStructure>
  );
};

export default HostessCard;
