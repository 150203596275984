import { put } from 'redux-saga/effects';
import { rankURL } from '../../api';

import {
    fetchRankSuccess
} from './actions';

export function* fetchRankAsync(date) {
    try {
        const response = yield fetch(`${rankURL}/${date}`, {
            method: "GET"
        })
        const json = yield response.json()
        yield put(fetchRankSuccess(json))
    } catch (error) {
        console.log(error.message)
    }
}