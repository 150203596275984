import Fetcher from "./fetcher";
import Parser from './parser';

const useYearly = () => {
    const getYearly = async (years) => {
        const rawData = await Fetcher(years);
        return Parser(rawData);
    }

    return getYearly
}


export default useYearly;