import { Reducer } from 'redux';
import Types from './types';
import  globalDateState, { globalDateDispatchAction } from './state';
import { getToday } from '../../../utils/date';

const today = getToday(undefined,"YYYY-MM-DD");

const initialState : globalDateState = {
  date: '',
}

const dateReducer : Reducer<globalDateState, globalDateDispatchAction> = 
    (state = initialState, action) => {
        switch(action.type) {
            case Types.DATE_STORE_CHANGE:
                return {date: today === action.payload ? '' : action.payload }
            default:
                return state
        }
    }

export default dateReducer;