import { put } from 'redux-saga/effects';
import { rankURL } from '../../api';

import {
    fetchRankSuccess
} from './actions';
import { ONNA_CATEGORIES } from 'resources/constants/config';

export function* fetchRankAsync(date) {
    try {
        const response = yield fetch(`${rankURL}/${date}`, {
            method: "GET",
            headers: {
                category: ONNA_CATEGORIES
            }
        })
        const json = yield response.json()
        yield put(fetchRankSuccess(json))
    } catch (error) {
        console.log(error.message)
    }
}