import React, { useContext } from "react";
import ListCard from "./components/servers-card/servers-list.container";
import Score from "../../../../containers/score/score.provider";
import DasboardHeader from "../../../../providers/header";
import {serversResumeSelector,serversWinlosesSelector} from "../../../../../libs/redux";
import CardDashboardComponent from "./components/servers-card/servers-card.component";
import { I8nContext } from "../../../../../libs/i18n";
import {MainStructure, MainContainer, CardTitle, CardContent, SideBar, SideHeader, SideContent } from "../../components/layout";

const ServersDashboard: React.FC = () => {
  const { translate } = useContext(I8nContext);

  return (
    <MainStructure>
      <DasboardHeader title={translate("SERVICE")}  selector={serversWinlosesSelector} />
      <MainContainer>
          <div>
            <CardTitle className="pr-3 sm:pr-0">
              <p style={{width: "30%"}}>{translate("SERVICE")}</p>
              <p style={{width: "15%"}}>{translate("OVERALL")}</p>
              <p style={{width: "40%"}}>{translate("TAGS")}</p>
              <p style={{width: "15%"}}>{translate("CHECK")}</p>
            </CardTitle>
            <CardContent> 
              <ListCard Component={CardDashboardComponent} /> 
            </CardContent>
          </div>
          <SideBar>
            <SideHeader> {translate("SIDE_BAR")} </ SideHeader>
            <SideContent> <Score selector={serversResumeSelector} /> </SideContent>
          </SideBar>
        </MainContainer>
      </MainStructure>
  );
};

export default ServersDashboard;
