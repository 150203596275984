import { useDispatch } from 'react-redux'
import {
    BarDispatcher,
    FoodDispatcher,
    HostessDispatcher,
    ServersDispatcher,
    StatisticsDispatcher,
    ManagersDispatcher,
    MusicDispatcher,
    CashiersDispatcher,
    OnnaDispatcher
} from './index';

import { useSelector, shallowEqual } from 'react-redux';
import configSelector from '../redux/config/selector';

const useSync = () => {

    const dispatcher = useDispatch();
    const config = useSelector(configSelector, shallowEqual);

    const foodDispatcher = new FoodDispatcher(dispatcher);
    const onnaDispatcher = new OnnaDispatcher(dispatcher);
    const statisticsDispatcher = new StatisticsDispatcher(dispatcher);
    const barDispatcher = new BarDispatcher(dispatcher);
    const hostessDispatcher = new HostessDispatcher(dispatcher);
    const serversDispatcher = new ServersDispatcher(dispatcher);
    const managersDispatcher = new ManagersDispatcher(dispatcher);
    const musicDispatcher = new MusicDispatcher(dispatcher);
    const cashierDispatch = new CashiersDispatcher(dispatcher);

    const doSync = () => {
        if (config.date !== '') return;

        statisticsDispatcher.fetchStart();
        foodDispatcher.fetchStart();
        onnaDispatcher.fetchStart();
        barDispatcher.fetchStart();
        hostessDispatcher.fetchStart();
        serversDispatcher.fetchStart();
        managersDispatcher.fetchStart();
        musicDispatcher.fetchStart();
        cashierDispatch.fetchStart();
    }

    return doSync;
}


export default useSync