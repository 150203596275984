import React, { useContext } from "react";
import styled from "styled-components";
import Lang from "app/components/lang-dropdown";
import { I8nContext } from "../../../../libs/i18n";
import { device, colors } from 'resources/Theme';
import Login from 'resources/assets/login.png'
import Logout from 'resources/assets/logout.svg'
import {getToken} from "../../../../utils/auth";
import { RESTAURANT_NAME } from "resources/constants/config";

const Container = styled.div`
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,1);
    width: 100%;
    display: grid;
    grid-template-columns: 100px 1fr 150px;
    grid-template-rows: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: ${colors.white};
    padding: 10px 0;
    @media ${device.mobile} { 
        grid-template-columns: 80px 1fr 80px;
    }
    @media ${device.mobileS} { 
        grid-template-columns: 70px 1fr 70px;
    }
    @media ${device.mini} { 
        grid-template-columns: 2px 1fr 73px;
    }
    
    h1 {
        font-weight: 600;
        font-size: 30px;
        @media ${device.mobile} { 
            font-size: 25px;
        }
        @media ${device.mobileM} { 
            font-size: 18px;
        }
        @media ${device.mobileS} { 
            font-size: 15px;
        }
        @media ${device.mini} { 
            font-size: 12px;
        }
    }
`;

const Navbar = () => {
  const token = getToken()
  return (
    <Container>
        <img
            alt={""}
            src={token ? Logout : Login}
            style={{width: "1.5rem", height: "1.8rem", marginLeft: "1rem", cursor: "pointer"}}
            onClick={() => {
                if (!token) {
                    window.location.href = "/login"
                    return
                }

                localStorage.removeItem("auth")
                window.location.href = "/"
            }}
        />
        <h1>{RESTAURANT_NAME}</h1>
        <div> <Lang /> </div>
    </Container>
  );
};

export default Navbar;