import React from 'react';
import BaseDialog from '../BaseDialog.jsx';
import { P } from '@etiquette-ui/typography';
import PrimaryButton, { TextButton } from '@etiquette-ui/buttons';
import styles from './confirm.module.css';

const Actions = ({ onClose, closeText, onSubmit, submitText }) => {
  return (
    <div className={styles.buttonsSection}>
      <TextButton type='text' onClick={onClose}>{closeText}</TextButton>
      <PrimaryButton type='text' onClick={onSubmit}>{submitText}</PrimaryButton>
    </div>
  );
};

const InfoDialog = ({ open, onClose, onSubmit, config }) => {

  return (
    <BaseDialog
      open={open}
      title={config.title}
      header={config.header}
      onClose={onClose}
      isResponsive={false}
      showCloseButton={config.showCloseButton ?? true}
      footer={<Actions onClose={onClose} closeText={config.closeText} onSubmit={onSubmit} submitText={config.submitText} />}
    >
      <P>{config.body}</P>
    </BaseDialog>
  );
};

export default InfoDialog;
