import React, { useState } from 'react';
import { addValueToObject } from './utils/objectUtils.js';
import { useTableContext } from './providers/TableProvider.jsx';
import { SortableElement, sortableHandle } from 'react-sortable-hoc';
import styles from './style.module.css';

const Row = ({ item, className, count }) => {
  const { structure, dragable, dragHandle, gridColumns, globalState, setGlobalState, setGlobalStateBulk } = useTableContext();
  const [state, replaceState] = useState({});

  const setState = (key, value) => {
    const data = { ...state };
    addValueToObject(data, key, value);
    replaceState(data);
  };

  const setStateBulk = (items) => {
    const data = { ...state };
    for (const key in items) {
      addValueToObject(data, key, items[key]);
    }
    replaceState(data);
  };

  const RowContainer = structure.row;

  return structure.row ? (
    <RowContainer
      columns={gridColumns}
      dragable={dragable}
      className={`${styles.row} ${className}`}
      item={item}
      state={state}
      globalState={globalState}
      count={count}
    >
      {dragable && <DragHandle dragHandle={dragHandle} />}
      {structure.columns.map((col, i) => {
        const Component = col.body;
        return (
          <Component
            key={i}
            count={count}
            item={item}
            state={state}
            setState={setState}
            replaceState={replaceState}
            setStateBulk={setStateBulk}
            globalState={globalState}
            setGlobalState={setGlobalState}
            setGlobalStateBulk={setGlobalStateBulk}
          />
        );
      })}
    </RowContainer>
  ) : (
    <div className={`${styles.row} ${className}`} style={{gridTemplateColumns: gridColumns}}>
      {dragable && <DragHandle dragHandle={dragHandle} />}
      {structure.columns.map((col, i) => {
        const Component = col.body;
        return (
          <Component
            key={i}
            count={count}
            item={item}
            state={state}
            setState={setState}
            replaceState={replaceState}
            setStateBulk={setStateBulk}
            globalState={globalState}
            setGlobalState={setGlobalState}
            setGlobalStateBulk={setGlobalStateBulk}
          />
        );
      })}
    </div>
  );
};

const DragHandle = sortableHandle(({ dragHandle }) =>
    dragHandle ? (
        dragHandle
    ) : (
        <div style={{ cursor: 'pointer', fontSize: '26px', fontWeight: '900', margin: 'auto', userSelect: 'none' }}>::</div>
    )
);

export const Sortable = SortableElement((props) => <Row {...props} />);

export default Row;
export { Row };
