import React from "react";
import styled from "styled-components";
import Navbar from "./Navbar";
import { DateDisplay } from "app/components/calendar";
import TimeCounter from "app/components/time-counter";
import { device } from 'resources/Theme';

const Container = styled.div`
  width: 95%;
  margin: 30px auto;
  display: grid;
  grid-template-columns: auto 200px;
  grid-template-rows: auto;
  justify-content: space-between;
  align-items: center;
  @media ${device.mobile} { 
    margin: 20px auto;
  }
  @media ${device.mobileM} { 
    grid-template-columns: auto 45%;
  }
  @media ${device.mobileS} { 
    grid-template-columns: auto 35%;
  }
  @media ${device.mini} { 
    margin: 10px auto;
  }
`;

const Header = () => (
    <>
    <Navbar />
    <Container>
      <DateDisplay/>
      <TimeCounter className='regular' />
    </Container>
    </>
);

export default Header;
