import React, { useContext } from "react";
import { getImageURL, personFolder } from "../../../../../../../resources/api";
import theme from "../../../../../../../resources/assets/theme";
import { HostessRank } from "../../../../../../../libs/redux";
import styled from "styled-components";
import tw from "tailwind.macro";
import { colorTagGroup } from "../../../../../../../resources/styles/components/colorTagGroup";
import { I8nContext } from "../../../../../../../libs/i18n";
import {getColor, getColorBorder, getValueForBorder} from "resources/helpers/helper";
import {MainStructure, Container, WrapInfo, Line, WrapNum, WrapNumTag, WrapTime} from "../../../../../../../resources/styles/components/cards";
import {getToken} from "../../../../../../../utils/auth";
import AddCommentIcon from "resources/assets/add-comment-icon-blue.svg";

interface StyledProps {
  colors: {
    card: string;
    overall: string;
    drink: string;
    tag : string;
  };
}
const CardStyle = styled.div`
  ${tw`flex w-full border-solid rounded-lg border-2 sm:border-8 lg:border-4`}
  border-color: ${(props: StyledProps) => props.colors.card};
  justify-content: center;
  align-items: center;
  .overall {
    color: ${(props) => props.colors.overall};
  }
  .tags {
    color: ${(props) => props.colors.tag};
  }
  .drink {
    color: ${(props) => props.colors.drink};
  }
`;

const HostessCard: React.FC<HostessRank> = ({
  person,
  overall,
  tags,
  tagslist,
  lobby,
  drink,
  time,
  table,
  avg,
  comments,
  onClick
}) => {
const colors = {
    card: getColorBorder(getValueForBorder(avg, tags)),
    overall: getColor(overall),
    drink: drink === "No" ? theme.red : theme.green,
    tag : getColor(tags)
  };

  const { translate } = useContext(I8nContext);
  const token = getToken()

  return (
    <MainStructure data-avg={avg}>
      {
          !!token && (
              <div className="relative">
                <div className="absolute top-0 right-0">
                  <div className="flex justify-center bg-white rounded-full w-8 h-8 z-0 cursor-pointer" onClick={onClick}>
                    <img src={AddCommentIcon} className="w-4 z-10"/>
                  </div>
                </div>
              </div>
          )
      }
      <CardStyle colors={colors}>
        <div className={"flex flex-col w-full"}>
          <div className={"flex"}>
            <Container width={'25%'}>
              <img src={getImageURL(personFolder, person.img)} alt="" />
              <WrapInfo>
                <p>{person.name}</p>
                <p className="font-sm">{time}</p>
                <p className="bg_table"> {translate("TABLE")} {`${table}`} </p>
              </WrapInfo>
            </Container>
            <WrapNum width={'10%'}>
              <Line />
              <p className="overall">{overall ? overall : "-"}</p>
              <Line />
            </WrapNum>
            <WrapNumTag width={'41%'}>
              <Line width={'0'}/>
              <div className="flex items-center w-11/12 py-2">
                <p className="tags number  w-2/12 text-center"> {tags ? tags.toFixed(1).replace(/\.0+$/, "") : "-"}</p>
                <div className="w-10/12">
                  {tagslist.map((tag, index) => (
                      <div className={"flex flex-col items-center xl:flex-row xl:items-center xl:justify-center"} key={index}>
                        <div className={"tag0  pr-0 text-xs sm:text-base xl:pr-2"} data-tag={tag[0]}>{translate(tag[0])} </div>
                        <div className={`tag1 text-center text-xs sm:text-base ${colorTagGroup[tag[1]]}`}>{translate(tag[1])}</div>
                      </div>
                  ))}
                </div>
              </div>
              <Line />
            </WrapNumTag>
            <WrapTime width={'14%'}>
              <Line width={'0'}/>
              <p className={`lobby ${colorTagGroup[lobby]}`} > {lobby ==='unknown' ? '?' : lobby} </p>
              <Line />
            </WrapTime>
            <WrapNum width={'10%'}>
              <p className="drink">-</p>
            </WrapNum>
          </div>
        {
            !!token && Array.isArray(comments) && comments.map(comment => (
                <>
                  <div className="flex justify-center">
                    <hr className="w-full m-2 border-1" />
                  </div>

                  <span className="pl-4">Comment added by {`${comment.user.name} ${comment.user.last_name}`}</span>
                  <p className="pl-4">{comment.comment}</p>
                </>
            ))
        }
        </div>
      </CardStyle>
    </MainStructure>
  );
};

export default HostessCard;
