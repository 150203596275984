import React, { useContext } from "react";
import Header from "../../../homepage/components/SectionHeader";
import TableGridPreview from "./components/TableGrid_preview";
import TableHeaderPreview from "./components/TableHeader_preview";
import PercentageGridPreview from "./components/PercentageGrid_preview";
import MobileView from "./mobile";
import { useHistory } from "react-router-dom";
import { I8nContext } from "../../../../../libs/i18n";
import {MainStructure} from "app/views/sections/components/layout";

const SurveySuccessPreview: React.FC = () => {

  const history = useHistory();
  const handleRoute = () => history.push("/statistics");
  const { translate } = useContext(I8nContext);

  return (
    <div onClick={handleRoute} className='box'>
      <Header title={translate("SUMMARY")} />
      
      <MainStructure className='survey'>
          <div className="mobile">
            <MobileView leading={3} highlight={2} cell={3} />
          </div>

          <div className="desktop">
            <div className="column">
              <TableHeaderPreview leading={3} highlight={2} cell={3} title={"TOTAL"} />
              <TableGridPreview leading={3} highlight={2} cell={3} />
            </div>

            <div className="column">
              <TableHeaderPreview leading={3}  highlight={2} cell={3} title={translate("SUMMARY")} />
              <PercentageGridPreview leading={3} highlight={2} cell={3} />
            </div>
          </div>
        </MainStructure>
    </div>
  );
};

export default SurveySuccessPreview;
