import styled from "styled-components";
import tw from "tailwind.macro";

export const TableStructure = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Avenir;
  color: #fff;
  padding: 0 20px;
`;
export const TableContainer = styled.div`
  ${tw` max-h-screen  scrolling-touch text-white `}
`;
export const TableContent = styled.div`
  display: flex;
  height: 37vh;
  width: 90vw;

  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e2e8f0;
`;
export const TableWrap = styled.div`
    ${tw`rounded`}
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    background-color: #001B35;
    color: #fff;
`;
export const TableColumn = styled.div`
    ${tw`w-1/3  pl-4 md:pl-8 py-1`}
`;
export const TableColumnItem = styled.div`
    ${tw`w-10/12 sm:w-11/12`}  
    @media (max-width: 640px){
        font-size: 10px;
    }
    .loses-list {
        ${tw`flex items-center break-words`}  
        background-color: #c30e2e;
        color: white;
        border-radius: 8px;
        margin-top: 10px;
      }
      .wins-list {
        ${tw`flex items-center break-words`}  
        background-color: #077e15;
        color: white;
        border-radius: 8px;
        margin-top: 10px;
      }
`;
export const ItemRating = styled.p`
    ${tw`text-2xs sm:text-base px-1 sm:px-2 font-bold`}  
`;
export const ItemName = styled.p`
    ${tw`text-2xs sm:text-base font-medium`}  
`;
export const TableCenter = styled.div`
    ${tw`w-1/3  py-3`}
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align:center;
    @media (max-width: 640px){
        font-size: 10px;
    }
    background-color: #0e3040;
    color: #fff;

    p{
    ${tw`text-2xs font-hairline sm:text-sm`}
    }
`;
export const TableSpan = styled.span`
    ${tw`flex  text-xs sm:text-base`}
    background-color: #2B4B59;
    border-radius: 6px;
    margin  : 5px 0;
    padding: 3px 5px;
    @media (max-width: 640px){
        font-size: 11px;
    }
    p{
    ${tw`px-1`}
    } 
`;
export const TableService = styled.div`
    ${tw`flex justify-center w-2/3 rounded-lg`}
    margin: 5px 0;
    
    p{
        ${tw`text-2xs font-hairline sm:text-sm font-black`}
    } 
`;
export const TableMetChef = styled.div`
    ${tw`mt-inline flex items-center justify-center text-center`}
    
    p{
        ${tw`text-2xs font-hairline sm:text-sm font-black`}
    }
    
    img{
        width: 14px; 
        height: 14px; 
        margin-right: 6px;
    } 
`;

export const ItemRating_Preview = styled.p`
    ${tw`text-2xs sm:text-base lg:text-xs px-1 sm:px-2 font-bold`}  
`;
export const ItemName_Preview = styled.p`
    ${tw`text-2xs sm:text-base lg:text-xs font-medium`}  
`;
export const TableService_Preview = styled.div`
    ${tw`flex justify-center w-2/3 rounded-lg`}
    margin: 5px 0;
    padding: 0 3px;
    p{
        ${tw`text-2xs font-hairline sm:text-sm font-black`}
    } 
`;


