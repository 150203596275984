import React, { useContext } from "react";
import Header from "../../../homepage/components/SectionHeader";
import Box from "app/components/box";
import { getCheckColor, getColorPercent } from "resources/helpers/helper";
import { I8nContext } from "../../../../../libs/i18n";
import { cashierResumeSelector } from "../../../../../libs/redux";
import { useSelector, shallowEqual } from 'react-redux';
import {Container, Grid} from "./styles";
import { colors } from 'resources/Theme';

const Cashiers: React.FC = () => {
  const cashiersResume: any = useSelector(cashierResumeSelector, shallowEqual);
  const { activationsPercent, completePercent, notAceptableCount, totalBillsCount } = cashiersResume;
  const { translate } = useContext(I8nContext);

  const activationsPercentValue = activationsPercent ? activationsPercent.toFixed(0) : "0";
  const completePercentValue = completePercent ? completePercent.toFixed(0) : "0";

  return (
    <div className="box">
        <Header title={translate("CASHIERS")} />

            <Grid>
                <Box background={colors.white} radius="8px" className="minutes">
                    <Container color={getCheckColor(notAceptableCount)}>
                        <h1>{`${notAceptableCount}/${totalBillsCount}`}</h1>
                        <h2> {translate("CHECK_TIME_NOT_ACCEPTABLE")} </h2>
                    </Container>
                </Box>
            
                <Box background={colors.white} radius="8px" className="active">
                    <Container color={getColorPercent(activationsPercent)}>
                        <h1>{activationsPercentValue}%</h1>
                        <p> {translate("ACTIVATIONS")} </p>
                    </Container>
                </Box>
            
                <Box background={colors.white} radius="8px" className="completed">
                    <Container color={getColorPercent(completePercent)}>
                        <h1>{completePercentValue}%</h1>
                        <p> {translate("COMPLETED")} </p>
                    </Container>
                </Box>
            </Grid>
    </div>
   );
};

export default Cashiers;
