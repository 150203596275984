import Types from './types';
import { Action, Dispatch } from 'redux';

export class ServersDispatcher { 
    private readonly dispatch: Dispatch<Action>;

    constructor(dispatch: Dispatch<Action>) {
        this.dispatch = dispatch;
    }

    fetchStart = () => this.dispatch({type: Types.FETCH_START, payload: {}})
}