import { createContext, useState, useEffect } from "react";
import { getTranslation } from "./i18n.helper.ts";
import {translateDate} from "utils/date"

export const I8nContext = createContext();

export const I8nProvider = ({ children }) => {
    const [selectedLang, setSelectedLang] = useState();

    useEffect(() => {
        const langFromStorage = localStorage.getItem('lang');
        const initLang = langFromStorage ? +langFromStorage : 1;
        setSelectedLang(initLang)
    }, []);

    const values = {
        selectedLang,
        setSelectedLang: (lang) => {
            setSelectedLang(lang)
            localStorage.setItem('lang', lang);
        },
        translate: value => getTranslation(value, selectedLang),
        translateDate : date => translateDate(date, selectedLang ? "en" : "es")

    }

    return (<I8nContext.Provider value={values}>{children}</I8nContext.Provider>);
};
