import {combineReducers} from 'redux';
import hostessReducer from './hostess/reducer';
import barReducer from './bar/reducer';
import foodReducer from './food/reducer';
import onnaReducer from './onna/reducer';
import serversReducer from './servers/reducer';
import statisticsReducer from './statistics/reducer';
import managersReducer from './managers/reducer';
import musicReducer from './music/reducer';
import cashiersReducer from './cashiers/reducer';
import configReducer from './config/reducer';

const rootReducer = combineReducers({
    hostess: hostessReducer,
    bar: barReducer,
    food: foodReducer,
    onna : onnaReducer,
    servers: serversReducer,
    statistics: statisticsReducer,
    managers: managersReducer,
    music : musicReducer,
    cashiers: cashiersReducer,
    config: configReducer,
});

export default rootReducer;