import React, { useContext } from "react";
import { I8nContext } from "../../../../../../../libs/i18n";
import { getImageURL, personFolder } from "../../../../../../../resources/api";
import styled from "styled-components";
import theme from "../../../../../../../resources/assets/theme";
import {ServersRank } from "../../../../../../../libs/redux";
import tw from "tailwind.macro";
// import "../../../../../../resources/assets/styles/main.scss";
import { colorTagGroup } from "../../../../../../../resources/styles/components/colorTagGroup";
import {getColor, getColorBorder , getColorTags, getValueForBorder} from "resources/helpers/helper";
import {MainStructure, ContainerPreview, WrapInfoPreview, Line, WrapNumPreview, WrapNumTagPreview, WrapTimePreview} from "../../../../../../../resources/styles/components/cards";

interface StyledProps {
  colors: {
    cardColor: string;
    overallColor: string;
    drinkColor: string;
    tags : string;
  };
}

const CardStyle = styled.div`
  ${tw`flex w-full border-solid rounded border-2 sm:border-4 lg:border-4`}
  border-color: ${(props: StyledProps) => props.colors.cardColor};
  justify-content: center;
  align-items: center;
  .overall {
    color: ${(props) => props.colors.overallColor};
  }
  .tags {
    color: ${(props) => props.colors.tags};
  }
  .drink {
    color: ${(props) => props.colors.drinkColor};
  }
`;

const ServersCard: React.FC<ServersRank> = ({
  person,
  overall,
  tags,
  tagslist,
  checkTime,
  time,
  table,
  tableCardinal,
  avg,
}) => {

  const colors = {
    cardColor: getColorBorder(getValueForBorder(avg, tags)),
    overallColor: getColor(overall),
    tags : getColorTags(tags),
    drinkColor: theme.red,
  };
  const { translate } = useContext(I8nContext);

  return (
    <MainStructure>
      <CardStyle colors={colors}>
        <ContainerPreview>
          <ContainerPreview width={"30%"}>
            <img src={getImageURL(personFolder, person.img)} alt=""/>
            <WrapInfoPreview width={"50%"}>
              <p>{person.name}</p>
              <p className="font-sm">{time}</p>
              <p className="bg_table"> {translate("TABLE")} {`${table}`} </p>
              <p>{tableCardinal}</p>
            </WrapInfoPreview>
          </ContainerPreview>
          <WrapNumPreview width={"15%"}>
            <Line /> 
            <p className="overall">{overall ? overall : "-"}</p>
            <Line />
          </WrapNumPreview>
          <WrapNumTagPreview width={"40%"}>
            <Line width={"0"}/>
            <div className="flex items-center w-11/12 py-2">
              <p className="tags number w-2/12 text-center">{tags ? tags.toFixed(1).replace(/\.0+$/, "") : "-"}</p>
              <div className="w-10/12">
                {tagslist.map((item: any, index: number) => {
                  if(item[1]) return(
                  <div className={"flex justify-center lg:flex-col lg:items-center xl:flex-row"} key={index}>
                    <div className={"tag0 pr-2 lg:pr-0 xl:pr-2"}> {translate(item[0])}</div>
                    <div className={`tag1 ${ colorTagGroup[item[1]]}`} >{translate(item[1])}</div>
                  </div>
                )})}
              </div>
            </div>
            <Line />
          </WrapNumTagPreview>
          <WrapTimePreview width={"15%"}>
            <p className={`lobby text-center ${colorTagGroup[checkTime]}`} > {checkTime === "unknown" ? "?" : checkTime } </p>
          </WrapTimePreview>
        </ContainerPreview>
      </CardStyle>
    </MainStructure>
  );
};

export default ServersCard;
