import React, { useState } from 'react';

const FiltersContext = React.createContext();

const FilterProvider = ({ children }) => {
  const [filters, setFilters] = useState(new Map());

  const applyFilter = (key, filter) => {
    setFilters(new Map(filters.set(key, filter)));
  };

  const resetFilters = () => {
    setFilters(new Map());
  };

  const applyAllFilters = (items) => {
    let filtered = [...items];
    Array.from(filters.values()).forEach((filter) => {
      filtered = filter(filtered);
    });

    return filtered;
  };

  return <FiltersContext.Provider value={{ filters, applyFilter, resetFilters, applyAllFilters }}>{children}</FiltersContext.Provider>;
};

export const useFilters = () => React.useContext(FiltersContext);

export default FilterProvider;
export {FilterProvider};
