import React, { Fragment } from "react";
import { FlexContainer } from "./common";
import { LeftTitle, CellContainer, CellsGrid, CellValue } from "./common_preview";
import { getColorPercent, percentageValue, roundedPercentage, getColorAlt } from "resources/helpers/helper";

const GridRow: React.FC<{
  title: string;
  gridTemplate: string;
  data: any[];
  base: string;
  compare: string;
  shifts: any[];
}> = ({ title, gridTemplate, data, base, compare, shifts }) => {
  const displayTotal = data
    .map((value) => value[base])
    .reduce((acc, curr) => acc + curr);
  const compareTotal = data
    .map((value) => value[compare])
    .reduce((acc, curr) => acc + curr);

  const findData = (data: any[], targetShift: string) => {
    return data.find(({ shift }) => shift === targetShift);
  };

  return (
    <Fragment>
      <FlexContainer>
        <LeftTitle>{title}</LeftTitle>
      </FlexContainer>
      <CellsGrid gridTemplate={gridTemplate}>
        <CellContainer
          backgroundColor={getColorPercent(
            percentageValue(displayTotal, compareTotal),
            true
          )}
        >
          <CellValue
            color={getColorAlt(percentageValue(displayTotal, compareTotal))}
          >{`${roundedPercentage(displayTotal, compareTotal)}`}</CellValue>
        </CellContainer>
        {shifts
          .map((shift: string) => findData(data, shift))
          .map((value: any, i: number) => (
            <CellContainer
              key={i}
              backgroundColor={getColorPercent(
                percentageValue(value[base], value[compare]),
                true
              )}
            >
              <CellValue
                color={getColorAlt(
                  percentageValue(value[base], value[compare])
                )}
              >
                {roundedPercentage(value[base], value[compare])}
              </CellValue>
            </CellContainer>
          ))}
      </CellsGrid>
    </Fragment>
  );
};

export default GridRow;