import styled from 'styled-components';
import { device, colors } from 'resources/Theme';

interface perfect {
    color: string;
}

const Container = styled.div`
    width: 100%; 
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    border-radius: 20px;
    margin-top: 40px;

    @media ${device.mobile} { 
        margin-top: 30px;
        margin-bottom: 0px;
    }  
`;
const Navbar = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding-top: 5px;
    box-shadow: ${colors.black};
    background-color: ${colors.primary};
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
`;
const NavbarContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        position: absolute;
        top: 5;
        left: 0;
        margin-left: 25px;
        cursor: pointer;
        @media ${device.mobileS} { 
            margin-left: 10px;
        }
    }
`;
const Center = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;
const Title = styled.h1 `
    font-weight: 600;
    text-transform: uppercase;
    text-shadow: ${colors.shadow};
    font-size: 50px;
    @media ${device.laptop} { 
        font-size: 45px;
    }
    @media ${device.tablet} { 
        font-size: 30px;
    }
    @media ${device.mobileM} { 
        font-size: 25px;
    }
`;
const LangContainer = styled.div`
    position: absolute;
    top: 5;
    right: 0;
    margin-right: 5rem;
    @media ${device.mobile} { 
        margin-right: 1.5rem;
    }
    @media ${device.mobileM} { 
        margin-right: 2.5rem;
    }
`;
const Hero = styled.div`
    margin-top: 140px;
    display: grid;
    width: 1080px;
    justify-content: space-between;
    align-items: start;

    &.regular{
        grid-template-columns: 200px auto 200px;
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas: "perfect rating time"
                            "table rating chef";
        row-gap: 20px;

        .perfect{
            grid-area: perfect;
        }
        .table{
            grid-area: table;
        }
        .rating{
            grid-area: rating;
            justify-self: center;
            @media ${device.laptop} { 
                margin-top: -45px;
            }
            @media ${device.mobile} { 
                margin-top: -35px;
            }
        }
        .time{
            grid-area: time;
            @media ${device.laptop} { 
                margin-left: -220px;
            }
            @media ${device.mobile} { 
                margin-left: -116%;
            }
            @media ${device.mobileL} { 
                margin-left: -105%;
            }
        }
        .chef{
            grid-area: chef;  
        }

        @media ${device.laptop} { 
            width: auto;
            align-items: center;
            margin-top: 120px;
            grid-template-columns: repeat(3, 200px);
            grid-template-rows: auto;
            grid-template-areas: "perfect table chef"
                                    "- - time"
                                    "rating rating rating";
            row-gap: 30px;
            column-gap: 20px;
        }
        @media ${device.mobile} { 
            width: 95%;
            grid-template-columns: repeat(3, 30%);
            column-gap: 10px;
        }
        @media ${device.mobileL} { 
            margin-top: 100px;
            grid-template-columns: repeat(3, 33%);
            column-gap: 5px;
        }
        @media ${device.mobileXS} { 
            width: 90%;
            column-gap: 3px;
        }
    }


    &.row{
        margin-top: 130px;
        width: min(860px, 95%);
        grid-template-columns: repeat(4, min(200px, 24%));
        grid-template-rows: auto;
        grid-template-areas: "perfect table chef time";
        @media ${device.tablet} { 
            margin-top: 100px;
        }
        @media ${device.mobile} {
            grid-template-columns: repeat(2, min(200px, 40%));
            grid-template-rows: auto;
            grid-template-areas: "perfect table" 
                                 "chef time";
            margin-bottom: 60px;
            row-gap: 20px;
        }
        @media ${device.mobileM} { 
            grid-template-columns: repeat(2, min(200px, 45%));
        }          
    }
    
`;
const BoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
    width: fit-content;
    @media (max-width: 1200px) {
        margin: 20px 0;
    }
`;
const Box = styled.div`
    width: 100%;
    text-transform: uppercase;
    color: ${({ color }) => (color ? color : colors.white)};
`;
const Timer = styled.div`
display: grid;
align-items: center;
background: rgb(3, 98, 151);
background: linear-gradient(
    180deg,
    rgba(3, 98, 151, 5) 0%,
    rgba(1, 39, 57, 1) 60%,
    rgba(1, 39, 57, 1) 100%
);
background-repeat: no-repeat;
border-radius: 6px;
text-align: center;
width: 100%;
box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.6);
text-transform: uppercase;
color: ${colors.white};

&.regular{
    height: 70px;
    @media ${device.mobileL} { 
        height: 55px;
    } 
    @media ${device.mobileL} { 
        height: 45px;
    } 
    @media ${device.mobileXS} { 
        height: 40px;
    }

    p {
        font-size: 20px;
        line-height: 1.25rem;
        text-align: left;
        justify-self: center;
        align-self: end;
        margin: 0 auto;

        @media ${device.mobile} { 
          font-size: calc(0.8rem - 0.3vw);
          line-height: 0.8rem;
        }
        @media ${device.mobileM} { 
          font-size: 12px;
        }
        @media ${device.mobileS} { 
          font-size: 10px;
        }
        @media ${device.mobileXS} { 
          font-size: 9px;
        }
        @media ${device.mini} {
          font-size: 7px;
        }
    }
    h1 {
        font-size: 25px;
        justify-self: center;
        text-align: center;
        align-self: start;
       
        @media ${device.mobile} { 
            font-size: calc(1.3rem - 0.3vw);
        }
        @media ${device.mobileL} { 
            font-size: calc(1.3rem - 0.6vw);
        }
        @media ${device.mobileM} { 
            font-size: calc(1.3rem - 1vw);
        }
        @media ${device.mobileS} { 
            font-size: calc(1.3rem - 1.5vw);
        }
        @media ${device.mobileXS} { 
            font-size: calc(1.3rem - 2.5vw);
        }
        @media ${device.mini} {
          font-size: 12px;
          line-height: 0.5rem;
        }
    }
}

&.row {
    height: 100%;
    @media ${device.mobile} { 
        height: 45px;
    }
    p {
        font-size: 16px;
        line-height: 1.25rem;
        text-align: center;
        justify-self: center;
        @media ${device.mobile} { 
          font-size: calc(0.8rem - 0.3vw);
          line-height: 0.8rem;
        }
        @media ${device.mobileS} { 
          font-size: calc(0.8rem - 0.8vw);
        }
      }
      h1 {
        font-size: 23px;
        justify-self: center;
        text-align: center;
        @media ${device.mobile} { 
          font-size: 18px;
          line-height: 0.8rem;
        }
        @media ${device.mobileS} { 
          font-size: calc(1.3rem - 1.5vw);
          padding-right: 0px;
        }
      }
}
`;
const BoxColor = styled.div<perfect>`
    && {
      display: flex;
      justify-content: space-evenly;
      padding : 5px;
    }
    background: ${p => p.color};
    border-radius: 6px;
    width: 100%;
    height: 55px;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 40% 60%;
   
    &.regular {
      @media ${device.mobile} { 
        grid-template-columns: 40% 60%;
        padding-right: 3px;
      }
      @media ${device.mobileL} { 
        height: 45px;
      }
      @media ${device.mobileM} { 
        height: 35px;
      }
      @media ${device.mobileXS} { 
        height: 25px;
      }
    
      p {
        font-size: 14px;
        line-height: 1.25rem;
        text-align: left;
        justify-self: start;

        @media ${device.mobile} { 
          font-size: 10px;
          line-height: 0.8rem;
        }
        @media ${device.mobileM} { 
          font-size: 8px;
          line-height: 0.7rem;
        }
        @media ${device.mobileS} { 
          font-size: 6px;
          line-height: 0.5rem;
        }
      }
      h1 {
        font-size: 28px;
        justify-self: center;
        text-align: center;

        @media ${device.mobile} { 
          font-size: 20px;
        }
        @media ${device.mobileM} { 
          font-size: 18px;
        }
        @media ${device.mobileS} { 
          font-size: 13px;
        }
      }
    }
  
    &.row {
      grid-template-columns: 40% 60%;

      @media ${device.mobile} { 
        height: 45px;
      }
      p {
        font-size: 16px;
        line-height: 1.25rem;
        text-align: left;
        justify-self: start;
        @media ${device.laptop} { 
          font-size: 14px;
          line-height: 0.8rem;
        }
        @media ${device.ipad} { 
          font-size: 13px;
        }
        @media ${device.tablet} { 
          font-size: 12px;
        }
        @media ${device.mobileM} { 
          font-size: 11px;
        }
        @media ${device.mobileS} { 
          font-size: 10px;
          justify-self: center;
        }
      }
      h1 {
        font-size: 28px;
        justify-self: center;
        text-align: center;
        @media ${device.tablet} { 
          font-size: calc(28px - 0.3vw);
        }
        @media ${device.mobile} { 
          font-size: calc(28px - 0.6vw);
        }
        @media ${device.mobileM} { 
          font-size: calc(28px - 1vw);
        }
        @media ${device.mobileS} { 
          font-size: calc(28px - 2.3vw);
          padding-right: 0px;
        }
      }
    }
  `;
  
export {
    Container,
    Navbar,
    NavbarContainer,
    Center,
    Title,
    LangContainer,
    Hero,
    BoxContainer,
    Box,
    BoxColor,
    Timer
};