import { useContext } from "react";
import {LateralText} from '../styles';
import { I8nContext } from "libs/i18n";

const LateralTitle = ({data}) => {
  const { translate } = useContext(I8nContext);
return (
    <LateralText>     
      { data.map((title, i) => <div key={i}>{translate(title)}</div>)}
    </LateralText>
  );
};

export default LateralTitle;