import React from 'react';
import styled from 'styled-components';
import { device, colors } from 'resources/Theme';


const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    background-color: ${colors.primary};
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    text-shadow: -1px 1px 1px #000;
    padding: 5px 0;
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 600;

    @media ${device.mobile} { 
       font-size: 15px;
    }
    @media ${device.mini} {
        font-size: 12px;
    }
`;


const SectionHeader = ({title}) =>(
        <Container>
            <span>{title}</span>
        </Container>
    );

export default SectionHeader;