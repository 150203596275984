import React, { useMemo, Fragment, useContext } from "react";
import { useSelector, shallowEqual } from "react-redux";
import {ticketsSelector} from "libs/redux"
import { GridContainer } from "./common_preview";
import { I8nContext } from "../../../../../../libs/i18n";
import GridRowPreview from "./GridRowPreview";
interface HeaderProps {
  leading?: number | undefined;
  highlight: number;
  cell: number;
}

const PercentageGrid: React.FC<HeaderProps> = ({
  leading,
  highlight,
  cell,
}) => {
  const summary: any = useSelector(ticketsSelector, shallowEqual);
  const { data, shifts } = summary;
  const { translate } = useContext(I8nContext);

  return useMemo(() => {
    let mainGridTemplate = `${leading}fr `;
    let gridTemplate = `${highlight}fr `;

    if (Array.isArray(data)) {
      mainGridTemplate = `${leading}fr ${data.length * cell + highlight}fr`;
      data.forEach(() => {
        gridTemplate = `${gridTemplate} ${cell}fr`;
      });
    }
    return (
      <GridContainer gridTemplate={mainGridTemplate}>
        {Array.isArray(data) && (
          <Fragment>
            <GridRowPreview
              title={translate("ACTIVATIONSPERCENT")}
              gridTemplate={gridTemplate}
              data={data}
              base={"activations"}
              compare={"tickets"}
              shifts={shifts}
            />
            <GridRowPreview
              title={translate("COMPLETEDPERCENT")}
              gridTemplate={gridTemplate}
              data={data}
              base={"completed"}
              compare={"activations"}
              shifts={shifts}
            />
            <GridRowPreview
              title={translate("REJECTEDPERCENT")}
              gridTemplate={gridTemplate}
              data={data}
              base={"rejected"}
              compare={"activations"}
              shifts={shifts}
            />
          </Fragment>
        )}
      </GridContainer>
    );
  }, [summary]);
};

export default PercentageGrid;
