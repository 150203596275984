import React, {useContext, useEffect, useState} from 'react';
import {PrimaryButton} from '@etiquette-ui/buttons';
import BaseDialog from './BaseDialog';
import {Dropdown} from "../../etiquette/Dropdown";
import styled from "styled-components";
import TextArea from "../../etiquette/TextArea";
import {getToken} from "../../../utils/auth";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {ConfigDispatcher} from "../../../libs/redux";
import configSelector from "../../../libs/redux/config/selector";
import moment from "moment";
import {API_URL} from "../../../resources/api";
import {I8nContext} from "../../../libs/i18n";
import Card from "../../etiquette/Card";
import ArrowIcon from "resources/assets/arrow-icon.svg"

const InputDropdown = styled(Dropdown)`
  margin-bottom: 5px;
`;

const InputText = styled(TextArea)`
  width: 100% !important;
  min-width: 100% !important;
  margin-bottom: 2rem;
`;

const InfoDialog = ({onClose, ticketData, openSurveyDetails, back}) => {
    const [comment, setComment] = useState("");
    const [disable, setDisable] = useState(true);
    const [dropdownOpened, setDropdownOpened] = useState(null)
    const dispatcher = useDispatch();
    const configDispatcher = new ConfigDispatcher(dispatcher);
    const {date} = useSelector(configSelector, shallowEqual);
    const { translate } = useContext(I8nContext);

    const solutionOptions = [
        translate("Courtesy"),
        translate("Other"),
        translate("Not necessary"),
        translate("No action"),
    ];
    const courtesyTypes = [
        translate("Free drinks"),
        translate("Free desserts"),
        translate("Discount on bill"),
        translate("Future credit"),
    ];

    const [solution, setSolution] = useState({label: solutionOptions[0], value: 0});
    const [courtesyType, setCourtesyType] = useState({label: courtesyTypes[0], value: 0});

    const addComment = async() => {
        try {
            const newComment = {
                survey_id: ticketData.survey_id,
                ticket_id: ticketData.ticket_id,
                solution: solution.label,
                courtesy_type: solution.value === 0 ? courtesyType.label : null,
                comment
            }
            await fetch(`${API_URL}dashboard/tickets/comment`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": getToken()
                },
                body: JSON.stringify(newComment)
            });

            !date ? configDispatcher.dateChangeApp(moment(new Date()).format("YYYY-MM-DD")) : configDispatcher.dateChangeApp(date)
            onClose()
        } catch (e) {
            alert('error en el servidor');
        }
    }

    const fetchComment = async () => {
        try {
            const response = await fetch(`${API_URL}dashboard/tickets/comment/${ticketData.ticket_id}`, {
              method: "GET",
              headers: {
                  "Authorization": getToken()
              }
            });
            if (response.status !== 200) {
                return;
            }

            const {solution, courtesy_type, comment} = await response.json();
            setSolution({
                label: solution,
                value: solutionOptions.indexOf(solution)
            });

            const initialCourtesyType = {label: courtesyTypes[0], value: 0}
            setCourtesyType(courtesy_type === null ? initialCourtesyType : {
                label: courtesy_type,
                value: courtesyTypes.indexOf(courtesy_type)
            });
            setComment(comment);
            setDisable(!comment)
        } catch (e) {
            alert('error en el servidor');
        }
    };

    const handleSolution = (value) => setSolution(value);
    const handleCourtesyType = (value) => setCourtesyType(value);
    const handleComment = (e) => {
        setComment(e.target.value);
        setDisable(!e.target.value);
    };

    useEffect(() => {
        fetchComment();
    }, []);

    return (
        <BaseDialog
            open={true}
            isResponsive={true}
            showCloseButton={true}
            onClose={onClose}
        >
            {
                !!ticketData &&
                <div className={"overflow-scroll scrollbar-hide max-h-75"}>
                    <header className="flex justify-center pb-4">
                        <span className="text-sm font-bold">{translate("Take Action")}</span>
                    </header>
                    {
                        !!ticketData.survey_id && (
                            <div className={"flex justify-end mb-1"} onClick={() => openSurveyDetails(ticketData.survey_id, back)}>
                                <span className="font-bold text-xs text-blue-500 cursor-pointer">{translate("View Survey Rating")}</span>
                                <img alt={""} src={ArrowIcon}/>
                            </div>
                        )
                    }
                    <body className="flex flex-col gap-2">
                        <Card shadow="light">
                            <div className="font-bold text-xs mb-2">{translate("General Information")}</div>
                            <div className="flex">
                                <div className="w-1/2">
                                    <span className="font-bold text-xs">{translate("Survey ID")}</span>
                                </div>
                                <div className="w-1/2">
                                    <span className="text-xs">{ticketData.survey_id || "N/A"}</span>
                                </div>
                            </div>
                            <div className="flex">
                                <div className="w-1/2">
                                    <span className="font-bold text-xs">{translate("Ticket ID")}</span>
                                </div>
                                <div className="w-1/2">
                                    <span className="text-xs">{ticketData.ticket_id || "N/A"}</span>
                                </div>
                            </div>
                            <div className="flex">
                                <div className="w-1/2">
                                    <span className="font-bold text-xs">{translate("Language")}</span>
                                </div>
                                <div className="w-1/2">
                                    <span className="text-xs">{ticketData.language  || "N/A" }</span>
                                </div>
                            </div>
                            <div className="flex">
                                <div className="w-1/2">
                                    <span className="font-bold text-xs">{translate("Table")}</span>
                                </div>
                                <div className="w-1/2">
                                    <span className="text-xs">{ticketData.table || "N/A"}</span>
                                </div>
                            </div>
                            <div className="flex">
                                <div className="w-1/2">
                                    <span
                                        className="font-bold text-xs whitespace-nowrap text-red-800">{translate("Activation Time")}</span>
                                </div>
                                <div className="w-1/2">
                                    <span className="text-xs">{ticketData.activation_time  || "N/A"}</span>
                                </div>
                            </div>
                            <div className="flex">
                                <div className="w-1/2">
                                    <span className="font-bold text-xs text-red-800">{translate("Time Sent")}</span>
                                </div>
                                <div className="w-1/2">
                                    <span className="text-xs">{ticketData.time_sent  || "N/A"}</span>
                                </div>
                            </div>
                            <div className="flex">
                                <div className="w-1/2">
                                    <span className="font-bold text-xs">{translate("Visiting Time")}</span>
                                </div>
                                <div className="w-1/2">
                                    <span className="text-xs">{ticketData.visiting_time ? `${ticketData.visiting_time} hrs` : "N/A"}</span>
                                </div>
                            </div>
                            {/*TODO: Get the number of guests*/}
                            {/*<div className="flex">*/}
                            {/*    <div className="w-1/2">*/}
                            {/*        <span className="font-bold text-sm">Number of Guests</span>*/}
                            {/*    </div>*/}
                            {/*    <div className="w-1/2">*/}
                            {/*        <span>{ticketData.number_of_guests}</span>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="flex">
                                <div className="w-1/2">
                                    <span className="font-bold text-xs">{translate("Waiter")}</span>
                                </div>
                                <div className="w-1/2">
                                    <span className="text-xs">{ticketData.waiter}</span>
                                </div>
                            </div>
                            <div className="flex">
                                <div className="w-1/2">
                                    <span className="font-bold text-xs">{translate("Total Bill")}</span>
                                </div>
                                <div className="w-1/2">
                                    <span className="text-xs">{ticketData.total_bill}</span>
                                </div>
                            </div>
                        </Card>
                        <Card shadow="light">
                            <div className="font-bold text-xs py-2">{translate("Customer Solution")}</div>
                            <div onClick={() => setDropdownOpened("solutions")} >
                                <InputDropdown
                                    labelProps={{
                                        label: translate("Solution")
                                    }}
                                    selectProps={{
                                        onChange: handleSolution,
                                        options: solutionOptions.map((opt, i) => ({label: opt, value: i})),
                                        placeholder: translate('Pick one...'),
                                        value: solution,
                                        menuIsOpen: dropdownOpened === "solutions"
                                    }}
                                />
                            </div>
                            {
                                solution.value === 0 && (
                                    <div onClick={() => setDropdownOpened("courtesy_types")}>
                                        <InputDropdown
                                            labelProps={{
                                                label: translate("Courtesy Type")
                                            }}
                                            selectProps={{
                                                onChange: handleCourtesyType,
                                                options: courtesyTypes.map((opt, i) => ({label: opt, value: i})),
                                                placeholder: translate('Pick one...'),
                                                value: courtesyType,
                                                menuIsOpen: dropdownOpened === "courtesy_types"
                                            }}
                                        />
                                    </div>
                                )
                            }
                            <InputText
                                inputProps={{
                                    disabled: false,
                                    onChange: handleComment,
                                    required: true,
                                    value: comment
                                }}
                                labelProps={{
                                    label: translate('Write your comment...')
                                }}
                            />
                        </Card>
                        <PrimaryButton disabled={disable} onClick={addComment}>{translate("Add Comment")}</PrimaryButton>
                    </body>
                </div>
            }
        </BaseDialog>
    );
};
export default InfoDialog;

