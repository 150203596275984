import styled from 'styled-components';
import { device, colors} from 'resources/Theme';


const TableContainer = styled.div`
    background: ${colors.secundary};
    border-radius: 6px 6px 0px 0px;
`;
const Header = styled.div`
    width: 100%;
    background: ${colors.mediumblue};
    text-align: center;
    text-transform: uppercase;
    padding: 5px 0;
    border-radius: 6px 6px 0px 0px;
    font-size: 14px;
`;
const Grid = styled.div`
    background: ${colors.secundary};
    display: grid;
    grid-template-columns: repeat(${({columns}) =>(columns)}, 1fr);
    grid-template-rows: repeat(3, 43px);

    width: 100%;
    justify-content: center;
    align-items: center;
    padding-bottom: 15px;

    @media ${device.mobile} {
        grid-template-rows: repeat(3, 35px);
    }
    @media ${device.mobileM} {
        grid-template-rows: repeat(3, 25px);
    }

    &.golden{
        grid-template-rows: 1fr;
        border: 3px solid ${colors.white};
        border-radius: 0px 0px 10px 10px;
        padding: 5px 0px;
        font-size: 16px;    
        text-transform: uppercase;
        @media ${device.mobileM} {
            font-size: 12px;    
        }
    }
    
    div:nth-child(n){
        text-align: center;
    }
    
`;

const Row = styled.div`
    border-bottom: 1px dashed ${colors.gray};
    text-transform: uppercase;
    font-size: 28px;

    @media ${device.mobile} {
        font-size: 18px;
    }
    @media ${device.mobileM} {
        font-size: 16px;
    }
    @media ${device.mobileS} {
        font-size: 12px;
    }
`;

const LateralText = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 43px);

    justify-content: right;
    align-items: center;

    text-transform: uppercase;
    font-size: 14px;
    text-align: right;
    margin-bottom: 15px;

    div:nth-child(n){
        line-height: 0.8rem;

        @media ${device.mobile} {
            line-height: 0.6rem;
        }
        @media ${device.mobileS} {
            line-height: 0.5rem;
        }
    }

    @media ${device.mobile} {
        font-size: 11px;
        grid-template-rows: repeat(3, 35px);
    }
    @media ${device.mobileL} {
        font-size: 9px;
    }
    @media ${device.mobileM} {
        grid-template-rows: repeat(3, 25px);
    }
    @media ${device.mobileS} {
        font-size: 9px;
    }
`;

const Container = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 14% 85%;
    column-gap: 15px;
    justify-content: center;
    align-items: end;

    @media ${device.ipad} {
        grid-template-columns: 20% 80%;
    }

    @media ${device.mobile} {
        column-gap: 5px;
    }
    @media ${device.mobileM} {
        grid-template-columns: 25% 75%;
    }
`;

export {
    Header,
    Container,
    TableContainer,
    Grid,
    Row,
    LateralText
};