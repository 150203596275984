import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useSelector, shallowEqual } from 'react-redux';
import { ticketsSelector} from '../../../../../../libs/redux';
import { FlexContainer } from './common';

interface GridProps {
  gridTemplate: string
}

const HeaderContainer = styled.div<GridProps>`
    display: grid;
    grid-template-columns: ${({gridTemplate})=>gridTemplate};
    padding: 0 8px;
    margin-bottom: 10px;
`;

const HeaderTitle = styled.p`
    font-weight: 700;
    font-size: 8px;
`;

interface HeaderProps {
  leading?: number | undefined,
  highlight?: number | undefined,
  cell?: number | undefined,
  title: string
}

const TableHeader: React.FC<HeaderProps> = ({leading, highlight, cell, title}) => {

  const summary : any = useSelector(ticketsSelector, shallowEqual);
  const {shifts} = summary;

  return useMemo(()=> {

    let gridTemplate =  `${leading}fr ${highlight}fr `
    
    if(Array.isArray(shifts)){
      shifts.forEach(()=>{
        gridTemplate = `${gridTemplate} ${cell}fr `
      }); 
    }

    return (
      <HeaderContainer gridTemplate={gridTemplate}>
        <FlexContainer></FlexContainer>
        <FlexContainer><HeaderTitle>{title}</HeaderTitle></FlexContainer>
        {
          Array.isArray(shifts) && shifts.map((shift: string, i)=>(<FlexContainer key={i}><HeaderTitle>{shift.toUpperCase()}</HeaderTitle></FlexContainer>))
        }
      </HeaderContainer>
    );
  },[summary])
};

export default TableHeader;