import React, {useMemo} from 'react';
import styled, { keyframes, Keyframes } from 'styled-components';
import {getRandom} from '../../../../resources/api'
import {flipInX} from 'react-animations';

const fadeinAnimation = keyframes`${flipInX}`;

interface AnimationProps { 
    animation: Keyframes
}
const Animation = styled.div`
    display: flex;
    animation: 1s ${(props: AnimationProps) => props.animation};
    width: 100%;
    height: 100%;
`;

interface ScoreProps {
    url1: string,
    url2: string,
    url3: string
}
const Score: React.FC<ScoreProps> = ({url1, url2, url3 }) => {
    return useMemo(()=>{
        return(
            <>
            <Animation  key={getRandom()} animation={fadeinAnimation}>
                <img src={url1} alt="" />
            </Animation>
            <Animation  key={getRandom()} animation={fadeinAnimation}>
                <img src={url2} alt="" />
            </Animation>
            <Animation  key={getRandom()} animation={fadeinAnimation}>
                <img src={url3} alt="" />
            </Animation>
            </>
        );
    },[url1, url2, url3]) 
}

export default Score;