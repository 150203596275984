const CalculateFields = (element, data) => {

    const activations = element.activations;
    const withData = element.withData;
    const withStar = element.withStar;

    const getPercentage = CalcPercent(activations);

    data.activations.push(activations || "-");
    data.withData.push(getPercentage(withData));
    data.withStar.push(getPercentage(withStar));
}


const CalcPercent = (total) => {

    return (target) => {
        if (!target || !total) return "-";

        const percent = (target / total) * 100;
        const percentNumber = percent % 1 === 0 ? percent : percent.toFixed(1);

        return `${percentNumber}%`
    }
}

export default CalculateFields;

