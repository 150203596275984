import React, { useContext } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { ticketsSelector } from "libs/redux";
import { I8nContext } from "../../../../../libs/i18n";
import { colors } from "resources/Theme";
import Box from "app/components/box";
import {
  getColorPercent,
  percentageValue,
  roundedPercentage,
  getColorPercentInverted,
} from "../../../../../resources/helpers/helper";
import { MobileContainer, MobileContent } from "app/components/box/mobile";

const GridRow: React.FC<{
  title: any;
  data: any[];
  base: string;
  compare: string;
  inverted?: boolean;
}> = ({ title, data, base, compare, inverted = false }) => {
  const displayTotal = data
    .map((value) => value[base])
    .reduce((acc, curr) => acc + curr);
  const compareTotal = data
    .map((value) => value[compare])
    .reduce((acc, curr) => acc + curr);

  const getColor = inverted ? getColorPercentInverted : getColorPercent;

  return (
    <MobileContent
      color={getColor(percentageValue(displayTotal, compareTotal))}
    >
      <h1>{`${roundedPercentage(displayTotal, compareTotal)}`}</h1>
      <p>{title}</p>
    </MobileContent>
  );
};

const GridBlackRow: React.FC<{
  title: any;
  data: any[];
  base: string;
  compare: string;
  inverted?: boolean;
}> = ({ title, data, base, compare }) => {
  const displayTotal = data
    .map((value) => value[base])
    .reduce((acc, curr) => acc + curr);

  return (
    <MobileContent color={colors.black}>
      <h1>{displayTotal}</h1>
      <p>{title}</p>
    </MobileContent>
  );
};

const Ticket: React.FC<{
  title: string;
  data: any[];
  totalKey: string;
  activationsKey: string;
}> = ({ title, data }) => {

  let totalCompleted = 0;
  let totalActive = 0;
  data.forEach((item) => {
    totalCompleted += item.completed;
    totalActive += item.activations;
  });

  let percentage = 0;

  if (totalActive) {
    percentage = Math.round((totalCompleted / totalActive) * 100);
  }

  function getColor(){
    return (percentage === 0 || percentage > 60) ? colors.black : colors.red;
  }

  return (
    <MobileContent color={getColor()}>
      <h1>{`${percentage}`}%</h1>
      <p>{title}</p>
    </MobileContent>
  );
};

interface HeaderProps {
  leading?: number | undefined;
  highlight: number;
  cell: number;
}
const PercentageGrid: React.FC<HeaderProps> = ({ highlight, cell }) => {
  const tickets: any = useSelector(ticketsSelector, shallowEqual);
  const { data } = tickets;
  const { translate } = useContext(I8nContext);

  const StarTitle = <span>{translate("ONLY_STARS")}</span>;
  const CrossTitle = <span>{translate("CROSSMARK")}</span>;
  const CheckTitle = <span>{translate("CHECKMARK")}</span>;

  let gridTemplate = `${highlight}fr `;

  if (Array.isArray(data)) {
    data.forEach(() => {
      gridTemplate = `${gridTemplate} ${cell}fr`;
    });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {Array.isArray(data) && (
        <>
          <MobileContainer>
            <Box background={colors.white} radius="8px">
              <GridRow
                title={translate("ACTIVATIONS")}
                data={data}
                base={"activations"}
                compare={"tickets"}
              />
            </Box>
            <Box background={colors.white} radius="8px">
              <Ticket
                title={translate("COMPLETED")}
                data={data}
                totalKey={"tickets"}
                activationsKey={"activations"}
              />
            </Box>
          </MobileContainer>

          <MobileContainer>
            <Box background={colors.white} radius="8px">
              <GridBlackRow
                title={CrossTitle}
                data={data}
                base={"rejected"}
                compare={"activations"}
              />
            </Box>
            <Box background={colors.white} radius="8px">
              <GridBlackRow
                title={StarTitle}
                data={data}
                base={"onlystar"}
                compare={"activations"}
                inverted={true}
              />
            </Box>
            <Box background={colors.white} radius="8px">
              <GridBlackRow
                title={CheckTitle}
                data={data}
                base={"morestars"}
                compare={"activations"}
              />
            </Box>
          </MobileContainer>
        </>
      )}
    </div>
  );
};

export default PercentageGrid;
