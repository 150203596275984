import {put} from 'redux-saga/effects';
import {rankURL} from '../../api';

import {
    fetchRankSuccess,
    fetchRankStart,
    fetchRankFailure,
} from './actions';

export function* fetchRankAsync (date) {
    try {
        yield put(fetchRankStart())
        const response = yield fetch(`${rankURL}/${date}`, {
            method: "GET"
        })
        const json = yield response.json()
        yield put(fetchRankSuccess(json))
    } catch (error) {
        console.log(error.message)
        yield put(fetchRankFailure())
    }
}