import OpencloseTypes from './types'
import { OpenCloseState } from './state';

export const fetchOpencloseStart = () => ({
    type: OpencloseTypes.FETCH_START
});

export const fetchOpencloseSuccess = (data: OpenCloseState) => ({
    type: OpencloseTypes.FETCH_SUCCESS,
    payload: data
});

export const fetchOpencloseFailure = () => ({
    type: OpencloseTypes.FETCH_FAILURE
});