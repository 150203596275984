import { put } from 'redux-saga/effects';

import {
    fetchResumeSuccess
} from './actions';

import { resumeURL } from '../../api';

export function* fetchResumeAsync(date) {
    try {
        const response = yield fetch(`${resumeURL}/${date}`, {
            method: "GET"
        })
        const json = yield response.json()
        yield put(fetchResumeSuccess(json))
    } catch (error) {
        console.log(error.message)
    }
}