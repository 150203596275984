import theme from "resources/assets/theme";
import styled from "styled-components";
import tw from "tailwind.macro";

export const MainStructure = styled.div`
    ${tw` max-h-screen w-full scrolling-touch text-white `}
    margin-top: 10px;
    background: #001B35;
    border-radius: 10px;

    .green {
        color: ${theme.green};
    }
    .red {
        color: ${theme.red};
    }
    .orange {
        color: ${theme.orange};
    }
`;
export const Container = styled.div<{ width?: string }>`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: ${({ width }) => (width ? width : "100%")};
    
    img {
        background-color: #fff;
        border-radius: 50%;
        height: 60px;
        width: 60px;
        @media (max-width: 640px){
            background-color: #fff;
            border-radius: 50%;
            height: 35px;
            width: 35px;
        } 
    }

    .container_font{
        width: 20%;
        font-weight: 700;
        padding: 13px;
        @media (max-width: 640px){
            font-size: 8px;
            padding: 0px;
        }
    }
`;
export const ContainerPreview = styled.div<{ width?: string }>`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: ${({ width }) => (width ? width : "100%")};

    img {
        background-color: #fff;
        border-radius: 50%;
        height: 50px;
        width: 50px; 
    }

    .container_font{
        width: 20%;
        font-weight: 700;
        padding: 13px;
        @media (max-width: 640px){
            font-size: 10px;
        }
    }
`;
export const WrapInfo = styled.div<{ width?: string }>`
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: end;
    margin: 5px 0;
    width: ${({ width }) => (width ? width : "50%")};
   

    p{
        font-weight: 700;
        font-size: 16px;
        @media (max-width: 640px){
            font-size: 9px;
        }
    }

    .bg_table{
        background-color: #2B4B59;
        border-radius: 6px;
        padding: 3px 5px;
        width: fit-content;
        align-self: flex-end;
        font-size: 14px;
        @media (max-width: 640px){
            font-size: 9px;
        }
    }

    .font-sm {
        font-weight: 400;
        font-size: 12px;
        @media (max-width: 640px){
            font-size: 8px;
        }
    }
`;
export const WrapInfoPreview = styled.div<{ width?: string }>`
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: end;
    margin: 5px 0;
    width: ${({ width }) => (width ? width : "50%")};

    p{
        font-weight: 700;
        font-size: 12px;
    }
    
    .bg_table{
        background-color: #2B4B59;
        border-radius: 6px;
        padding: 3px 5px;
        width: fit-content;
        align-self: flex-end;
        font-size: 10px;
    }

    .font-sm {
        font-weight: 400;
        font-size: 9px;
    }
`;
export const WrapNum = styled.div<{ width?: string }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: ${({ width }) => (width ? width : "50%")};
    text-align: center;

    p {
        ${tw`font-bold text-xs uppercase font-bold sm:text-2xl`}
        font-weight: 700;
        padding: 0 0.25rem;
        font-size: 1.5rem;
        @media (max-width: 640px){
            font-size: 1rem;
        }
    }

    .drink {
        @media (max-width: 640px){
            font-size: 0.8rem;
        }
    }
`;
export const WrapNumTagsMusic = styled.div<{ width?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({ width }) => (width ? width : "50%")};
    text-align: center;

    p {
        ${tw`font-bold text-xs font-bold sm:text-2xl`}
        font-weight: 700;
        padding: 0 0.25rem;
        font-size: 1.5rem;
        @media (max-width: 640px){
            font-size: 1rem;
        }
    }

    .drink {
        @media (max-width: 640px){
            font-size: 0.8rem;
        }
    }
`;
export const WrapNumPreview = styled.div<{ width?: string }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: ${({ width }) => (width ? width : "50%")};
    text-align: center;

    p {
        font-weight: 700;
        padding: 0 0.25rem;
        font-size: 1rem;
        text-transform: uppercase;
    }
`;
export const WrapNumTag = styled.div<{ width?: string }>`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 10px;
    width: ${({ width }) => (width ? width : "100%")};

    @media (max-width: 640px){
        padding: 0px;
    }

    .number {
        font-weight: 700;
        padding: 0 0.25rem;
        font-size: 1.5rem;

        @media (max-width: 640px){
            font-size: 1rem;
        }
    }
    .tagTitle {
        margin: 2px 2px;
        text-align: right;
        font-weight: 600;
        padding-right: 2px;
        font-size: 0.8rem;

        @media (max-width: 640px){
            font-size: 0.5rem;
          }
    }
    .tagValue {
        margin: 2px 2px;
        font-style: italic;
        text-align: left;
        font-weight: 500;
        font-size: 0.9rem;

        img {
            width: 17px; 
            height: 17px; 
        }
        @media (max-width: 640px){
            font-size: 0.6rem;
            img {
                width: 13px; 
                height: 13px; 
                margin-left: 5px;
            }
          }
    }
    .Chef{
        display: flex;
        align-items: center;
        width: 75px;
        text-align: center;
        font-weight: 600;
        font-size: 0.8rem;
        

        @media (max-width: 640px){
            font-size: 0.5rem;
            width: 50px;
            margin-left: 5px;
          }
    }

    .wrapTag{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 5px 0;
        width: 100%;
        @media (max-width: 640px){
            padding-right: 0px;
            font-size: 0.7rem
        }
    }

`;
export const WrapNumTagPreview = styled.div<{ width?: string }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: ${({ width }) => (width ? width : "100%")};

    .number {
        font-weight: 700;
        font-size: 1rem;
    }
    .tagTitle {
        margin: 2px 2px;
        text-align: right;
        font-weight: 600;
        padding-right: 2px;
        font-size: 0.7rem;
    }
    .tagValue {
        margin: 2px 2px;
        font-style: italic;
        text-align: left;
        font-weight: 500;
        font-size: 0.7rem;

       img {
            width: 13px; 
            height: 13px;
            }
          
    }

    .wrapTag{
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        margin: 5px 0;
        width: 100%;
    }
    .Chef{
        display: flex;
        align-items: center;
        width: 85px;
        text-align: center;
        font-weight: 600;
        font-size: 0.8rem;
    }

`;
export const WrapTime = styled.div<{ width?: string }>`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: ${({ width }) => (width ? width : "50%")};
    font-size: 1rem;
    text-align: center;

    p {
        font-weight: 700;
        padding: 0 0.35rem;
        @media (max-width: 640px){
            font-size: 0.7rem;
            padding: 0;
        }
}
`;
export const WrapTimePreview = styled.div<{ width?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    width: ${({ width }) => (width ? width : "50%")};

    p {
        font-weight: 700;
        padding: 0 0.35rem;
        @media (max-width: 640px){
            font-size: 0.8rem;
        }
    }
`;
export const Line = styled.div<{ width?: string }>`
    height: 2rem;
    width: ${({ width }) => (width ? width : "2px")};
    background-color: #a0aec0;
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e2e8f0;

    @media (min-width: 640px){
        height: 2.5rem;
    }
`;
export const BorderCardColor = styled.div`
${tw``}
width: 600px
  
`;
