import React from 'react';
import TableProvider from './providers/TableProvider.jsx';
import Dynamic from './Dynamic.jsx';
import FilterProvider from './providers/Filters.jsx';
import PropTypes from 'prop-types';

const DynamicTable = ({ config = {}, structure, dragable = false, dragHandle=null, items, onSortEnd, className }) => {
  return (
    <TableProvider tableStructure={structure} tableConfig={config} isDragable={dragable} dragHandle={dragHandle}>
      <FilterProvider>
        <Dynamic items={items.map(i => ({...i, id: i.ticketId}))} onSortEnd={onSortEnd} className={className} />
      </FilterProvider>
    </TableProvider>
  );
};

export default DynamicTable;

DynamicTable.propTypes = {
  structure: PropTypes.object,
  items: PropTypes.array,
  config: PropTypes.object,
  dragable: PropTypes.bool,
  dragHandle: PropTypes.element,
  onSortEnd: PropTypes.func,
  className: PropTypes.string,
};