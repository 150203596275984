import {Grid} from '../styles';

const TableHeader = ({data}) => (

  <Grid columns={!!data && data.length} className="golden">
    { !!data && data.map((title, i) => <div key={i}>{title}</div>)}
  </Grid>

);

export default TableHeader;