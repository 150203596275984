import React from 'react';
import RatingContainer from '../components/rating/container';

import { useSelector, shallowEqual } from 'react-redux';


interface RatingProps {
  selector : any,
  Component: any,
  preview?: boolean,
}
const RatingProvider: React.FC<RatingProps>= ({selector, Component, preview}) => {

    const data : any = useSelector(selector, shallowEqual);

    const {wins, loses, avg, perfect, touch, chefmet} = data;
    const props = {
        wins,
        loses,
        avg,
        perfect,
        touch,
        Component,
        preview,
        chefmet,
    };
  
    return (<RatingContainer {...props} />);
};
  
export default RatingProvider;