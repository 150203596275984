import CalculateFields from "../common/CalculateFields";

const parser = (rawData) => {
    const data = {
        title: [],
        activations: [],
        withData: [],
        withStar: []
    };

    for (const key in rawData) {
        if (Object.hasOwnProperty.call(rawData, key)) {
            const element = rawData[key];

            data.title.push(key);

            CalculateFields(element, data)
        }
    }

    return data;

}

export default parser;