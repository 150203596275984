import {useEffect} from 'react';
import {Switch, Route} from 'react-router-dom';
import styled from 'styled-components';
import HostessDashboard from './views/sections/dashboards/hostess';
import 'tailwindcss/dist/base.css';
import FoodDashboard from './views/sections/dashboards/food';
import OnnaDashboard from './views/sections/dashboards/onna';
import ServersDashboard from './views/sections/dashboards/servers';
import StatisticsDashboard from './views/sections/dashboards/statistics';
import HomePage from './views/homepage';
import ManagersDashboard from './views/sections/dashboards/managers';
import BarDashboard from './views/sections/dashboards/bar';
import MusicDashboard from './views/sections/dashboards/music';
import useSync from '../libs/redux/useSync';
import {FloatButton} from "./etiquette/Float";
import {useAlert} from "./etiquette/AlertProvider";
import ALERT_TYPES from "./etiquette/types";
import AllTicketsDialog from "./components/dialogs/AllTickets";
import DetailsTicketsDialog from "./components/dialogs/TicketDetails";
import LoginPage from "./views/sections/login"
import {getToken} from "../utils/auth";
import SurveyDetails from "./components/dialogs/SuveyDetails";

const AppStyles = styled.div`
  background: #001B35;
  background: linear-gradient(180deg,
  #001B35 30%,
  rgba(3, 98, 151, 5) 130%);
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
`;

function App() {
    const doSync = useSync()
    useEffect(() => doSync(), [])
    const alert = useAlert()
    const token = getToken()

    const openSurveyDetails = (surveyId: any, back: any) => {
        alert({
            type: ALERT_TYPES.CUSTOM,
            config: {
                body: SurveyDetails,
                props: {
                    surveyId,
                    back
                }
            }
        })
    }

    const openDetails = (ticketData: any) => {
        const back = openDetails.bind(null, ticketData)
        alert({
            type: ALERT_TYPES.CUSTOM,
            config: {
                body: DetailsTicketsDialog,
                props: {
                    ticketData,
                    openSurveyDetails,
                    back
                }
            }
        })
    }

    const openAllTickets = () => {
        alert({
            type: ALERT_TYPES.CUSTOM,
            config: {
                body: AllTicketsDialog,
                props: {
                    openDetails
                }
            }
        })
    }

    return (
        <AppStyles>
            <Switch>
                <Route exact={true} path='/' component={HomePage}/>
                <Route exact={true} path='/login' component={LoginPage}/>
                <Route exact={true} path='/hostess' render={() => <HostessDashboard/>}/>
                <Route exact={true} path='/bar' render={() => <BarDashboard/>}/>
                <Route exact={true} path='/food' render={() => <FoodDashboard/>}/>
                <Route exact={true} path='/servers' render={() => <ServersDashboard/>}/>
                <Route exact={true} path='/statistics' render={() => <StatisticsDashboard/>}/>
                <Route exact={true} path='/managers' render={() => <ManagersDashboard/>}/>
                <Route exact={true} path='/music' render={() => <MusicDashboard/>}/>
                <Route exact={true} path='/onna' render={() => <OnnaDashboard/>}/>
            </Switch>
            {
                /*  @ts-ignore*/
                !!token && <FloatButton onClick={openAllTickets}> Add Comment</FloatButton>
            }
        </AppStyles>
    );
};

export default App;