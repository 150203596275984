
import RankTypes from './types'
import { OnnaRankState } from './state';

export const fetchRankStart = () => ({
    type: RankTypes.FETCH_START
});

export const fetchRankSuccess = (data: OnnaRankState) => ({
    type: RankTypes.FETCH_SUCCESS,
    payload: data
});
