
import {combineReducers} from 'redux';

import OpencloseReducer from '../common/states/openclose/reducer';
import RankReducer from './states/rank/reducer';
import WinlosesReducer from './states/winloses/reducer';

const foodReducer = combineReducers({
    openclose: OpencloseReducer,
    rank: RankReducer,
    winloses: WinlosesReducer,
});


export default foodReducer;