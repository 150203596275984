import RankTypes from './types'
import { HostessRankState } from './state';

export const fetchRankStart = () => ({
    type: RankTypes.FETCH_START
});

export const fetchRankSuccess = (data: HostessRankState) => ({
    type: RankTypes.FETCH_SUCCESS,
    payload: data
});
export const fetchRankFailure = () => ({
    type: RankTypes.FETCH_FAILURE
})