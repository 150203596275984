import React, { useContext } from "react";
import Header from "../../../homepage/components/SectionHeader";
import {hostessWinlosesSelector} from "../../../../../libs/redux";
import Provider from "app/providers/rating";
import Rating from "app/components/rating/component";
import MobileView from "../../../homepage/components/MobileCards";
import ListCard from "./components/hostess-card/hostess-list.container";
import HostessCard from "./components/hostess-card/hostess-card.preview";
import { useHistory } from "react-router-dom";
import { I8nContext } from "../../../../../libs/i18n";
import {
  MainStructure, 
  ContainerPreview, 
  CardTitle, 
  CardContentPreview, 
  RatingContainer 
} from "app/views/sections/components/layout";

const HostessPreview: React.FC= () => {

  const history = useHistory();
  const handleRoute = () => history.push("/hostess");
  const { translate } = useContext(I8nContext);

  return (
    <div onClick={handleRoute} className='box'>
      <Header title={translate("HOSTS")} />

      <MainStructure>
          <div className="mobile">
            <Provider selector={hostessWinlosesSelector} Component={MobileView} />
          </div>

          <RatingContainer>
            <Provider selector={hostessWinlosesSelector} Component={Rating} preview={true}/>
          </RatingContainer>

          <div className="desktop">
            <ContainerPreview>
              <CardTitle>
                <p style={{width: "25%"}}>{translate("HOSTESS")}</p>
                <p style={{width: "10%"}}>{translate("OVERALL")}</p>
                <p style={{width: "35%"}}>{translate("TAGS")}</p>
                <p style={{width: "15%"}}>{translate("LOBBY")}</p>
                <p style={{width: "15%"}}>{translate("DRINK")}</p>
              </CardTitle>
              <CardContentPreview> 
                <ListCard Component={HostessCard} /> 
              </CardContentPreview>
            </ContainerPreview>
          </div>
        </MainStructure>
    </div>
  );
};

export default HostessPreview;
