import React, {useState, forwardRef, useMemo} from 'react';
import styles from './style.module.css';
import PropTypes from 'prop-types';
import { PRIMARY, INPUTS, WRONG, TEXT } from '@etiquette-ui/colors';
import { format } from 'date-fns';

import { Input } from '../Input/index.jsx';
import  Calendar  from '../Calendar';
import "react-datepicker/dist/react-datepicker.css";

const regular = {
  '--bg': INPUTS,
  '--wrong': WRONG,
  '--right': PRIMARY,
  '--text': TEXT,
};

export const DateInput = ({ 
  className='',
  inputProps={},
  labelProps={},
  errorProps={},
  iconProps={},
  containerProps={},
  showIcon=true,
  value,
  setValue,
  dateFormat="dd/MM/yyyy",
  language='en',
  calendarProps={},
}) => {

  const dateLabelProps = !!labelProps ? labelProps : { label: 'Select a date' };
  const { error, ...restErrorProps } = errorProps;  
  const [startDate, setStartDate] = useState(!!value ? value : new Date() );
  const { style: containerStyles = {}, ...restContainerProps } = containerProps;  
  const { onChange: onCalendarChange =  (date) => {}, ...restCalendarProps } = calendarProps;  

  const { disabled: inputDisabled = false } = inputProps;
  
  const handleDateChange = (date) => {
    if(!!date){
      setStartDate(date);
      setValue(date);
      onCalendarChange(date)
    }
  }  

  const iconValues = useMemo(()=>{
    if(!showIcon) return({});

    const {icon, ...restIconProps} = iconProps;
    return({
      ...restIconProps, 
      icon: !!icon ? icon : 'calendar'
    });
    
  },[iconProps]);

  const CustomInput = forwardRef(({ onClick }, ref) => (
    <div onClick={onClick}>
      <Input 
        iconProps = {iconValues}
        inputProps={{ ...inputProps,  value: format(startDate, dateFormat), readOnly: true}} 
        labelProps={dateLabelProps}
        errorProps={{}}
        ref={ref}/>
    </div>
  ));

  const CustomCalendarContainer = ({ className, children }) => {
    return (
      <div className={styles.calendarContainer} >
        <div className={`${className} ${styles.calendar}`}>
         {!!error && <p className={styles.invalidAlert} {...restErrorProps}>{error}</p>}
          <div style={{ position: "relative" }}>{children}</div>
        </div>
      </div>
    );
  };

  return (
    <div 
      style={{...regular, ...containerStyles}} 
      className={styles.mainContainer}
      {...restContainerProps}
    >
      <Calendar 
        className={className}
        selected={startDate} 
        closeOnScroll={true}
        dateFormat={dateFormat}
        language={language}
        onChange={handleDateChange}
        inline={false}
        disabled={inputDisabled}
        customInput={
          <CustomInput />          
        }
        calendarContainer={CustomCalendarContainer}
        {...restCalendarProps}
      >
       
      </Calendar>
    </div>
    
  );
};

DateInput.propTypes = {
  className: PropTypes.string,
  containerProps: PropTypes.object,
  inputProps: PropTypes.object,
  labelProps: PropTypes.object,
  errorProps: PropTypes.object,
  iconProps: PropTypes.object,
  showIcon: PropTypes.bool,
  value: PropTypes.any,
  setValue: PropTypes.any,
  format: PropTypes.string,
  language: PropTypes.string,
  calendarProps: PropTypes.object,
};