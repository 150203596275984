import React, { useContext } from "react";
import { useSelector, shallowEqual } from "react-redux";
import Rating from "../../../../providers/rating";
import { 
  Container, 
  Navbar, 
  Box,
  BoxColor,
  NavbarContainer,
  Hero,
  Center,
  Title,
  LangContainer 
} from "./styles";
import RatingDashboardComponent from "../../../../components/rating/component";
import backArrow from "resources/assets/back-option.png";
import { I8nContext } from "../../../../../libs/i18n";
import Lang from "../../../../components/lang-dropdown";
import { getColorNegative } from "../../../../../resources/helpers/helper";
import TimeCounter from "../../../../components/time-counter";
import {DateDisplay} from "app/components/calendar";

interface HeaderProps {
  title: string;
  handler: () => void;
  selector: any;
  withRating: boolean;
}


const formatPercentZero = (percent: any) =>
  percent === "-" ? "0" : Math.round(percent);

const DasboardHeader: React.FC<HeaderProps> = ({
  title,
  handler,
  selector,
  withRating,
}) => {
  const { translate } = useContext(I8nContext);

  const items: any = useSelector(selector, shallowEqual);
  const { perfect, touch, chefmet } = items;

  const perfectGreen = (perfect.count - perfect.total) === 0 ? 0 : 1;
  const touchGreen = (touch.count - touch.total) === 0 ? 0 : 1; 

  return (
    <Container>
        <Navbar>
          <NavbarContainer>
            <img
              src={backArrow}
              alt="Arrow"
              onClick={handler}
            />

            <Center>
              <Title>{title}</Title>
              <DateDisplay />
            </Center>

            <LangContainer>
              <Lang />
            </LangContainer>
          </NavbarContainer>
        </Navbar>

        <Hero className={` ${withRating ? "regular" : "row"}`}>
          <Box className="perfect">
            <BoxColor color={getColorNegative(perfectGreen)} className={` ${withRating ? "regular" : "row"}`}>
              <h1>{formatPercentZero(!!perfect && perfect.count)}/{!!perfect && perfect.total}</h1>
              <p style={{marginLeft : "5px"}}> {translate("PERFECTION")} </p>
            </BoxColor>
          </Box>

          <Box className="table">
            <BoxColor color={getColorNegative(touchGreen)} className={` ${withRating ? "regular" : "row"}`}>
              <h1  style={{paddingRight : "15px"}}>{!!touch ? touch.count : ''}/{!!touch && touch.total}</h1>
              <p style={{maxWidth : "80%"}}> {translate("TOUCH_TABLE_CAPS")} </p>
            </BoxColor>
          </Box>

          {withRating && (
            <div className="rating">
              <Rating
                selector={selector}
                Component={RatingDashboardComponent}
              />
            </div>
          )}

          <Box className="time">
            <TimeCounter className={`${withRating ? "regular" : "row"}`} />
          </Box>

          <Box className="chef">
            <BoxColor color={getColorNegative(chefmet)} className={` ${withRating ? "regular" : "row"}`}>
              <h1>{formatPercentZero(!!chefmet && chefmet.count)}/{!!chefmet && chefmet.total}</h1>
              <p style={{maxWidth : "30%"}}>{translate("Met Chef")}</p>
            </BoxColor>
          </Box>
        </Hero>
    </Container>
  );
};

export default DasboardHeader;
