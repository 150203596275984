import React, {useMemo} from 'react';
import { managersRankSelector, ManagersRank  } from '../../../../../../../libs/redux';
import { useSelector, shallowEqual } from 'react-redux';
import styled, { keyframes, Keyframes } from 'styled-components';
import {API_URL, getRandom} from '../../../../../../../resources/api';
import {fadeIn} from 'react-animations';
import {useAlert} from "../../../../../../etiquette/AlertProvider";
import ALERT_TYPES from "../../../../../../etiquette/types";
import {getToken} from "../../../../../../../utils/auth";
import SurveyDetails from "../../../../../../components/dialogs/SuveyDetails";
import DetailsTicketsDialog from "../../../../../../components/dialogs/TicketDetails";

const fadeinAnimation = keyframes`${fadeIn}`;
interface StyledProps {
  animation : Keyframes
}
const Animation = styled.div`
    animation: 1s ${(props : StyledProps) => props.animation};
    
`;

const ListCardContainer : React.FC<{Component : any}> = ({Component}) => {

    const items = useSelector(managersRankSelector, shallowEqual)
    const dialogAlert = useAlert()

    const fetchTicketDetails = async(surveyId: number) => {
        const response = await fetch(`${API_URL}dashboard/tickets/${surveyId}`, {
            method: "GET",
            headers: {
                "Authorization": getToken()
            }
        })
        if (response.status !== 200) {
            throw new Error('error en el servidor')
        }
        return await response.json()
    }

    const openSurveyDetails = (surveyId: any, back: any) => {
        dialogAlert({
            type: ALERT_TYPES.CUSTOM,
            config: {
                body: SurveyDetails,
                props: {
                    surveyId,
                    back
                }
            }
        })
    }

    const openDetails = (ticketData: any) => {
        const back = openDetails.bind(null, ticketData)
        dialogAlert({
            type: ALERT_TYPES.CUSTOM,
            config: {
                body: DetailsTicketsDialog,
                props: {
                    ticketData,
                    openSurveyDetails,
                    back
                }
            }
        })
    }


    const showDialog = async(rank: ManagersRank) => {
        try {
            const ticketData = await fetchTicketDetails(rank.id)
            openDetails(ticketData)
        } catch(e) {
            alert(e);
        }
    }

    return useMemo(()=> {
      return (
        <div className="flex w-full flex-col overflow-y-auto overflow-x-hidden scrolleable">
          <Animation key={getRandom()} animation = {fadeinAnimation}>
            {
              Array.isArray(items) && items.map((item: ManagersRank) =><Component key={item.id} {...item} onClick={() => showDialog(item)} />)
            }
          </Animation>
        </div>
      );
    },[JSON.stringify(items)])
};

export default ListCardContainer;