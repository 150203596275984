export const API_URL : string = process.env.REACT_APP_API || "http://localhost:8097/";
export const ADMIN_API_URL : string = process.env.REACT_APP_ADMIN_API_URL || "https://api.adminsite.test.appsinti.com/";

export const winsFolder : string = "wins/";
export const losesFolder : string = "loses/";
export const personFolder : string = "persons/";



export const imageURL : string = "https://appsinti.com/images/common/";

export const getImageURL : (folder: string, name: string) => string =
    (folder, name) =>  `${imageURL}${folder}${name}`;
    
export const getRandom : ()=> number = ()=> Math.floor(Math.random() * 10000)
