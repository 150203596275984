import styled from 'styled-components';
interface BoxProps {
    background? : string,
    radius? : string
}
const Box = styled.div((props: BoxProps) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: props.background? props.background : 'transparent',
    width: '100%',
    height: '100%',
    borderRadius: props.radius? props.radius :  '17px',
}));

export default Box;