import styled from 'styled-components';
import { device , colors} from 'resources/Theme';

const Container = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: center;
position: fixed;
height: 100%;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: rgba(21,27,34,0.7);
z-index: 200;
cursor: pointer;
`;
const Week = styled.div `
display: flex;
flex-direction: column;
justify-content: center;
width: 385px;
color: ${colors.black} !important;
background-color: ${colors.white};
@media ${device.mobile}{
    width: fit-content !important;
}

.DayPicker-Month {
    border-collapse: separate;
}
.DayPicker-WeekNumber {
    outline: none;
}
.DayPicker-Day {
    outline: none;
    border: 1px solid transparent;
    border-radius: 0 !important;
    height: 30px !important;
    width: 30px !important;
    padding: 8px 12px !important;
    @media ${device.mobileM}{
        padding: 0px 0 !important;
    }
}
.DayPicker-Day--hoverRange {
    background-color: ${colors.lightblue} !important;
    border-radius: 0 !important;

    &:nth-last-child(1) {
        border-radius: 0 50% 50% 0 !important;
      }
    &:nth-last-child(7) {
        border-radius: 50% 0 0 50% !important;
    }
}

.DayPicker-Day--selectedRange {
    background-color: ${colors.lightblue} !important;
    color: ${colors.black} !important;

    &:nth-last-child(1) {
        border-radius: 0 50% 50% 0 !important;
      }
    &:nth-last-child(7) {
        border-radius: 50% 0 0 50% !important;
    }
}

.DayPicker-Day--selectedRangeStart {
    background-color: ${colors.blue} !important;
    color: ${colors.white} !important;
    border-radius: 50% !important;
}

.DayPicker-Day--selectedRangeEnd {
    background-color: ${colors.blue} !important;
    color: ${colors.white} !important;
}

.DayPicker-Day--hoverRange:hover {
    opacity: 0.7;
}
`;
const Day = styled.div `
display: flex;
flex-direction: column;
justify-content: center;
width: 385px;
color: ${colors.black} !important;
background-color: ${colors.white};
@media ${device.mobile}{
    width: fit-content !important;
}

.DayPicker-Day{
    text-align: center;
    height: 30px !important;
    width: 0px !important;
    padding: 8px 12px !important;
    margin: 0 auto !important;
    border-radius: 100%;
    @media ${device.mobileM}{
        padding: 0px 0 !important;
    }
}
.DayPicker-Day: hover {
  background-color: ${colors.lightblue} !important;
  color: ${colors.black} !important;
}
.DayPicker-Day--selected { 
  background-color: ${colors.blue} !important;
}

.DayPicker-Day--disabled {
    color: ${colors.gray} !important;
    opacity: 0.2;
}

`;
const Header = styled.div`
width: 100%;
background: ${colors.blue};
color: ${colors.white};
text-transform: uppercase;
text-align: left; 

div{
    width: 85%;
    margin: 0 auto;
    padding: 20px 0;
}

span {
    font-size: 16px;
}

h1 {
    font-size: 34px;
}
`;
const Footer = styled.div`
width: 100%;
display: flex;
justify-content: flex-end; 

div{
    width: 70%;
    text-align: center;
    display: flex;
    justify-content: space-evenly; 
    color: ${colors.blue};
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 0 15px;
    font-size: 20px;
}
`;
const Box = styled.div `
    background: ${colors.clear};
    width: 380px;
    @media ${device.mobile}{
        width: 280px;
    }
`;
const Navbar = styled.div `
    width: 85%;
    height: 20px;
    margin: 10px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${colors.black};
    @media ${device.mobile}{
        width: 95%;
        margin: 8px auto;
    }
`;
const Arrow = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 100%;
`;

const Left = styled.div`
    margin: 0 auto;
    width: 0.9em;
    height: 0.7em;
    background-color: ${colors.black};
    clip-path: polygon(50% 0%, 0 50%, 50% 100%);
    &:hover {
        background: ${colors.blue};
    }

    &.disabled{
        background-color: ${colors.gray};
        clip-path: polygon(50% 0%, 0 50%, 50% 100%);
        &:hover {
            background: ${colors.lightblue};
        }
    }
`;

const Right = styled(Left)`
    transform: rotate(180deg);
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    row-gap: 10px;
    column-gap: 25px;
    padding: 0 10px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 15px auto;
`;

const GridItem = styled.div`
    width: max-content; 
    display: inline-block; 
    margin: 0 auto;

    input{
        display: none;

        + label{
            display: table;
            height: 40px;
            width: 40px; 
            border-radius: 50%;
            text-align: center;
            padding-top: 8px;
            color: ${({color}) => (color || colors.black)};
            cursor: pointer;
            font-size: 14px;
            &:hover {
                background: ${colors.lightblue};
            }
        }

        &:checked {
            + label{
            background: ${colors.blue};
            color: ${colors.white};
            border-radius: 50%;
            font-weight: 500;
            }
        }

        &:disabled {
            + label{
            color: ${colors.gray};
            &:hover {
              opacity: 0.4;
            }
            }
        }
    }
`;
export {
    Container,
    Week,
    Day,
    Header,
    Footer,
    Box,
    Navbar,
    Arrow,
    Left,
    Right,
    Grid,
    GridItem
};
