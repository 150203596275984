import React, { useContext } from "react";
import Header from "../../../homepage/components/SectionHeader";
import {barWinlosesSelector,barRankSelector} from "../../../../../libs/redux";
import Provider from "../../../../providers/rating";
import Rating from "../../../../components/rating/component";
import MobileView from "../../../homepage/components/MobileCards";
import CardPreview from "../../../../containers/info-card/card.preview";
import withSelector from "../../../../containers/info-card/card.provider";
import { useHistory } from "react-router-dom";
import { I8nContext } from "../../../../../libs/i18n";
import {
  MainStructure, 
  ContainerPreview, 
  CardTitle, 
  CardContentPreview, 
  RatingContainer 
} from "app/views/sections/components/layout";

const BarPreview: React.FC = () => {


  const history = useHistory();
  const handleRoute = () => history.push("/bar");

  const { translate } = useContext(I8nContext);
  return (
    <div onClick={handleRoute} className="box">
      <Header title={translate("BAR/DRINKS")} />

      <MainStructure>
          <div className="mobile">
            <Provider selector={barWinlosesSelector} Component={MobileView} />
          </div>

          <RatingContainer>
            <Provider selector={barWinlosesSelector} Component={Rating} preview={true}/>
          </RatingContainer>

          <div className="desktop">
            <ContainerPreview>
              <CardTitle>
                <p className="w-1/3"> {translate("WINS")} </p>
                <p className="w-1/3">{translate("DETAILS")}</p>
                <p className="w-1/3"> {translate("LOSES")}</p>
              </CardTitle>
              <CardContentPreview> 
                {withSelector(CardPreview)(barRankSelector)} 
              </CardContentPreview>
            </ContainerPreview>
          </div>
        </MainStructure>
    </div>
  );
};

export default BarPreview;
