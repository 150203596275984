import moment from 'moment';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { Container, Day } from "../styles";
import {Layout} from '../containers/Layout';

export const Daily = ({onCancel, setDaySelected , daySelected, onOK}) => {

    const handleDayClick = (day,  modifiers = {}, { selected }) => {
      if (modifiers.disabled) {
        return;
      }
      setDaySelected(selected ? undefined : day)
    }
    
    const todayYear = moment(new Date()).format('YYYY');
    const todayDate = moment(new Date()).format('ddd, MMM D');
    const today = moment(new Date()).format('ddd, MMM D, YYYY');
  
  
    const year = daySelected ? moment(daySelected).format('YYYY') : todayYear;
    const date = daySelected ? moment(daySelected).format('ddd, MMM D') : todayDate;
    const day = daySelected ? moment(daySelected).format('ddd MMM D, YYYY') : today;
    const fulldate = daySelected ? moment(daySelected) : today;
  
  
    return (
      <Container>
        <Day>
          <Layout 
          year={year}
          date={date}
          onCancel={onCancel}
          onOK={() => (onOK(fulldate, day))}
          >
            <DayPicker
              selectedDays={daySelected}
              onDayClick={handleDayClick}
              disabledDays={[{after: new Date() }]}
            />
          </Layout>
        </Day>
      </Container>
      );
  }