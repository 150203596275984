
import {all, fork } from 'redux-saga/effects'
import hostessSaga from "./hostess/saga";
import barSaga from "./bar/saga";
import * as foodSaga from "./food/saga";
import * as onnaSaga from "./onna/saga";
import * as musicSaga from "./music/saga";
import serversSaga from "./servers/saga";
import * as statisticsSaga from "./statistics/saga";
import managersSaga from "./managers/saga";
import cashiersSaga from "./cashiers/saga";
import configSaga from "./config/saga";

export default function* rootSaga() {
    yield all(
      [ hostessSaga,
        barSaga,
        ...Object.values(foodSaga),
        ...Object.values(onnaSaga),
        ...Object.values(musicSaga),
        serversSaga,
        ...Object.values(statisticsSaga),
        managersSaga,
        cashiersSaga,
        configSaga,
      ].map(fork)
    );
}