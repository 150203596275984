import {createSelector} from 'reselect';
import GlobalState from '../root-state';

const selectConfig = (state : GlobalState) => state.config;

const configSelector = createSelector(
    [selectConfig],
    data => data
)

export default configSelector;
