import theme from "../assets/theme";

export interface IIndexable {
  [key: string]: any;
}

export const getColor: (score: number | null) => string = (score) => {
  if (score !== null) {
    if (score >= 4.5) return theme.serviceGood;
    if (score === 0) return theme.white;
    if (score < 4.5 && score >= 4) return theme.orange;
    if (score < 4 && score > 0) return theme.serviceBad;
  }

  return theme.black;
};

export const getColorPercentPerfect: (perfect: string | null) => string = (
  perfect
) => {
  if (perfect === "") return theme.white;
  if (perfect === "No one") return theme.serviceBad;

  return theme.serviceGood;
};

export const getValueForBorder = (avg: number | null, tags: number) => {
  if(tags >= 4) return tags;
  return avg;
}

export const getColorBorder: (score: number | null) => string = (score) => {
  if (score !== null) {
    if (score >= 4.4) return theme.serviceGood;
    if (score < 4.4 && score >= 4) return theme.orange;
  }

  return theme.serviceBad;
};

export const getColorTags: (score: number | null) => string = (score) => {
  if (score !== null) {
    if (score >= 4.5) return theme.serviceGood;
    if (score < 4.5 && score >= 4) return theme.orange;
    if (score < 4 && score > 0) return theme.serviceBad;
  }
  return theme.white;
};

export const getColorAlt: (avg: number | null) => string = (avg) => {
  if (avg !== null && avg > 0) {
    return theme.white;
  }
  return theme.black;
};

export const getBackgroundColor: (score: number | null) => string = (score) => {
  if (score !== null) {
    if (score >= 4.5) return theme.serviceGood;
    if (score === 0) return theme.white;
    if (score < 4.5 && score >= 4) return theme.serviceRegular;
    if (score < 4 && score > 0) return theme.serviceBad;
  }

  return theme.white;
};

export const getColorTime: (time: string | null) => string = (time) => {
  if (time) {
    switch (time) {
      case  "1 min":
      case "0-5 min":
      case "5-10 min": return theme.serviceGood;
      case "10-20 min":
      case "20-30 min":
      case "30-45 min":
      case "45-60 min":
      case "+60 min": return theme.serviceBad;

      default: return theme.serviceBad;
    }
  }
  return theme.black;
};

export const getCheckColor: (notAceptableCount: number) => string = (notAceptableCount) => {
  if(notAceptableCount>0) return theme.serviceBad;
  return theme.serviceGood;
}

export const getColorPercent: (score: number, alt?: boolean) => string = (
  score,
  alt = false
) => {
  if (score >= 95) return theme.serviceGood;
  if (score < 95 && score > 0) return theme.serviceBad;
  if (score === 0) return alt ? theme.white : theme.black;
  return alt ? theme.white : theme.black;
};

export const colorTable: (score: number, alt?: boolean) => string = (
  score,
  alt = false
) => {
  if (score >= 95) return theme.serviceGood;
  if (score < 95 && score > 0) return theme.serviceBad;
  if (score === 0) return alt ? theme.table: theme.white;
  return alt ? theme.table: theme.white;
};

export const getColorPercentInverted: (score: number) => string = (score) => {
  if (score === 0) return theme.black;
  if (score <= 5) return theme.serviceGood;
  return theme.serviceBad;
};

export const percentageValue = (item: number, complete: number) => {
  return complete && item ? Math.round((item * 100) / complete) : 0;
};

export const roundedPercentage = (item: number, complete: number) => {
  const value = percentageValue(item, complete);
  return value ? `${value}%` : `0%`;
};

export const getColorNegative: (score: number | null | string) => string = (
  score
) => {
  if (score === 0 || score === "-") return theme.serviceGood;
  return theme.serviceBad;
};

export const getPersonColor: (id: number) => string = (id) => {
  if (id === -3) return theme.red; //No manager check on table
  
  return theme.white;
};
