import React from "react";
import styles from './float.module.css';
import {
  PRIMARY,
  PRIMARY_DARK,
  PRIMARY_LIGHT,
  DISABLED_BUTTON,
  TEXT_PLACEHOLDER
} from '@etiquette-ui/colors';
import AddCommentButton from '../assets/add-comment-icon-white.svg'

export const FloatButton = ({ 
  children, 
  className,
  disabled,
  ...props
  }) => {

    const float = {
      '--primary-color': PRIMARY,
      '--hover-color': PRIMARY_DARK,
      '--active-color': PRIMARY_LIGHT,
      '--shadow': '#03326956',
      '--font-color': '#fff',
      '--hover-font': '#fff'
    };
    const disable = {
      '--primary-color': DISABLED_BUTTON,
      '--hover-color': '#6969693D',
      '--active-color': DISABLED_BUTTON,
      '--shadow': '#6969693D',
      '--font-color': TEXT_PLACEHOLDER,
      '--hover-font': '#69696988'
     };

    const Add = () => (
      <svg width="36" height="36" viewBox="0 0 36 36">
        <rect className={styles.a} width="36" height="36"/>
        <g transform="translate(7.5 7.5)">
          <line className={styles.b} y2="21" transform="translate(10.5 0)"/>
          <line className={styles.b} x2="21" transform="translate(0 10.5)"/>
        </g>
      </svg>
     );

    return (
      <div className={className}>
        <div className={styles.container}>
          <div
            style={disabled ? disable : float}
            className={styles.button}
            {...props}
          >
            <img src={AddCommentButton} alt={""}/>
            <div className={styles.child} style={{marginLeft: "1rem"}}> {children} </div>
          </div>
      </div>
    </div>
    );
};