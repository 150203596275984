import React, { useContext } from "react";
import { getImageURL, personFolder } from "../../../../../../../resources/api";
import styled from "styled-components";
import theme from "../../../../../../../resources/assets/theme";
import { ServersRank } from "../../../../../../../libs/redux";
import tw from "tailwind.macro";
import { colorTagGroup } from "../../../../../../../resources/styles/components/colorTagGroup";
import { I8nContext } from "../../../../../../../libs/i18n";
import {getColor , getColorBorder, getColorTags, getValueForBorder} from "resources/helpers/helper";
import {MainStructure, Container, WrapInfo, Line, WrapNum, WrapNumTag, WrapTime} from "../../../../../../../resources/styles/components/cards";
import {getToken} from "../../../../../../../utils/auth";
import AddCommentIcon from "resources/assets/add-comment-icon-blue.svg";
interface StyledProps {
  colors: {
    card: string;
    overall: string;
    drink: string;
    tags : string;
  };
}

const CardStyle = styled.div`
  ${tw`flex w-full border-solid rounded-lg border-2 sm:border-8 lg:border-4`}
  justify-content: center;
  align-items: center;

  border-color: ${(props: StyledProps) => props.colors.card};
  .overall {
    color: ${(props) => props.colors.overall};
  }
  .tags {
    color: ${(props) => props.colors.tags};
  }
  .drink {
    color: ${(props) => props.colors.drink};
    @media (max-width: 640px){
      padding: 0;
    }
  }
`;

const ServersCard: React.FC<ServersRank> = ({
  person,
  overall,
  tags,
  tagslist,
  checkTime,
  time,
  table,
  tableCardinal,
  avg,
  comments,
  onClick
}) => {
  const colors = {
    card: getColorBorder(getValueForBorder(avg, tags)),
    overall: getColor(overall),
    drink: theme.red,
    tags : getColorTags(tags)
  };

  const { translate } = useContext(I8nContext);
  const token = getToken()

  return (
    <MainStructure data-avg={avg}>
      {
          !!token && (
              <div className="relative">
                <div className="absolute top-0 right-0">
                  <div className="flex justify-center bg-white rounded-full w-8 h-8 z-0 cursor-pointer" onClick={onClick}>
                    <img src={AddCommentIcon} className="w-4 z-10"/>
                  </div>
                </div>
              </div>
          )
      }
      <CardStyle colors={colors}>
        <div className={"flex flex-col w-full"}>
          <div className={"flex"}>
            <Container>
              <Container width={"30%"}>
                <div className="flex justify-center" >
                  <img src={getImageURL(personFolder, person.img)} alt=""/>
                </div>
                <WrapInfo width={"40%"}>
                  <p>{person.name}</p>
                  <p className="font-sm">{time}</p>
                  <p className="bg_table"> {translate("TABLE")} {`${table}`} </p>
                  <p>{tableCardinal}</p>
                </WrapInfo>
              </Container>
              <WrapNum width={"15%"}>
                <Line />
                <p className="overall">{overall ? overall : "-"}</p>
                <Line />
              </WrapNum>
              <WrapNumTag width={"40%"}>
                <Line  width={'0%'}/>
                <div className="flex items-center w-11/12 py-2">
                  <p className="tags number w-2/12 text-center">{tags ? tags.toFixed(1).replace(/\.0+$/, "") : "-"}</p>
                  <div className="w-10/12">
                    {tagslist.map((item: any, index: number) =>{
                      if(item[1]) return (
                          <div className="flex flex-col items-center sm:flex-row sm:justify-center" key={index}>
                            <div className="tagTitle pr-0 sm:pr-2" data-value={item}> { translate(item[0])}</div>
                            <div className={`tagValue ${ colorTagGroup[item[1]]}`} > {translate(item[1])}</div>
                          </div>)}
                    )}
                  </div>
                </div>
                <Line />
              </WrapNumTag>
              <WrapTime width={"15%"}>
                <p className={`lobby ${colorTagGroup[checkTime]}`} > {checkTime === "unknown" ? "?" : translate(checkTime) } </p>
              </WrapTime>
            </Container>
          </div>
          {
              !!token && Array.isArray(comments) && comments.map(comment => (
                  <>
                    <div className="flex justify-center">
                      <hr className="w-full m-2 border-1" />
                    </div>

                    <span className="pl-4">Comment added by {`${comment.user.name} ${comment.user.last_name}`}</span>
                    <p className="pl-4">{comment.comment}</p>
                  </>
              ))
          }
        </div>
      </CardStyle>
    </MainStructure>
  );
};

export default ServersCard;
