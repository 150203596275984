
import {combineReducers} from 'redux';
// -------------------- REDUCERS -------------------- //
import OpencloseReducer from '../common/states/openclose/reducer';
import RankReducer from './states/rank/reducer';
import WinlosesReducer from './states/winloses/reducer';

const barReducer = combineReducers({
    openclose: OpencloseReducer,
    rank: RankReducer,
    winloses: WinlosesReducer
});


export default barReducer;