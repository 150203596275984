import React from "react";
import ReactDOM from "react-dom";
import "../src/resources/assets/main.css";
import App from "./app/App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "../src/libs/redux";
import { I8nProvider } from "./libs/i18n";
import Socket from "./Socket";
import AlertServiceProvider from "./app/etiquette/AlertProvider";

ReactDOM.render(
    <Provider store={store}>
        <I8nProvider>
            <AlertServiceProvider>
                <BrowserRouter>
                    <Socket />
                    <App />
                </BrowserRouter>
            </AlertServiceProvider>
        </I8nProvider>
    </Provider>,
  document.getElementById("root")
);
