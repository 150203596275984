import { API_URL } from 'resources/api';

const Fetcher = async (data) => {
    const URI = `dashboard/summary/monthly`;
    const response = await fetch(`${API_URL}${URI}`,{
        method : 'POST',
        headers : {
            'Content-Type' : 'application/json',
        },
        body : JSON.stringify(data)
    });

    return response.json();
}

export default Fetcher;
