const theme = {
    primary : '#001B35',
    secondary: '#001B35',
    lightbox: '#001B35',
    rating: 'transparent',
    orange: '#DB921D',
    red: '#CC3E4C',
    green: '#7BA950',
    strongRed: '#ffff',
    strongGreen: '#fff',
    gray: "#f2f2f2",
    serviceGood: "#077e15",
    serviceBad: "#c30e2e",
    white: "#ffff",
    black: "#000",
    serviceRegular: '#888888',
    table: '#012739',
}

export default theme;