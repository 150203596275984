import { useEffect } from "react";
import openSocket from "socket.io-client";
import { API_URL } from "./resources/api";

import useSync from './libs/redux/useSync'


const socket = openSocket(API_URL, {
  path: "/dashboard.socket",
  secure: true,
  jsonp: false,
  transports: ['websocket']
});

export const Socket = () => {

  const doSync = useSync()

  useEffect(() => {
    socket.on('dashboard', doSync)

    return () => socket.off('dashboard')
  }, []);

  return (<></>);
};



export default Socket;