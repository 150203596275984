import { useState } from 'react';
import moment from 'moment';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { Container, Week } from "../styles";
import {Layout} from '../containers/Layout';

export function getWeekDays(weekStart) {
  const days = [weekStart];
  for (let i = 1; i < 7; i += 1) {
    days.push(
      moment(weekStart)
        .add(i, 'days')
        .toDate()
    );
  }
  return days;
};

export function getWeekRange(date) {
  return {
      from: moment(date)
      .startOf('week')
      .toDate(),
      to: moment(date)
      .endOf('week')
      .toDate(),
  };
};

export  const Weekly = ({onCancel, setSelectedDays , selectedDays, onOK}) => {
    const [hoverRange, setHoverRange] = useState(undefined);

    const handleDayChange = (date ,  modifiers = {}) => {
      if (modifiers.disabled) {
        return;
      }
      setSelectedDays(getWeekDays(getWeekRange(date).from));
    }


    const handleWeekClick = (days, e,  modifiers = {}) => {
      if (modifiers.disabled) {
        return;
      }
      setSelectedDays(days);
    }
    

    const handleDayEnter = (date) => setHoverRange(getWeekRange(date));
    const handleDayLeave = (date) => setHoverRange(undefined);

    const daysAreSelected = selectedDays.length > 0;

    const modifiers = {
      hoverRange,
      selectedRange: daysAreSelected && {
        from: selectedDays[0],
        to: selectedDays[6],
      },
      hoverRangeStart: hoverRange && hoverRange.from,
      hoverRangeEnd: hoverRange && hoverRange.to,
      selectedRangeStart: daysAreSelected && selectedDays[0],
      selectedRangeEnd: daysAreSelected && selectedDays[6],
    };

    const date = `${moment(selectedDays[0]).format('MMM D')} – ${' '} ${moment(selectedDays[6]).format('MMM D')}`;
    const year = `${moment(selectedDays[0]).format('YYYY')}`;
    const weeks = `${moment(selectedDays[0]).format('MMM D, YY')} – ${' '} ${moment(selectedDays[6]).format('MMM D, YY')}`;

    const fullDate =[moment(selectedDays[0]).format('YYYY-MM-DD'), moment(selectedDays[6]).format('YYYY-MM-DD')];

    return (
  <Container>
    <Week>
      <Layout 
      year={year}
      date={date}
      onCancel={onCancel}
      onOK={() => (onOK(fullDate, weeks))}
      >
          <DayPicker
          selectedDays={selectedDays}
          modifiers={modifiers}
          onDayClick={handleDayChange}
          onDayMouseEnter={handleDayEnter}
          onDayMouseLeave={handleDayLeave}
          onWeekClick={handleWeekClick}
          disabledDays={[{after: new Date() }]}
          />
      </Layout>
    </Week>
    </Container>
    );
}
