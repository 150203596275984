import Types from './types';
import GlobalTypes from '../globalTypes';

export const globalDateChange = (data: string) => ({
  type: Types.DATE_STORE_CHANGE,
  payload: data,
});

export const globalDateDidChange = () => ({
  type: GlobalTypes.DATE_DID_CHANGE,
});
