import React from 'react';
import Modal from 'simple-react-modal';
import PropTypes from 'prop-types';
import styles from './dialog.module.css';
import { H3 } from '@etiquette-ui/typography';
import CloseSVG from '../assets/close-icon.svg';

const BaseDialog = ({ className, open, header, title, footer, onClose, children, isResponsive, showCloseButton = true }) => {
  return (
    <Modal show={open} className={`${styles.modalContainer} ${className ?? ''}`} containerClassName={isResponsive ? styles.modalInnerResponsive : styles.modalInner }>
      <img className={styles.closeIcon} src={CloseSVG} alt="close" onClick={onClose} />
      {header && (
        <div className={styles.headerContainer}>
          <H3>{header}</H3>
        </div>
      )}
      <div className={styles.content}>
        {title && <>{title}</>}
        <div className={styles.mainContent}>{children}</div>
        {footer && <div>{footer}</div>}
      </div>
    </Modal>
  );
};

export default BaseDialog;

BaseDialog.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  header: PropTypes.any,
  title: PropTypes.any,
  footer: PropTypes.any,
  onClose: PropTypes.func,
  isResponsive: PropTypes.bool,
};
