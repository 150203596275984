import { takeLatest, call, all, select } from 'redux-saga/effects';
import configSelector from '../config/selector';

// SERVERS
import Types from './types';
import GlobalTypes from '../globalTypes';

import { fetchResumeAsync } from './states/resume/saga';

function* fetchStartAsync() {
    const config = yield select(configSelector);
    yield call(fetchResumeAsync, config.date);
}

function* fetchStart() {
    yield takeLatest(Types.FETCH_START, fetchStartAsync);
    yield takeLatest(GlobalTypes.DATE_DID_CHANGE, fetchStartAsync);
}

export default function* sagas() {
    yield all([
        call(fetchStart),
    ]);
}