import { useState, useEffect, useContext } from "react";

import useDaily from "../logic/daily";
import useMonthly from "../logic/monthly";
import useYearly from "../logic/yearly";
import useWeekly from "../logic/weekly";
import { Weekly, Daily, Monthly, Yearly } from "app/components/calendar";
import { Dropdown } from "./dropdown";
import { formatDate } from "utils/date";
import { I8nContext } from "libs/i18n";

const today = new Date();
const initDate =  formatDate(today, "ddd, MMM D, YYYY");

const Calendars = ({ setLoading, setTitle, setData }) => {
  const getDaily = useDaily();
  const getMonthly = useMonthly();
  const getYearly = useYearly();
  const getWeekly = useWeekly();


  const { translate, selectedLang } = useContext(I8nContext);
  const [selectedDays, setSelectedDays] = useState([]);
  const [daySelected, setDaySelected] = useState(today);
  const [calendar, setCalendar] = useState(0);
  const [date, setDate] = useState(initDate);
  const [list, setList] = useState([]);

  useEffect(() => {
    setList([
        { id: 0, label: translate("Select") },
        { id: 1, label:  translate("Daily") },
        { id: 2, label:  translate("Weekly") },
        { id: 3, label:  translate("Monthly") },
        { id: 4, label:  translate("Yearly") },
      ]);
  }, [selectedLang])



  const onCancel = () => setCalendar(0);

  const handleDay = (date, day) => {
    prepareTable("SHIFTS");
    setDate(day);
    getDaily(date.format("YYYY-MM-DD")).then((d) => setData(d));
  };
  const handleWeek = (date, weeks) => {
    prepareTable("DAYS");
    setDate(weeks);
    const initDay = date[0];
    const endDay = date[1];

    getWeekly(initDay, endDay).then((d) => setData(d));
  };

  const handleMonth = (data, dates) => {

    console.log(data)
    prepareTable("MONTHS");
    setDate(dates);
    getMonthly(data)
      .then((d) => {
        setData(d);
      })
      .catch((e) => console.error(e));
  };

  const handleYear = (date, years) => {
    prepareTable("YEARS");
    setDate(years);
    getYearly(date).then((d) => setData(d));
  };

  const prepareTable = (title) => {
    setCalendar(0);
    setLoading(true);
    setTitle(title);
  };

  const showCalendar = () => {
    switch (calendar) {
      case 1:
        return (
          <Daily
            daySelected={daySelected}
            setDaySelected={setDaySelected}
            onCancel={onCancel}
            onOK={handleDay}
          />
        );
      case 2:
        return (
          <Weekly
            setSelectedDays={setSelectedDays}
            selectedDays={selectedDays}
            onCancel={onCancel}
            onOK={handleWeek}
          />
        );
      case 3:
        return (
          <Monthly
            year={formatDate(today, "YYYY")}
            onCancel={onCancel}
            onOK={handleMonth}
          />
        );
      case 4:
        return (
          <Yearly
            daySelected={daySelected}
            onCancel={onCancel}
            onOK={handleYear}
          />
        );
      default:
        <></>;
    }
  };

  return (
    <>
      <Dropdown
        options={list}
        onChange={(e) => setCalendar(+e.target.value)}
        initialValue={calendar}
        date={date}
      />
      {showCalendar()}
    </>
  );
};

export default Calendars;
