import TYPES from './types';
import { Reducer } from 'redux';
import { TicketsState, TicketsDispatchAction } from './state';

const initialState : TicketsState = {
    tickets: ''
}

const TicketReducer : Reducer<TicketsState, TicketsDispatchAction> = 
    (state = initialState, action) => {
        switch(action.type) {
            case TYPES.FETCH_START:
                return state
            case TYPES.FETCH_SUCCESS:
                return action.payload
            default:
                return state
        }
    }

export default TicketReducer;