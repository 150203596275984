import styled from "styled-components";
import { device, colors } from 'resources/Theme';

const MainStructure = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Avenir;
  color: #fff;
  padding: 0 20px;
  width: 100%;
  height: auto;
  margin: auto 0;
  @media ${device.tablet} {
    padding: 0 5px;
  }

  &.center {
    height: 80%;
    @media ${device.ipad} {
      height: auto;
    }
  }

  &.survey {
    height: auto;
    @media ${device.tablet} {
      height: 85%;
    }
    @media ${device.mobile} {
      height: 90%;
    }
    @media ${device.mini} {
      height: 75%;
    }
    .desktop {
      display: grid;
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: 1fr;
      row-gap: 3px;
      column-gap: 3px;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      @media ${device.tablet} {
        display: none;
      }

      .column{
        display: flex;
        flex-direction: column;
      }
    }
    .mobile {
      @media ${device.tablet} {
        height: 100%;
      }
    }
  }

  .desktop {
    display: flex;
    width: inherit;
    @media ${device.tablet} {
      display: none;
    }
  }

  .mobile {
    display: none;
    width: 100%;
    @media ${device.tablet} {
      display: flex;
    }
  }
`;
const RatingContainer = styled.div`
  width: 80%;
  @media ${device.tablet} {
    width: 100%;
    padding: 0 3px;
  }
  @media ${device.mobileL} {
    padding: 0;
  }
`;
const CardTitle = styled.div`
  display: flex;
  width: 100%;

  font-weight: 700;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  text-align: center;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e2e8f0;

  p{
    font-size: 1rem;
    margin: 0 5px;
    @media (max-width: 640px){
      margin: 0 0px;
      font-size: 0.7rem;
    }
  }
`;
const CardContentPreview = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e2e8f0;
  @media (min-width: 640px){
    height: 16rem;
  }
  @media (min-width: 1024px){
    height: 16vh;
  }
`;
const ContainerPreview = styled.div`
  display: flex;
  align-items:center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
`;
export {
  MainStructure,
  RatingContainer, 
  CardTitle,
  CardContentPreview,
  ContainerPreview
};























export const MainContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  row-gap: 10px;
  column-gap: 10px;
   @media ${device.ipad} {
    grid-template-columns: repeat(1, 1fr);
  }
`;
export const CardContent = styled.div`
  display: flex;
  height: 37vh;
  width: 55vw;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e2e8f0;
  @media (min-width: 640px){
      height: 44vh;
  }
  @media (min-width: 1024px){
    height: 60vh;
  }
  @media ${device.ipad} {
        width: 100%;
    }
`;
export const MusicContent = styled.div`
  display: flex;
  height: 37vh;
  width: 90vw;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e2e8f0;
  @media (min-width: 640px){
      height: 44vh;
  }
  @media (min-width: 1024px){
    height: 60vh;
  }
  @media ${device.ipad} {
        width: 95vw;
    }
`;
export const SideBar = styled.div`
  background:  rgb(3, 98, 151); 
  background: linear-gradient(
    180deg,
    rgba(3, 98, 151, 5) 0%,
    rgba(1, 39, 57, 1) 45%
  );
  border-radius: 10px;
  margin-left: 30px;
  width: 200px;

   @media ${device.ipad} {
    background:  transparent;
    width: 90vw;
    margin-left: 0px;
  }
`;

export const SideHeader = styled.p`
  padding: 10px 0;
  margin-bottom: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #001B35;
  font-family: 'Arial';
  font-weight: 600;
  text-shadow: 0.1em 0.1em #000;
  width: auto;
  text-transform: uppercase;
  text-align: center;

  @media (max-width: 640px){
    font-size: 1rem;
  }

   @media ${device.ipad} {
    background:  transparent;
    margin-bottom: 0;
    padding: 5px 0;
    font-size: 2.3rem;
  }
`;

export const SideContent = styled.div`
  display: flex;
  justify-content: space-around;

  @media (min-width: 1024px){
    height: 53vh;
  }

  img{
    background-color: #fff;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    margin: 10px 0;  
  }
`;

export const TableStructure = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Avenir;
  color: #fff;
  padding: 0 20px;
`;

export const TableContainer = styled.div`
  max-height: 100vh;
  -webkit-overflow-scrolling: touch;
  color: ${colors.white}
`;

export const TableContent = styled.div`
  display: flex;
  height: 37vh;
  width: 90vw;

  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e2e8f0;
`;


