import styled from 'styled-components';


export const GridContainer = styled.div<{ gridTemplate: string }>`
  display: grid;
  grid-template-columns: ${({ gridTemplate }) => gridTemplate};
  padding: 0 40px;
`;

export const LeftTitle = styled.p`
  font-weight: 700;
  font-size: 8px;
  margin-left: auto;
  margin-right: 20px;
  text-align: right;
`;

export const CellsGrid = styled.div<{ gridTemplate: string }>`
  display: grid;
  grid-template-columns: ${({ gridTemplate }) => gridTemplate};
  overflow: hidden;
  border-radius: 5px;
`;

const rowHeight = "43px";

export const CellContainer = styled.div<{ backgroundColor?: string }>`
  display: flex;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "#D1D1D1"};
  border-bottom: dashed 1px #707070;
  border-right: dashed 1px #707070;
  height: ${rowHeight};
  align-items: center;
  justify-content: center;
`;

export const CellValue = styled.p<{ color?: string }>`
  color: ${({ color }) => (color ? color : "black")};
  font-weight: 400;
  font-size: 18px;
`;