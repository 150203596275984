import Fetcher from './fetcher';
import Parser from './parser';
import { useContext } from 'react';
import { I8nContext } from 'libs/i18n';


const useWeekly = () => {
    const { selectedLang } = useContext(I8nContext);

    const lang = selectedLang ? "en" : "es";

    const getWeekly = async (dayInit, dayEnd) => {
        const rawData = await Fetcher(dayInit, dayEnd);
        return Parser(rawData, lang);
    }


    return getWeekly;
}


export default useWeekly;