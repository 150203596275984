import {useContext, useEffect} from "react";
import styled from "styled-components"
import {useAlert} from "../../../etiquette/AlertProvider";
import {I8nContext} from "../../../../libs/i18n";

const Window = styled.iframe`
  width: 100vw;
  height: 100vh;
  border: none;
`;

const Login = () => {
    const alert = useAlert();
    const { translate } = useContext(I8nContext);
    useEffect(() => (window.onload = onLoad), []);

    const onLoad = () => {
        const loginFrame = document.getElementById('loginframe')?.contentWindow;
        if (!!loginFrame) {
            loginFrame.postMessage(`getData:${process.env.APP_ID}`, process.env.REACT_APP_AUTH_URL);

            window.addEventListener(
                'message',
                (e) => {
                    if (typeof e.data !== 'string' || !e.data.startsWith('data:')) return;
                    const toStore = e.data.slice(5);
                    if (toStore !== '') {
                        const auth = toStore.split('|')[0];
                        const parsed = JSON.parse(auth);

                        if (1) {
                            localStorage.setItem('auth', auth);
                            window.location.href = '/';
                        } else {
                            alert({
                                type: 'INFO',
                                config: {
                                    title: translate('Unauthorized'),
                                    body: translate("You don't have access to this app"),
                                    buttonText: translate('OK'),
                                },
                            })
                                .then(() => {})
                                .catch(() => {});
                        }
                    }
                },
                false
            );
        }
    };

    return <Window id="loginframe" title="login" src={process.env.REACT_APP_AUTH_URL}></Window>;
};

export default Login;