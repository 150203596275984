import React from 'react'
import styles from './styles.module.css';

const Card = ({ children, className, style = {}, border, shadow, ...props }) => {
    const finalStyle = { ...style }

    if (border) {
        finalStyle.borderRadius = border;
    }

    switch (shadow) {
        case "light": {
            finalStyle.boxShadow = "0px 4px 12px #03326914"
        }
            break;
        case "strong": {
            finalStyle.boxShadow = "0px 8px 24px #0332693D"
        }
            break;

        default: {
            finalStyle.boxShadow = "0px 6px 18px #03326929"
        }
            break;
    }

    return (<div className={`${styles.card} ${className ?? ''}`} style={finalStyle} {...props} > {children}</div >)
}


export default Card
