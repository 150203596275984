import React, { useContext } from "react";
import { getImageURL, personFolder } from "../../../../../../../resources/api";
import { ManagersRank } from "../../../../../../../libs/redux";
import styled from "styled-components";
import tw from "tailwind.macro";
// import "../../../../../../resources/assets/styles/main.scss";
import { colorTagGroup } from "../../../../../../../resources/styles/components/colorTagGroup";
import NoSVG from "resources/assets/close.svg";
import YesSVG from "resources/assets/check.svg";
import {getColor, getColorBorder, getColorPercentPerfect, getPersonColor, getValueForBorder} from "resources/helpers/helper";
import { I8nContext } from "../../../../../../../libs/i18n";
import {MainStructure, ContainerPreview, WrapInfoPreview, Line, WrapNumPreview, WrapNumTagPreview} from "../../../../../../../resources/styles/components/cards";

interface StyledProps {
  colors: {
    cardColor: string;
    borderColor: string;
    overallColor: string;
    colorPerfect: string;
    tagsColor: string;
  };
}
const CardStyle = styled.div`
  ${tw`flex border-solid rounded border-2 sm:border-4 lg:border-4`}
  border-color: ${(props: StyledProps) => props.colors.borderColor};
  justify-content: center;
  align-items: center;
  width: 45vw;
  @media (max-width: 1024px){
    width: 90vw;
  }
  .overall {
    font-family: Avenir;
    color: ${(props) => props.colors.overallColor};
  }
  .tags {
    font-family: Avenir;
    color: ${(props) => props.colors.cardColor};
  }
  .perfect {
    font-family: Avenir;
    color: ${(props) => props.colors.colorPerfect};
    @media (max-width: 640px){
      padding: 0;
    }
  }
  p{
    margin: 0 3px;
  }
`;

const ManagerCard: React.FC<ManagersRank> = ({
  person,
  overall,
  tags,
  tagslist,
  perfect,
  time,
  table,
  avg,
}) => {


  const colors = {
    borderColor: getColorBorder(getValueForBorder(avg, tags)),
    cardColor: getColor(avg),
    overallColor: getColor(overall),
    colorPerfect: getColorPercentPerfect(perfect),
    tagsColor: getColor(tags),
  };
  const { translate } = useContext(I8nContext);

  return (
    <MainStructure>
      <CardStyle colors={colors}>
        <ContainerPreview width={"35%"}>
          <img src={getImageURL(personFolder, person.img)} alt="" />
          <WrapInfoPreview>
            <p style={{color : getPersonColor(person.id)}}>{person.name}</p>
            <p className="font-sm">{time}</p>
            <p className="bg_table"> {translate("TABLE")} {`${table}`} </p>
          </WrapInfoPreview>
        </ContainerPreview>
        <WrapNumPreview width={"10%"}>
          <Line /> 
          <p className="overall">{overall ? overall : "-"}</p>
          <Line />
        </WrapNumPreview> 
        <WrapNumPreview width={"25%"}>
          <Line width={'0'}/>
          <p className="perfect" >{perfect ? translate(perfect) : "-"} </p>
          <Line />
        </WrapNumPreview> 
        <WrapNumTagPreview width={"30%"}>
          <div className="comments wrapTag">
            {tagslist.map((item: any, index: number) => (
              item[1] !== '' && (
              <div className={"flex"} key={index}>
                  <div className={"Chef"}>
                    {item[0] === "Met Chef" ? ( <p>{translate("Met Chef")}</p>) : ( item[0]) }
                  </div>
                  <div className={`tagValue  ${colorTagGroup[item[1]]}`} >
                    {item[0] === "Met Chef" ? (
                      <img src={item[1] === "No" ? NoSVG : YesSVG} alt=""></img> ) : (item[1] )}
                  </div>
                </div>)
            ))}
          </div>
          </WrapNumTagPreview>   
        </CardStyle>
      </MainStructure>
  );
};

export default ManagerCard;
