import { useContext } from 'react';
import Fetcher from './fetcher';
import Parser from './parser';
import { I8nContext } from 'libs/i18n';


const useDaily = () => {
    const { translate } = useContext(I8nContext);

    const getDaily = async (day) => {
        const rawData = await Fetcher(day);
        return Parser(rawData, translate);
    }

    return getDaily;
}


export default useDaily;