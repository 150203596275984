import CalculateFields from "../common/CalculateFields";

const monthsTexts = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

const parser = (rawData, translate) => {
    const data = {
        title: [],
        activations: [],
        withData: [],
        withStar: []
    };


    for (const year in rawData) {
        if (Object.hasOwnProperty.call(rawData, year)) {
            const monthRow = rawData[year];
    
            for (const month in monthRow) {
                if (Object.hasOwnProperty.call(monthRow, month)) {
                    const element = monthRow[month];

                    const monthText = monthsTexts[+month - 1];
                    data.title.push(`${translate(monthText)} - ${year}`);
                    CalculateFields(element, data);
                }
            }
        }
    }

    return data;
}

export default parser;
