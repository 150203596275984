import { useContext, useState , useEffect } from "react";
import DasboardHeader from "../../../../providers/header";
import {ticketsSelector} from "libs/redux"
import TableGrid from "./components/TableGrid";
import TableHeader from "./components/TableHeader";
import { I8nContext } from "libs/i18n";
import {MainContainer, Container, CalendarContainer, Content } from './styles';
import {formatDate} from 'utils/date'
import {Loading} from 'app/components/loading';
import {Table} from './table';
import Calendars from "./components/Calendars";

import useDaily from "./logic/daily";

const SurveySuccess = () => {
  const { translate , selectedLang } = useContext(I8nContext);
  const getDaily = useDaily();

  const [title, setTitle] = useState("SHIFTS");
  const [data,setData] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(()=>{
    setLoading(false);
  },[data])


  useEffect(() => {
    loadInitialData()
  },[selectedLang])

  useEffect(() => {
    loadInitialData()
  }, []);

  const loadInitialData = () => {
    getDaily(formatDate(new Date(), "YYYY-MM-DD"))
    .then(d => setData(d))
    .finally(() => setLoading(false));
  }

return (
      <MainContainer>
          <DasboardHeader
            title={translate("SUMMARY")}
            withRating={false}
            selector={ticketsSelector}
          />
          <Container>
            <Content>
              <TableHeader 
                leading={3} 
                highlight={2} 
                cell={3} 
                title={"TOTAL"} 
                columns='repeat(5,1fr);'
              />
            </Content>
            <TableGrid leading={3} highlight={2} cell={3} />
            <CalendarContainer>
              <Calendars 
                setLoading={setLoading} 
                setTitle={setTitle} 
                setData={setData} />
            </CalendarContainer>
            { !!loading ? <Loading /> : <Table data={data} title={title}/> }
        </Container>
     </MainContainer>
  );
};

export default SurveySuccess;
