import { useContext } from 'react';
import { I8nContext } from "libs/i18n";
import 'react-day-picker/lib/style.css';
import {
    Header,
    Footer
} from "../styles";

export const Layout = ({onCancel, children, onOK, year, date}) => {
    const { translate } = useContext(I8nContext);
    return (
        <>
        <Header>
            <div>
                <span>{year}</span>
                <h1>{date}</h1>
            </div>
        </Header>
        {children}
        <Footer>
            <div>
                <a onClick={onCancel}>{translate("Cancel")}</a>
                <a onClick={onOK}>{translate("Ok")}</a>
            </div>
        </Footer>
        </>
    );
}