import CalculateFields from "../common/CalculateFields";

import { translateDate } from "utils/date";

const parser = (rawData, lang) => {
    const data = {
        title: [],
        activations: [],
        withData: [],
        withStar: []
    };

    for (const key in rawData) {
        if (Object.hasOwnProperty.call(rawData, key)) {
            const element = rawData[key];

            data.title.push(translateDate(key , lang , "ddd D"));
            CalculateFields(element, data);
        }
    }

    return data;
}

export default parser;
