import React, { useContext, useState } from "react";
import { PrimaryButton } from "@etiquette-ui/buttons";
import BaseDialog from "./BaseDialog";
import styled from "styled-components";
import { Input } from "../../etiquette/Input";
import DynamicTable from "../../etiquette/DynamicTable";
import { colors } from "../../../resources/Theme";
import { DateInput } from "../../etiquette/DateInput";
import { getToken } from "../../../utils/auth";
import { API_URL } from "../../../resources/api";
import { I8nContext } from "../../../libs/i18n";
import { format } from "date-fns";

const Table = styled(DynamicTable)`
    height: 100%;
    background-color: transparent;
    background: transparent;
`;

const ContentContainer = styled.div`
    flex: 1;
    margin-top: 2rem;
    margin-bottom: 1rem;
    max-height: 40vh;
    overflow: auto;
`;

const RowContainer = styled.div`
    display: grid;
    grid-template-columns: ${({ columns }) => columns};
    column-gap: 5px;
    overflow-y: visible;
    padding: 5px 0;
    min-height: 45px;
    border-radius: 8px;
    background-color: ${({ count }) =>
        count % 2 === 0 ? `${colors.backgroundLightGrey}` : "transparent"};
`;

const getStructure = ({ handleDetails, translate }) => {
    return {
        row: RowContainer,
        columns: [
            {
                flex: 2,
                header: () => (
                    <span className="ml-5 mt-3 items-start leading-3 font-bold text-xs sm:text-sm">
                        #Ticket
                    </span>
                ),
                body: ({ item }) => (
                    <span className="ml-5 mt-3 items-start leading-3 text-xs sm:text-sm">
                        {item.ticket_id}
                    </span>
                ),
            },
            {
                flex: 2,
                header: () => (
                    <span className="ml-5 mt-3 items-start leading-3 font-bold text-xs sm:text-sm">
                        Survey ID
                    </span>
                ),
                body: ({ item }) => (
                    <span className="ml-5 mt-3 items-start leading-3 text-xs sm:text-sm">
                        {item.survey_id || "No Survey"}
                    </span>
                ),
            },
            {
                flex: 2,
                header: () => (
                    <span className="ml-5 mt-3 items-start leading-3 font-bold text-xs sm:text-sm">
                        Activation Time
                    </span>
                ),
                body: ({ item }) => (
                    <span className="ml-5 mt-3 items-start leading-3 text-xs sm:text-sm">
                        {item.activation_time || "N/A"}
                    </span>
                ),
            },
            {
                flex: 1,
                header: () => <div></div>,
                body: ({ item }) => (
                    <span
                        className="ml-5 mt-3 items-start leading-3 font-bold text-blue-400 cursor-pointer text-xs sm:text-sm"
                        onClick={() => {
                            handleDetails(item.survey_id, item.ticket_id);
                        }}
                    >
                        {translate("View Details")}
                    </span>
                ),
            },
        ],
    };
};

const InfoDialog = ({ onClose, openDetails }) => {
    const timeFormat = "yyyy-MM-dd";
    const [tickets, setTickets] = useState([]);
    const [tableNumber, setTableNumber] = useState(null);
    const [date, setDate] = useState(new Date());
    const [disable, setDisable] = useState(true);
    const { translate } = useContext(I8nContext);

    const fetchTickets = async () => {
        try {
            const response = await fetch(
                `${API_URL}dashboard/tickets/by_table/${tableNumber}?date=${format(date, timeFormat)}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: getToken(),
                    },
                }
            );
            if (response.status === 401) {
                alert(response.statusText);
                return;
            }
            const tickets = await response.json();
            if (!tickets.length) {
                alert(translate("Tickets no encontrados para la mesa seleccionada"));
                return;
            }
            setTickets(tickets);
        } catch (e) {
            alert(translate("server error"));
        }
    };

    const fetchTicketDetails = async (surveyId) => {
        try {
            const response = await fetch(`${API_URL}dashboard/tickets/${surveyId}`, {
                method: "GET",
                headers: {
                    Authorization: getToken(),
                },
            });
            return await response.json();
        } catch (e) {
            alert("error en el servidor");
        }
    };

    const fetchTicketDetailsByTicketId = async (ticketId) => {
        try {
            const response = await fetch(`${API_URL}dashboard/tickets/by_ticketId/${format(date, timeFormat)}/${ticketId}`, {
                method: "GET",
                headers: {
                    Authorization: getToken(),
                },
            });
            return await response.json();
        } catch (e) {
            alert("error en el servidor");
        }
    };

    const handleDetails = async (surveyId, ticketId) => {
        if (!!surveyId) {
            const ticketData = await fetchTicketDetails(surveyId);
            openDetails(ticketData);
            return;
        }
        const ticketData = await fetchTicketDetailsByTicketId(ticketId);
        openDetails(ticketData);
    };

    return (
        <BaseDialog
            open={true}
            isResponsive={true}
            showCloseButton={true}
            onClose={onClose}
            header={translate("Table Comment")}
            title={
                <>
                    <p>{translate("Do you want to report a problem with a table?")}</p>
                    <p className="font-bold">{translate("Insert the table number")}</p>
                </>
            }
        >
                <div className="flex mt-10">
                    <div className="w-1/2 mr-2">
                        <Input
                            inputProps={{
                                disabled: false,
                                onChange: (e) => {
                                    setTableNumber(e.target.value);
                                    setDisable(!e.target.value);
                                },
                                required: true,
                                value: tableNumber,
                            }}
                            labelProps={{
                                label: translate("Table Number"),
                            }}
                        />
                    </div>
                    <div className="w-1/2 ml-2">
                        <DateInput
                            inputProps={{
                                required: true,
                            }}
                            labelProps={{
                                label: translate("Date"),
                            }}
                            language="en"
                            setValue={(value) => {
                                setDate(value);
                            }}
                            value={date}
                        />
                    </div>
                </div>
                <ContentContainer>
                    {tickets.length > 0 && (
                        <Table
                            structure={getStructure({ handleDetails, translate })}
                            items={tickets}
                        />
                    )}
                </ContentContainer>

                <PrimaryButton disabled={disable} onClick={fetchTickets}>
                    {translate("Continue")}
                </PrimaryButton>
        </BaseDialog>
    );
};

export default InfoDialog;
