import { put } from 'redux-saga/effects';

import {
    fetchWinlosesSuccess
} from './actions';

import { winLosesURL } from '../../api';
import { ONNA_CATEGORIES } from 'resources/constants/config';

export function* fetchWinlosesAsync(date) {
    try {
        const response = yield fetch(`${winLosesURL}/${date}`, {
            method: "GET",
            headers: {
                category: ONNA_CATEGORIES
            }
        })
        const json = yield response.json()
        yield put(fetchWinlosesSuccess(json))
    } catch (error) {
        console.log(error.message)
    }
}