import React, { useContext } from "react";
import styled from "styled-components";
import theme from "../../../resources/assets/theme";
import tw from "tailwind.macro";
import { CardInfo, goodServices, badServices } from "./card.model";
import NoSVG from "../../../resources/assets/close.svg";
import YesSVG from "../../../resources/assets/check.svg";
import { TableWrap, TableColumn, TableCenter, TableColumnItem, ItemRating_Preview, ItemName_Preview, TableSpan, TableService_Preview, TableMetChef } from "../../../resources/styles/components/tables";
import { I8nContext } from "../../../libs/i18n";
import { TableCardinal, Service } from "../../../libs/i18n/switches";

interface withTargetColor {
  bgColor: string;
  textColor: string;
}
const CardStyled = styled.div`
  ${tw`flex w-full text-black  py-1`}
  font-family: Avenir;
  .score-red {
    color: ${theme.strongRed};
  }
  .score-green {
    color: ${theme.strongGreen};
  }
  .target {
    background-color: ${(props: withTargetColor) => props.bgColor};
    color: ${(props: withTargetColor) => props.textColor};
  }
  p{
    margin: 0 3px;
  }
`;

const Card: React.FC<CardInfo> = ({ wins, loses, details }) => {
  const getTargetColor: (
    service: string
  ) => { bgColor: string; textColor: string } = (service) => {
    if (goodServices.find((e) => e === service))
      return { bgColor: theme.serviceGood, textColor: "white" };
    else if (badServices.find((e) => e === service))
      return { bgColor: theme.serviceBad, textColor: "white" };
    else return { bgColor: theme.gray, textColor: "black" };
  };
  const color = getTargetColor(details.service);
  const { translate } = useContext(I8nContext);

  return (
    <CardStyled bgColor={color.bgColor} textColor={color.textColor}>
      <TableWrap>   
        <TableColumn>
          <TableColumnItem>
            {wins.length > 0 &&  wins.map((item, index: number) => (
              <div key={index} className="p-1 wins-list">
                <ItemRating_Preview className="score-green">{item.rating}</ItemRating_Preview>
                <ItemName_Preview className="name">{item.name}</ItemName_Preview>
              </div>
              ))}
          </TableColumnItem>
          </TableColumn>

        <TableCenter>
          <TableSpan>
            <p>{translate("TABLE")} - {details.table}</p>
            <p><TableCardinal tableCardinal={details.tableCardinal} /> </p>
          </TableSpan>
          <p>{details.time}</p>
          <ItemName_Preview>{details.name}</ItemName_Preview>
          <TableService_Preview className="target">
          <p><Service service={details.service}/></p>
          </TableService_Preview>
          <TableMetChef>
            <img  src={details.chefmet ? YesSVG : NoSVG} alt='' />
            <p>{translate("Met Chef")}</p>
          </TableMetChef>
          </TableCenter>

        <TableColumn>
          <TableColumnItem>
            {loses.length > 0 && loses.map((item, index: number) => (
              <div key={index} className="p-1 loses-list">
                <ItemRating_Preview className="score-red">{item.rating}</ItemRating_Preview>
                <ItemName_Preview>{item.name}</ItemName_Preview>
              </div>
              ))}
          </TableColumnItem>
        </TableColumn>
      </TableWrap>
    </CardStyled>
  );
};

export default Card;
