import WinlosesTypes from './types';
import { FoodWinlosesState, FoodWinlosesDispatchAction } from './state';
import { Reducer } from 'redux';

const initialState: FoodWinlosesState = {
    wins: '',
    loses: '',
    avg: '',
    touch : 0,
    perfect : 0,
    chefmet: 0,
}

export const WinlosesReducer : Reducer<FoodWinlosesState, FoodWinlosesDispatchAction>= 
    (state = initialState, action) => {
        switch(action.type) {
            case WinlosesTypes.FETCH_START:
                return state
            case WinlosesTypes.FETCH_SUCCESS:
                return action.payload
            default:
                return state
        }
    }

export default WinlosesReducer;