import React from 'react';

import DasboardHeaderContainer from '../views/sections/components/Header/container';

interface ProviderProps {
    selector: any,
    title: string,
    withRating?: boolean,
}
const DasboardHeaderProvider: React.FC<ProviderProps> = ({
    title, 
    selector, 
    withRating=true,
    }) => {

    const childProps = {
        title,
        selector,
        withRating,
    }

    return (<DasboardHeaderContainer {...childProps} />);
}

export default DasboardHeaderProvider;