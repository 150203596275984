import styled from "styled-components";
import { device, colors } from 'resources/Theme';

export const Grid = styled.div`
  display: grid;
  width: 100%;
  height: auto;
  grid-template-columns: repeat(2, min(250px, 40%));
  grid-template-rows: auto;
  grid-template-areas: "minutes minutes"
                       "active completed";
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  row-gap: 10px;
  margin: 15px 0;
  .minutes{
    grid-area: minutes;
  }
  .active{
    grid-area: active;
  }
  .completed{
    grid-area: completed;
  }

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 45%);
    height: 80%;
    margin:  0;
  }
  @media ${device.mobile} {
    column-gap: 5px;
  }
  @media ${device.mini} {
    height: 70%;
    column-gap: 3px;
    row-gap: 4px;
  }
`;
export const Container = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  color: ${({ color }) => (color ? color : colors.black)};
  padding: 10px 0;
  @media ${device.mobileS} {
      padding: 5px 0;
  }

  h1{
      font-size: 20px;
      @media ${device.mini} {
        font-size: 10px;
      }
  }

  p{
      text-transform: uppercase;
      letter-spacing: 0.05em;
      font-size: 11px;
      line-height: 0.8rem;

      @media ${device.mobile} {
        font-size: min(10px, calc(11px - 0.3vw));
        padding: 0 2px;
      }
      @media ${device.mobileL} {
          font-size: calc(11px - 1vw);
      }
      @media ${device.mobileM} {
          font-size: calc(10px - 1.2vw);
      }
      @media ${device.mini} {
        font-size: 5px;
        line-height: 0.3rem;
    }
  }
`;