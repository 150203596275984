import ResumeTypes from './types';
import { MusicResumeState, MusicResumeDispatchAction } from './state';
import { Reducer } from 'redux';

const initialState: MusicResumeState = {
    timeAvg: '',
    activationsPercent: 0,
    completePercent: 0,
    notAceptableCount: 0,
    totalBillsCount: 0,
}

export const WinlosesReducer : Reducer<MusicResumeState, MusicResumeDispatchAction>= 
    (state = initialState, action) => {
        switch(action.type) {
            case ResumeTypes.FETCH_START:
                return state
            case ResumeTypes.FETCH_SUCCESS:
                return action.payload
            default:
                return state
        }
    }

export default WinlosesReducer;