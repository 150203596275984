import {put} from 'redux-saga/effects';
import {openCloseURL} from '../../api';
import {
    fetchOpencloseSuccess,
    fetchOpencloseStart,
    fetchOpencloseFailure,
} from './actions';
import moment from "moment";
import {getCurrentDayOfTheWeek} from "../../../../../utils/date";

export function* fetchOpencloseAsync () {
    try {
        yield put(fetchOpencloseStart())
        const response = yield fetch(openCloseURL, {
            method: "GET"
        })
        const json = yield response.json()
        const config = JSON.parse(json.config)
        const currentDayOfTheWeek = getCurrentDayOfTheWeek()
        //TODO: define these values in case we don't have a schedule defined by the API
        const defaultStart = "13:00"
        const defaultEnd = "23:00"
        const openCloseObject = {
            start: config?.openingHours?.schedule?.[currentDayOfTheWeek]?.hours?.[0] || defaultStart,
            end: config?.openingHours?.schedule?.[currentDayOfTheWeek]?.hours?.[2] || defaultEnd,
        }

        yield put(fetchOpencloseSuccess(openCloseObject))
    } catch (error) {
        console.log(error.message)
        yield put(fetchOpencloseFailure())
    }
}