import React from "react";

import DasboardHeader from "./component";
import { useHistory } from "react-router-dom";

interface HeaderProps {
  selector: any;
  title: string;
  withRating: boolean;
}
const DasboardHeaderContainer: React.FC<HeaderProps> = ({
  title,
  selector,
  withRating,
}) => {

  const history = useHistory();
  const handleRoute = () => history.push("/");

  const childProps = {
    title,
    handler: handleRoute,
    selector,
    withRating,
  };

  return (<DasboardHeader {...childProps} />);
};

export default DasboardHeaderContainer;
