import React, { useState } from 'react';
import { addValueToObject } from './utils/objectUtils.js';
import styles from './style.module.css';
import { useTableContext } from './providers/TableProvider.jsx';
import { useFilters } from './providers/Filters.jsx';

const Header = ({className}) => {
    const { structure, gridColumns, dragable, globalState, setGlobalState, setGlobalStateBulk } = useTableContext();
    const { filters, applyFilter, resetFilters } = useFilters();

    const [state, replaceState] = useState({});

    const setState = (key, value) => {
        const data = { ...state };
        addValueToObject(data, key, value);
        replaceState(data);
    };

    const setStateBulk = (items) => {
        const data = { ...state };
        for (const key in items) {
            addValueToObject(data, key, items[key]);
        }
        replaceState(data);
    };

    return (
        <div className={`${styles.header} ${className}`} style={{gridTemplateColumns: gridColumns}}>
            {dragable && <div></div>}
            {structure.columns.map((col, i) => {
                const Component = col.header;
                return (
                    <Component
                        key={`header_${i}`}
                        applyFilter={applyFilter}
                        resetFilters={resetFilters}
                        filters={filters}
                        state={state}
                        setState={setState}
                        setStateBulk={setStateBulk}
                        globalState={globalState}
                        setGlobalState={setGlobalState}
                        setGlobalStateBulk={setGlobalStateBulk}
                    />
                );
            })}
        </div>
    );
};

export default Header;
export {Header};
