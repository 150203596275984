import React, { useContext } from "react";
import Header from "../../../homepage/components/SectionHeader";
import { onnaRankSelector, onnaWinlosesSelector } from "../../../../../libs/redux";
import Provider from "app/providers/rating";
import Rating from "app/components/rating/component";
import MobileView from "./mobile";
import CardPreview from "../../../../containers/info-card/card.preview";
import withSelector from "../../../../containers/info-card/card.provider";
import { useHistory } from "react-router-dom";
import { I8nContext } from "../../../../../libs/i18n";
import {
  MainStructure, 
  ContainerPreview, 
  CardTitle, 
  CardContentPreview, 
  RatingContainer 
} from "app/views/sections/components/layout";

const OnnaPreview: React.FC = () => {

  const history = useHistory();
  const handleRoute = () => history.push("/onna");
  const { translate } = useContext(I8nContext);

  return (
    <div onClick={handleRoute} className="box">
      <Header title="ONNA" />

      <MainStructure>
          <div className="mobile">
            <Provider selector={onnaWinlosesSelector} Component={MobileView} />
          </div>

          <RatingContainer>
            <Provider selector={onnaWinlosesSelector} Component={Rating} preview={true}/>
          </RatingContainer>

          <div className="desktop">
            <ContainerPreview>
              <CardTitle>
                <p className="w-1/3"> {translate("WINS")} </p>
                <p className="w-1/3">{translate("DETAILS")}</p>
                <p className="w-1/3"> {translate("LOSES")}</p>
              </CardTitle>
              <CardContentPreview> 
                {withSelector(CardPreview)(onnaRankSelector)} 
              </CardContentPreview>
            </ContainerPreview>
          </div>
        </MainStructure>
      </div>
  );
};

export default OnnaPreview;
