import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { CardInfo } from './card.model';
import CardContainer from './card.container';

const withSelector = (WrappedComponent : React.FC<CardInfo>) => (selector: any) => {
    const WithSelectorHOC = () => {
        const items : [CardInfo] = useSelector(selector, shallowEqual);
        const props = {
            items,
            Component: WrappedComponent
        }
        return(<CardContainer {...props}/>)
    }
    return WithSelectorHOC();
}

export default withSelector;