import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import ScoreContainer from './score.container';

interface Score{
  id: number,
  person: {
    name: string,
    img: string
  },
  week: number,
  today: number
}

interface ScoreProps {
  selector: any
}
const ScoreProvider : React.FC<ScoreProps> = ({selector}) => {

    const items:  Score[] = useSelector(selector, shallowEqual)

    return(<ScoreContainer items={items}/>)
};

export default ScoreProvider;