import { put } from "redux-saga/effects";
import { ticketsURL } from "../../api";

import { fetchSummarySuccess } from "./actions";

export function* fetchAsync(date) {
  try {
    const response = yield fetch(`${ticketsURL}/${date}`, {
      method: "GET",
    });
    const json = yield response.json();
    yield put(fetchSummarySuccess(json));
  } catch (error) {
    console.log(error.message);
  }
}
