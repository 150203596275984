import React, { useContext } from "react";
import Header from "../../../homepage/components/SectionHeader";
import { serversWinlosesSelector } from "../../../../../libs/redux";
import Provider from "../../../../providers/rating";
import Rating from "../../../../components/rating/component";
import MobileView from "../../../homepage/components/MobileCards";
import ListCard from "./components/servers-card/servers-list.container";
import CardPreviewComponent from "./components/servers-card/servers-card.preview";
import { useHistory } from "react-router-dom";
import { I8nContext } from "../../../../../libs/i18n";
import {
  MainStructure, 
  ContainerPreview, 
  CardTitle, 
  CardContentPreview, 
  RatingContainer 
} from "app/views/sections/components/layout";

const ServersPreview: React.FC = () => {

  const history = useHistory();
  const handleRoute = () => history.push("/servers");
  const { translate } = useContext(I8nContext);

  return (
    <div onClick={handleRoute} className='box'>
      <Header title={translate("SERVICE")} />

      <MainStructure>
          <div className="mobile">
            <Provider selector={serversWinlosesSelector} Component={MobileView}/>
          </div>

          <RatingContainer>
            <Provider selector={serversWinlosesSelector} Component={Rating} preview={true}/>
          </RatingContainer>

          <div className="desktop">
            <ContainerPreview>
              <CardTitle>
                <p style={{width: "30%"}}>{translate("SERVICE")}</p>
                <p style={{width: "15%"}}>{translate("OVERALL")}</p>
                <p style={{width: "40%"}}>{translate("TAGS")}</p>
                <p style={{width: "15%"}}>{translate("CHECK")}</p>
              </CardTitle>
              <CardContentPreview> 
                <ListCard Component={CardPreviewComponent} />  
              </CardContentPreview>
            </ContainerPreview>
          </div>
        </MainStructure>
      </div>
  );
};

export default ServersPreview;
