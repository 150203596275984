import React, { useContext } from "react";
import Header from "../../../homepage/components/SectionHeader";
import { managersWinlosesSelector} from "../../../../../libs/redux";
import Provider from "app/providers/rating";
import Rating from "app/components/rating/component";
import MobileView from "./mobile";
import ListCard from "./components/manager-card/managers-list.container";
import ManagerCard from "./components/manager-card/manager-card.preview";
import { useHistory } from "react-router-dom";
import { I8nContext } from "../../../../../libs/i18n";
import {
  MainStructure, 
  ContainerPreview, 
  CardTitle, 
  CardContentPreview, 
  RatingContainer 
} from "app/views/sections/components/layout";

const ManagerPreview: React.FC = () => {

  const history = useHistory();
  const handleRoute = () => history.push("/managers");
  const { translate } = useContext(I8nContext);

  return (
    <div onClick={handleRoute} className='box'>
      <Header title={translate("MANAGERS")} />
      
      <MainStructure className="center">
          <div className="mobile">
            <Provider selector={managersWinlosesSelector} Component={MobileView}/>
          </div>

          <RatingContainer>
            <Provider selector={managersWinlosesSelector} Component={Rating} preview={true}/>
          </RatingContainer>

          <div className="desktop">
            <ContainerPreview>
              <CardTitle>
                <p style={{width: "35%"}}>{translate("MANAGER")}</p>
                <p style={{width: "10%"}}>{translate("OVERALL")}</p>
                <p style={{width: "35%"}}>{translate("PERFECT")}</p>
                <p style={{width: "30%"}}>{translate("TAGS")}</p>
              </CardTitle>
              <CardContentPreview> 
                <ListCard Component={ManagerCard} /> 
              </CardContentPreview>
            </ContainerPreview>
          </div>
        </MainStructure>
    </div>
  );
};

export default ManagerPreview;
