import { Action, Dispatch } from 'redux';
import Types from './types';

export class ConfigDispatcher { 
    private readonly dispatch: Dispatch<Action>;

    constructor(dispatch: Dispatch<Action>) {
        this.dispatch = dispatch;
    }

    dateChangeApp = (date: string) => this.dispatch({type: Types.DATE_CHANGE, payload: date})
}
