import React, { useContext } from "react";
import ListCard from "./components/manager-card/managers-list.container";
import Score from "../../../../containers/score/score.provider";
import DasboardHeader from "../../../../providers/header";
import {managersWinlosesSelector, managersResumeSelector} from "../../../../../libs/redux";
import ManagerCard from "./components/manager-card/manager-card.component";
import {MainStructure, MainContainer, CardTitle, CardContent, SideBar, SideHeader, SideContent } from "../../components/layout";
import { I8nContext } from "../../../../../libs/i18n";

const ManagersDashboard: React.FC = () => {
  const { translate } = useContext(I8nContext);

  return (
    <MainStructure>
      <DasboardHeader title={translate("MANAGER")} selector={managersWinlosesSelector} />
      <MainContainer>
          <div>
            <CardTitle>
              <p style={{width: "30%"}}>{translate("MANAGER")}</p>
              <p style={{width: "17%"}}>{translate("OVERALL")}</p>
              <p style={{width: "17%"}}>{translate("PERFECT")}</p>
              <p style={{width: "40%"}}>{translate("TAGS")}</p>
            </CardTitle>
            <CardContent> <ListCard Component={ManagerCard} /> </CardContent>
          </div>
          <SideBar>
            <SideHeader> {translate("SIDE_BAR")} </ SideHeader>
            <SideContent> <Score selector={managersResumeSelector} /> </SideContent>
          </SideBar>
        </MainContainer>
      </MainStructure>
  );
};

export default ManagersDashboard;
