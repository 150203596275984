import React, { useContext } from "react";
import { I8nContext } from ".";

export const Week = ({ dow }) => {
  const { translate } = useContext(I8nContext);
  return (
    <>
      {(() => {
        switch (dow) {
          case "Monday":
            return translate("Monday");
          case "Tuesday":
            return translate("Tuesday");
          case "Wednesday":
            return translate("Wednesday");
          case "Thursday":
            return translate("Thursday");
          case "Friday":
            return translate("Friday");
          case "Saturday":
            return translate("Saturday");
          case "Sunday":
            return translate("Sunday");
          default:
            return "-";
        }
      })()}
    </>
  );
};

export const TableCardinal = ({ tableCardinal }) => {
  const { translate } = useContext(I8nContext);
  return (
    <>
      {(() => {
        switch (tableCardinal) {
          case "East":
            return translate("East");
          case "West":
            return translate("West");
          case "North":
            return translate("North");
          default:
            return translate("South");
        }
      })()}
    </>
  );
};

export const Service = ({ service }) => {
  const { translate } = useContext(I8nContext);
  return (
    <>
      {(() => {
        switch (service) {
          case "Perfectly":
            return translate("Perfectly");
          case "Fast Flowing":
            return translate("Fast Flowing");
          case "Always Delayed":
            return translate("Always Delayed");
          case "Adequate":
            return translate("Adequate");
          case "Slow":
            return translate("Slow");
          case "No Opinion":
          case "No opinionn":
            return translate("No_Opinion");
          default:
            return "-";
        }
      })()}
    </>
  );
};

export const Month = ({ mon }) => {
  const { translate } = useContext(I8nContext);
  return (
    <>
      {(() => {
        switch (mon) {
          case "Jan":
            return translate("Jan");
          case "Feb":
            return translate("Feb");
          case "Mar":
            return translate("Mar");
          case "Apr":
            return translate("Apr");
          case "May":
            return translate("May");
          case "Jun":
            return translate("Jun");
          case "Jul":
            return translate("Jul");
          case "Aug":
            return translate("Aug");
          case "Sep":
            return translate("Sep");
          case "Oct":
            return translate("Oct");
          case "Nov":
            return translate("Nov");
          default:
            return translate("Dec");
        }
      })()}
    </>
  );
};
