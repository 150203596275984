import moment from 'moment'
import 'moment/locale/es';
const DATE_FORMAT = 'ddd MMM D, YYYY';


export const getToday = (locale = "en", format = DATE_FORMAT) => {
    moment.locale(locale);
    const today = moment().format(format)

    return capitalize(today)
}

export const translateDate = (date, locale = "en", format = DATE_FORMAT) => {
    moment.locale(locale);
    const formattedDate = moment(date).format(format)

    return capitalize(formattedDate)
}

export const formatDate = (date, format = DATE_FORMAT) => moment(date).format(format);

const capitalize = string => string.split(" ").map(item => item.charAt(0).toLocaleUpperCase() + item.slice(1)).join(' ')

export const getCurrentTime = () =>  moment().format(DATE_FORMAT)

export const isOpen = (start, end) => {
    const startMoment = moment(start, 'HH:mm');
    const endMoment = moment(end, 'HH:mm').add(1,'days');
    const now = moment();

    return now.isAfter(startMoment) && now.isBefore(endMoment);
}

export const getSecondsToClose = (end, isTomorrow = false) => {
    const endMoment = isTomorrow ?  moment(end, 'HH:mm').add(1,'days') :  moment(end, 'HH:mm');
    const now = moment();

    return endMoment.diff(now, 'seconds');
}

export const substractYear = (year, amount = 1) => moment(year).subtract(amount, "y");

export const addYear = (year, amount = 1 ) => moment(year).add(amount, "y");

export const getCurrentDayOfTheWeek = () => {
    const daysOfTheWeek = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
    ]
    return daysOfTheWeek[moment().isoWeekday()]
}