import RankTypes from './types';
import { Reducer } from 'redux';
import { ManagersRankState, ManagersRankDispatchAction } from './state';

const initialState : ManagersRankState = {
    rank: ''
}

const RankReducer : Reducer<ManagersRankState, ManagersRankDispatchAction> = 
    (state = initialState, action) => {
        switch(action.type) {
            case RankTypes.FETCH_START:
                return state
            case RankTypes.FETCH_SUCCESS:
                return action.payload
            default:
                return state
        }
    }

export default RankReducer;