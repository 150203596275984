import React, { useMemo } from 'react';
import { CardInfo } from './card.model';
import styled, { Keyframes, keyframes } from 'styled-components';
import { getRandom } from '../../../resources/api';
import {fadeIn} from 'react-animations';

const fadeinAnimation = keyframes`${fadeIn}`;
interface StyledProps {
    animation : Keyframes
  }
const Animation = styled.div`
    animation: 1s ${(props : StyledProps) => props.animation};
`;

interface ContainerProps {
    items: CardInfo[],
    Component: any
}
const CardContainer : React.FC<ContainerProps>= ({items, Component}) => {

    return useMemo(()=> {
        return ( 
          <div className="flex w-full flex-col overflow-y-auto scrolleable">
            <Animation key={getRandom()} animation = {fadeinAnimation}>
              {
                Array.isArray(items) && items.map((item: CardInfo) =><Component key={item.id} {...item} />)
              }
            </Animation>
          </div>
        );
      },[JSON.stringify(items)])
}

export default CardContainer;