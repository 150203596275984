import React from 'react';
import PropTypes from 'prop-types';
import styles from './text-area.module.css';
import Icon from '@etiquette-ui/icons';
import { PRIMARY, INPUTS, WRONG, TEXT } from '@etiquette-ui/colors';

const regular = {
    '--bg': INPUTS,
    '--wrong': WRONG,
    '--right': PRIMARY,
    '--text': TEXT,
};

const TextArea = ({ className, iconProps = {}, containerProps = {}, inputProps = {}, labelProps = {}, errorProps = {} }) => {
    const { icon = '', iconStyles = {} } = iconProps;

    const defaultInputProps = { type: 'text' };
    const { value, disabled: inputDisabled = false, ...restInputProps } = inputProps;
    const mergedInputProps = { ...defaultInputProps, ...restInputProps };

    const { label = '', ...restLabelProps } = labelProps;
    const { error, ...restErrorProps } = errorProps;

    const validValue = (!!value || value === 0 || value === '0') && !!label;

    return (
        <div style={regular}>
            <div className={`${styles.container} ${className}`} {...containerProps}>
                {icon && (
                    <div className={styles.iconContainer}>
                        <Icon icon={icon} styles={{ width: '1.75rem', height: '1.75rem', ...iconStyles }} />
                    </div>
                )}
                <div className={styles.inputContainer}>
                    <textarea className={`${styles.area} ${!!label ? styles.areaHover : ''} ${validValue ? styles.upArea : ''}`} value={value} disabled={inputDisabled} {...mergedInputProps} />
                    {!!label && (
                        <label className={`${styles.label} ${validValue ? styles.upLabel : ''}`} {...restLabelProps}>
                            {label}
                        </label>
                    )}
                    {!!error && (
                        <p className={styles.invalidAlert} {...restErrorProps}>
                            {error}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

TextArea.propTypes = {
    className: PropTypes.string,
    iconProps: PropTypes.object,
    containerProps: PropTypes.object,
    inputProps: PropTypes.object,
    labelProps: PropTypes.object,
    errorProps: PropTypes.object,
};

export default TextArea;
