import React, { useMemo, Fragment, useContext } from "react";
import { useSelector, shallowEqual } from "react-redux";
import {ticketsSelector} from "libs/redux"
import { FlexContainer } from "./common";
import { LeftTitle, GridContainer, CellContainer, CellsGrid, CellValue } from "./common_preview";

import { I8nContext } from "../../../../../../libs/i18n";

interface HeaderProps {
  leading?: number | undefined;
  highlight: number;
  cell: number;
}

const TableGrid: React.FC<HeaderProps> = ({ leading, highlight, cell }) => {
  const summary: any = useSelector(ticketsSelector, shallowEqual);
  const { data, shifts } = summary;
  const { translate } = useContext(I8nContext);

  const keys2 = [
    translate("TICKETS"),
    translate("ACTIVATIONSPERCENT"),
    translate("COMPLETEDPERCENT"),
    translate("REJECTEDPERCENT"),
  ];
  const keys = ["tickets", "activations", "completed", "rejected"];

  const findData = (data: any[], targetShift: string) => {
    return data.find(({ shift }) => shift === targetShift);
  };

  return useMemo(() => {
    let mainGridTemplate = `${leading}fr `;
    let gridTemplate = `${highlight}fr `;

    if (Array.isArray(data)) {
      mainGridTemplate = `${leading}fr ${data.length * cell + highlight}fr`;
      data.forEach(() => {
        gridTemplate = `${gridTemplate} ${cell}fr`;
      });
    }
    return (
      <GridContainer gridTemplate={mainGridTemplate}>
        {Array.isArray(data) &&
          keys.map((key, i) => {
            const orderedData = shifts.map((shift: string) =>
              findData(data, shift)
            );
            const total = orderedData
              .map((item: any) => item[key])
              .reduce((acc: number, curr: number) => acc + curr);
            return (
              <Fragment key={i}>
                <FlexContainer>
                  {<LeftTitle>{keys2[i].toUpperCase()}</LeftTitle>}
                </FlexContainer>

                <CellsGrid gridTemplate={gridTemplate}>
                  <CellContainer>
                    <CellValue>{total}</CellValue>
                  </CellContainer>
                  {orderedData.map((value: any, i: number) => (
                    <CellContainer key={i} backgroundColor={"#fff"}>
                      <CellValue>{value[key]}</CellValue>
                    </CellContainer>
                  ))}
                </CellsGrid>
              </Fragment>
            );
          })}
      </GridContainer>
    );
  }, [summary]);
};

export default TableGrid;
