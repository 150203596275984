
import {combineReducers} from 'redux';
// -------------------- REDUCERS -------------------- //
import OpencloseReducer from '../common/states/openclose/reducer';
import ResumeReducer from './states/resume/reducer';
import WinlosesReducer from './states/winloses/reducer';
import RankReducer from './states/rank/reducer';

const hostessReducer = combineReducers({
    resume: ResumeReducer,
    winloses: WinlosesReducer,
    openclose: OpencloseReducer,
    rank: RankReducer
});


export default hostessReducer;