import React, { useContext } from "react";
import DasboardHeader from "../../../../providers/header";
import { musicWinlosesSelector } from "../../../../../libs/redux";
import ListCard from "./components/music-list.container";
import MusicCard from "./components/music-card.component";
import { TableStructure, TableContainer, CardTitle, MusicContent } from "../../components/layout";
import { I8nContext } from "../../../../../libs/i18n";

const MusicDashboard: React.FC = () => {

  const { translate } = useContext(I8nContext);

  return (
    <TableStructure>
      <DasboardHeader title={translate("MUSIC")} selector={musicWinlosesSelector} />
      <TableContainer>
        <div>
          <CardTitle>
            <p style={{width: "33%"}}> {translate("OPERATOR")} </p>
            <p style={{width: "33%"}}>{translate("OVERALL")}</p>
            <p style={{width: "33%"}}> {translate("TAGS")}</p>
          </CardTitle>
          <MusicContent> <ListCard Component={MusicCard} /> </MusicContent>
        </div>
      </TableContainer>
    </TableStructure>
  );
};

export default MusicDashboard;
