import React, { useMemo } from 'react';
import { getImageURL, winsFolder, losesFolder } from 'resources/api';

interface RatingProps {
  wins: string,
  loses: string,
  avg: number,
  perfect : number,
  Component: any,
  touch : number,
  chefmet? : any,
  preview? : boolean
}
const RatingContainer: React.FC<RatingProps>= ({wins, loses, avg, touch, perfect, Component, chefmet, preview}) => {

  return useMemo(()=>{

    const numberToBool = (value: number) => {
      if (value > 0 && value < 10) return [true, true, false];
      if (value >= 10 && value <= 99) return [true, false, false];
      if (value > 99) return [false, false, false];
      return [true, true, true];
    }
    
    const winsNum = parseInt(wins, 10);
    const losesNum = parseInt(loses, 10);

    const winsBoolArray = numberToBool(winsNum);
    const losesBoolArray = numberToBool(losesNum);

    const ratingUrl = (variable: string, folder: string, boolArray: Array<boolean>, id: number) => {
      return variable && variable[id] ? getImageURL(folder,   boolArray[id] ? '-.png' : `${variable[id]}.png`)  : ''
    }

    const imagesURLS : {[key : string] : string} = {
      winsUrl1 :  ratingUrl(wins, winsFolder, winsBoolArray, 0),
      winsUrl2 :  ratingUrl(wins, winsFolder, winsBoolArray, 1),
      winsUrl3 :  ratingUrl(wins, winsFolder, winsBoolArray, 2),
      losesUrl1 : ratingUrl(loses, losesFolder, losesBoolArray, 0),
      losesUrl2 : ratingUrl(loses, losesFolder, losesBoolArray, 1),
      losesUrl3 : ratingUrl(loses, losesFolder, losesBoolArray, 2),
    };

    const score = {
      total: avg, 
      imagesURLS,
      chefmet,
      wins,
      loses,
      perfect,
      touch,
      preview
    }
    return <Component {...score}/>
  },[wins, loses, avg, perfect, touch])
};
  
export default RatingContainer;