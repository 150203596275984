import React from "react";
import BaseDialog from "../BaseDialog.jsx";
import { P } from '@etiquette-ui/typography';
import PrimaryButton from '@etiquette-ui/buttons';
import styles from './info.module.css';

const Actions = ({ onClose, buttonText }) => {
  return (
    <div className={styles.buttonsSection}>
      <PrimaryButton type='text' onClick={onClose} className={styles.button}>{buttonText}</PrimaryButton>
    </div>
  );
};


const InfoDialog = ({ open, onClose, config }) => {

  return (
    <BaseDialog
      open={open}
      title={config.title}
      header={config.header}
      onClose={onClose}
      isResponsive={false}
      showCloseButton={config.showCloseButton ?? true}
      footer={<Actions onClose={onClose} buttonText={config.buttonText} />}
    >
      <P>{config.body}</P>
      {config.foot && <P>{config.foot}</P>}
    </BaseDialog>
  );
};

export default InfoDialog;
