const size = {
    mini: '270px',
    mobileXS: '281px',
    mobileS: '340px',
    mobileM: '420px',
    mobileL: '461px',
    mobile: '661px',
    tablet: '768px',
    ipad: '1024px',
    laptop: '1100px',
    desktop: '2560px',
    height: '1024px',
    custom: '890px',
  };
export const device = {
    mini: `(max-width: ${size.mini})`,
    mobileXS: `(max-width: ${size.mobileXS})`,
    mobileS: `(max-width: ${size.mobileS})`,
    mobileM: `(max-width: ${size.mobileM})`,
    mobileL: `(max-width: ${size.mobileL})`,
    mobile: `(max-width: ${size.mobile})`,
    tablet: `(max-width: ${size.tablet})`,
    ipad: `(max-width: ${size.ipad})`,
    laptop: `(max-width: ${size.laptop})`,
    desktop: `(max-width: ${size.desktop})`,
    desktopL: `(max-width: ${size.desktopL})`,
    custom: `(max-width: ${size.custom})`,
    height: `(min-height: ${size.height})`
  };

export const colors = {
    primary: '#001B35',
    secundary: '#012739',
    clear: '#f2f2f2',
    red: '#CC3E4C',
    green: '#7BA950',
    gold: '#DB921D',
    grayblue:'#2d4b63',
    gray: "#888888",
    white: "#ffffff",
    black: "#000000",
    shadow: "0.1em 0.1em #000",
    good: "#077e15",
    bad: "#c30e2e",
    blue: "#176998",
    mediumblue: '#025786',  
    lightblue: '#CDDDE6' ,
    backgroundLightGrey: "#EDEDED",
};